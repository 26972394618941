<div class="admin-invitation__wrapper">
  <div class="admin-invitation__header">
    <h1 class="subtitle-2 admin-invitation__header-title">
      {{ 'org_access.handling.header' | translate }}
    </h1>
  </div>

  <div class="admin-invitation__content">
    <p class="admin-invitation__content-text">
      {{
        'org_access.handling.text1'
          | translate
            : {
                firstName: organisationInvitation?.givenName,
                lastName: organisationInvitation?.familyName,
                email: organisationInvitation?.email,
                orgName: orgName
              }
      }}
    </p>

    <p class="admin-invitation__content-text">
      {{ 'org_access.handling.text2' | translate }}
    </p>
  </div>

  <div class="admin-invitation__content-row">
    <claas-dropdown-key-value
      (onChange)="onChangeRole($event)"
      [defaultOption]="roles[0]"
      [label]="'overview.role' | translate"
      [options]="roles"
      formControlName="role"
      ngDefaultControl />
    <!-- TODO: NO TEXT IN DESIGN -->
    <!--    <claas-icon-button [iconSize]="mediumIconSize" [icon]="infoIcon" />-->
  </div>

  <div class="admin-invitation__dialog-actions">
    <div class="left-side">
      <div class="btn-left">
        <div *ngIf="showSpinner$ | async" class="flex flex-end w-full">
          <span class="flex">
            <claas-circular-progress
              [color]="secondaryColor"
              [diameter]="largeSize">
            </claas-circular-progress>
          </span>
        </div>
      </div>
    </div>
    <div class="right-side">
      <claas-button
        (onClick)="onClose(false)"
        *ngIf="data.buttonCancel"
        [buttonClass]="secondaryTextButtonClass"
        [text]="'generic.deny' | translate"
        class="btn-left">
      </claas-button>

      <claas-button
        (onClick)="onClose(true)"
        *ngIf="data?.buttonSave"
        [disabled]="(showSpinner$ | async) === true"
        [text]="'generic.confirm' | translate"
        class="btn-right">
      </claas-button>
    </div>
  </div>
</div>
