<div class="organisation-data-overview">
  <div *ngIf="organisation">
    <div class="line-container">
      <div class="content-line">
        <div class="data">
          <span class="label label-1-style">{{
            'registration.formfield.name' | translate
          }}</span>
          <span class="value">{{ organisation['name'] }}</span>
        </div>

        <div class="action">
          <claas-icon-button
            *ngIf="isAdmin"
            class="edit-btn"
            [icon]="editIcon"
            [iconSize]="mediumIconSize"
            [fontSize]="mediumIconFontSize"
            (click)="openDialog('organisation-name')"></claas-icon-button>
        </div>
      </div>

      <claas-divider
        [dividerOrientation]="horizontalDivider"
        [inset]="false"></claas-divider>
    </div>

    <div class="line-container">
      <div class="content-line">
        <div class="data">
          <span class="label label-1-style">{{
            'registration.formfield.type' | translate
          }}</span>
          <span class="value">{{ 'registration.formfield.select.'+organisation['type'] | translate }}</span>
        </div>

        <div class="action">
          <claas-icon-button
            *ngIf="isAdmin"
            class="edit-btn"
            [icon]="editIcon"
            [iconSize]="mediumIconSize"
            [fontSize]="mediumIconFontSize"
            (click)="openDialog('organisation-type')"></claas-icon-button>
        </div>
      </div>

      <claas-divider
        [dividerOrientation]="horizontalDivider"
        [inset]="false"></claas-divider>
    </div>

    <div class="line-container">
      <div class="content-line">
        <div class="data">
          <span class="label label-1-style">{{
            'organization.fields.customer_number' | translate
          }}</span>
          <span class="value">{{ organisation['debitorId'] }}</span>
        </div>
      </div>

      <claas-divider
        [dividerOrientation]="horizontalDivider"
        [inset]="false"></claas-divider>
    </div>

    <div class="line-container">
      <div class="content-line">
        <div class="data">
          <span class="label label-1-style">{{
            'registration.formfield.country' | translate
          }}</span>
          <span class="value">{{ getCountry(organisation['country'], 'label') | translate }}</span>
        </div>
      </div>

      <claas-divider
        [dividerOrientation]="horizontalDivider"
        [inset]="false"></claas-divider>
    </div>

    <div class="line-container">
      <div class="content-line">
        <div class="data">
          <span class="label label-1-style">{{
            'registration.formfield.timeZone' | translate
          }}</span>
          <span class="value">{{ organisation['timeZone'] }}</span>
        </div>

        <div class="action">
          <claas-icon-button
            *ngIf="isAdmin"
            class="edit-btn"
            [icon]="editIcon"
            [iconSize]="mediumIconSize"
            [fontSize]="mediumIconFontSize"
            (click)="openDialog('organisation-timezone')"></claas-icon-button>
        </div>
      </div>

      <claas-divider
        [dividerOrientation]="horizontalDivider"
        [inset]="false"></claas-divider>
    </div>

    <div class="line-container">
      <div class="content-line">
        <div class="data">
          <span class="label label-1-style">{{
            'registration.formfield.unitSystem' | translate
          }}</span>
          <span class="value">{{
            'registration.formfield.select.' + organisation['unitSystem'] | translate
          }}</span>
        </div>

        <div class="action">
          <claas-icon-button
            *ngIf="isAdmin"
            class="edit-btn"
            [icon]="editIcon"
            [iconSize]="mediumIconSize"
            [fontSize]="mediumIconFontSize"
            (click)="openDialog('organisation-unitsystem')"></claas-icon-button>
        </div>
      </div>

      <claas-divider
        [dividerOrientation]="horizontalDivider"
        [inset]="false"></claas-divider>
    </div>

    <div class="line-container">
      <div class="content-line">
        <div
          [ngClass]="
            organisation['phoneNumber']?.phoneNumber
              ? 'data'
              : 'data add-phonenumber'
          ">
          <span class="label label-1-style">{{
            'registration.formfield.phoneNumber' | translate
          }}</span>

          <span
            class="value"
            *ngIf="
              organisation['phoneNumber']?.phoneNumber;
              else addOrgaPhonenumber
            "
            >{{
              organisation['phoneNumber']?.phoneNumber
                ? organisation['phoneNumber']?.phoneNumber
                : ''
            }}</span
          >
          <ng-template #addOrgaPhonenumber>
            <div class="action">
              <claas-button
                *ngIf="isAdmin"
                class="add-btn phonenumber"
                [icon]="addIcon"
                [text]="'personal_data.phonenumber.add_header' | translate"
                [buttonClass]="primaryTextButtonClass"
                [size]="mediumButtonSize"
                (onClick)="
                  openDialog('organisation-phonenumber', 'add')
                "></claas-button>
            </div>
          </ng-template>
        </div>

        <div class="action">
          <claas-icon-button
            *ngIf="isAdmin && organisation['phoneNumber']?.phoneNumber"
            class="edit-btn"
            [icon]="editIcon"
            [iconSize]="mediumIconSize"
            [fontSize]="mediumIconFontSize"
            (click)="
              openDialog('organisation-phonenumber', 'edit')
            "></claas-icon-button>
        </div>
      </div>

      <claas-divider
        [dividerOrientation]="horizontalDivider"
        [inset]="false"></claas-divider>
    </div>

    <div class="line-container">
      <div class="content-line">
        <div class="data address">
          <span class="label label-1-style">{{
            'registration.formfield.select.INVOICE' | translate
          }}</span>
          <span class="value">{{
            organisation['addresses'] | address: 'INVOICE' : true | async
          }}</span>
        </div>

        <div class="action">
          <claas-icon-button
            *ngIf="isAdmin"
            class="edit-btn"
            [icon]="editIcon"
            [iconSize]="mediumIconSize"
            [fontSize]="mediumIconFontSize"
            (click)="
              openDialog('organisation-address', 'edit', invoiceValue)
            "></claas-icon-button>
        </div>
      </div>

      <claas-divider
        [dividerOrientation]="horizontalDivider"
        [inset]="false"></claas-divider>
    </div>

    <div class="line-container">
      <div class="content-line">
        <div class="data address">
          <span class="label label-1-style">{{
            'registration.formfield.select.DELIVERY' | translate
          }}</span>
          <span class="value">{{
            organisation['addresses'] | address: 'DELIVERY' : true | async
          }}</span>
        </div>

        <div class="action">
          <claas-icon-button
            *ngIf="isAdmin"
            class="edit-btn"
            [icon]="editIcon"
            [iconSize]="mediumIconSize"
            [fontSize]="mediumIconFontSize"
            (click)="
              openDialog('organisation-address', 'edit', deliveryValue)
            "></claas-icon-button>
        </div>
      </div>

      <claas-divider
        [dividerOrientation]="horizontalDivider"
        [inset]="false"></claas-divider>
    </div>

    <div class="line-container btn-container">
      <app-button
        *ngIf="isAdmin"
        type="warn"
        [label]="'organization.delete_organization.label' | translate"
        (click)="showConfirmationDialog('organisation-deletion')"></app-button>
    </div>
  </div>
</div>
