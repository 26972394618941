import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { passedDataToDialog } from '../dialog-window/dialog-window.component';
import { ButtonClass, Icon, IconSize } from '@claas/claas-form-components';
import { AuthService } from '../../services/auth.service';
import { Organization } from '../../models/organization.model';
import { ApiService } from '../../services/api.service';
import { Application } from '../../models/application.model';
import {
  BehaviorSubject,
  Subscription,
  finalize,
  skipWhile,
  take,
  tap
} from 'rxjs';
import { selectUserDetails } from '../../state/user';
import {
  combineLatestWith,
  distinctUntilChanged,
  filter,
  map,
  startWith
} from 'rxjs/operators';
import { IUser } from '../../models/auth.model';
import {
  loadUserOrganisations,
  organisationMembersNotLoaded
} from '../../state/organisations';
import { selectOrganisations } from '../../state/organisations/organisations.selectors';
import { UserOrganisations } from '../../state/organisations/organisations.state';
import { Store } from '@ngrx/store';
import { Consent } from '../../models/consent.model';
import { Router } from '@angular/router';
import { consentsArr } from '../../../applications/consent-source';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ProgressColor } from '@claas/claas-layout-components';
import { errorStatusObject } from '../address-view/address-view.component';

@Component({
  selector: 'app-delete-account-view',
  templateUrl: './delete-account-view.component.html',
  styleUrls: ['./delete-account-view.component.scss']
})
export class DeleteAccountViewComponent implements OnInit, OnDestroy {
  public adminOrgs: Organization[] = [];
  public showConfirmation: boolean = false;
  public consents: Consent[] = [];
  public consentsArr = consentsArr;
  public connectedApps: Application[] = [];
  public organisations: any;
  errorTextButtonClass = ButtonClass.ERROR;
  isSocial: boolean = false;
  returnedError: 'blocked' | 'password' | 'unknown' | undefined;
  showPassword: boolean = false;
  showPasswordIcon: Icon = {
    iconId: '001003',
    iconStyle: 'bold',
    namespace: 'claas-id',
    size: 24
  };
  smallIconSize = IconSize.SMALL;
  isLoadingOrganisations$: BehaviorSubject<any> = new BehaviorSubject(true);
  organisations$: BehaviorSubject<any> = new BehaviorSubject(undefined);

  myForm: FormGroup | undefined;
  secondaryColor = ProgressColor.SECONDARY;
  showSpinner$: BehaviorSubject<any> = new BehaviorSubject(false);
  invalidSaveButtonState$ = new BehaviorSubject(true);
  errorsStatusObject$: errorStatusObject = {};

  @Input() data: passedDataToDialog = {};
  @Input() secondaryTextButtonClass?: ButtonClass;
  @Output() closeDialogWindow = new EventEmitter();
  private user: IUser | undefined;
  private subscriptions = new Subscription();

  constructor(
    private auth: AuthService,
    private apiService: ApiService,
    private store: Store,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    // Get connected applications from user
    this.getOrganisations();

    if (this.data?.consents) {
      this.consents = this.data.consents;

      this.getConnectedApps();
    } else {
      this.connectedApps = [];
    }

    if (this.data?.isSocial) {
      this.isSocial = this.data.isSocial;
    }

    this.myForm = this.setForm();
    this.trackForm(this.myForm);
  }

  setForm(): FormGroup {
    return new FormGroup({
      password: new FormControl('', [Validators.required])
    });
  }

  preTrackFormChanges(myForm: FormGroup): void {
    this.invalidSaveButtonState$.next(true);

    this.trackForm(myForm);
  }

  trackForm(myForm: FormGroup): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    this.errorsStatusObject$['password'] = this.myForm
      .get('password')
      .valueChanges.pipe(
        map(value => {
          return this.myForm?.get('password')?.status === 'INVALID'
            ? 'registration.validationMsgs.required'
            : undefined;
        })
      );

    this.subscriptions.add(
      myForm
        .get('password')!
        .valueChanges.pipe(
          combineLatestWith(myForm.get('password')!.statusChanges),
          map(([value, status]) => {
            const length = value.length >= 10;
            const isInvalid = status === 'INVALID' || !length;
            // update the save button status
            this.invalidSaveButtonState$.next(isInvalid);
          })
        )
        .subscribe(() => this.cdr.detectChanges())
    );
  }

  //Fetch Organisations from Backend
  getOrganisations() {
    this.subscriptions.add(
      this.store
        .select(selectUserDetails)
        .pipe(
          filter((user: any) => !!user),
          tap((user: IUser) => {
            this.user = user;
            // in case the token is required please use "user.sub" for it
            this.store.dispatch(loadUserOrganisations({ userId: user.uuid }));

            this.subscriptions.add(
              this.store
                .select(selectOrganisations)
                .pipe(
                  filter((organisations: any) => !!organisations),
                  map((organisations: UserOrganisations) => {
                    if (Object.keys(organisations).length === 0) {
                      // the user has no registered organisation
                      this.isLoadingOrganisations$.next(false);
                    } else {
                      this.isLoadingOrganisations$.next(false);
                      // convert the dictionary into an array
                      this.organisations$.next(Object.values(organisations));
                    }
                  })
                )
                .subscribe()
            );
          })
        )
        .subscribe()
    );
  }

  getConnectedApps() {
    if (this.consents != undefined) {
      for (const app of this.consents) {
        const connectedApp = new Application();
        connectedApp.client_id = app.client_id;

        const dummy = consentsArr.find(foo => foo.client_id == app.client_id);
        if (dummy != undefined) {
          connectedApp.id = dummy.id;
          connectedApp.label = 'applications.' + dummy.id;
          if (connectedApp.id.startsWith('ET_')) {
            connectedApp.label = 'applications.ET';
          }
          this.connectedApps.push(connectedApp);
        }
      }
    } else {
      this.connectedApps = [];
    }
  }

  getConsentTranslation(consent: string, translationLabel: string) {
    const c = this.consentsArr.find(function (element) {
      return element.id === consent;
    });
    return c === undefined ? '' : c.label + '.' + translationLabel;
  }

  deleteAccountSocial(passedObject: any) {
    this.auth.getTokenSilently$().subscribe(async (accessToken: string) => {
      // Send HTTPS Request to Backend
      const payload = {
        email: this.user!.email
      };
      this.subscriptions.add(
        this.apiService
          .deleteSocialAccount(payload, accessToken)
          .pipe(
            finalize(() => {
              this.showSpinner$.next(false);
              this.closeDialogWindow.emit(passedObject);
            })
          )
          .subscribe({
            next: () => {
              this.auth.logout();
              this.router.navigate(['/']);
            },
            error: (err: Error) => {
              const errorString = JSON.stringify(err);
              console.log(errorString);
            }
          })
      );
    });
  }

  deleteAccount(passedObject: any) {
    if (this.adminOrgs.length !== 0) {
      this.closeDialogWindow.emit(passedObject);
      return;
    }
    if (this.isSocial) {
      this.deleteAccountSocial(passedObject);
    } else {
      this.auth.getTokenSilently$().subscribe(async accessToken => {
        const payload = {
          email: this.user!.email,
          password: this.myForm?.get('password')?.value
        };
        this.subscriptions.add(
          this.apiService
            .deleteAccount(payload, accessToken)
            .pipe(finalize(() => this.showSpinner$.next(false)))
            .subscribe({
              next: () => {
                this.auth.logout();
                this.closeDialogWindow.emit(passedObject);
                this.router.navigate(['/']);
              },
              error: error => {
                if (error.status === 429) {
                  this.returnedError = 'blocked';
                } else if (error.status === 403) {
                  this.returnedError = 'password';
                } else {
                  this.returnedError = 'unknown';
                }
              }
            })
        );
      });
    }
  }

  onCancelClicked(): any {
    const passedObject = {
      action: 'cancel'
    };

    this.closeDialogWindow.emit(passedObject);
  }

  // TODO: Extract Admin Organisations to a separate function
  onSaveClicked(): void {
    this.showSpinner$.next(true);
    this.organisations$
      .pipe(
        skipWhile(value => !value),
        take(1)
      )
      .subscribe((res: Organization[]) => {
        for (const org of res) {
          let isAdmin = false;
          let moreAdmins = false;
          let moreMembers = false;
          this.subscriptions.add(
            this.apiService.getOrganisationMembers(org.auth0Id!).subscribe({
              next: (members: any) => {
                for (const member of members.content) {
                  if (member.role === 'ADMIN') {
                    if (member.idpUser === this.user!.sub) {
                      isAdmin = true;
                    } else {
                      moreAdmins = true;
                    }
                  } else {
                    moreMembers = true;
                  }
                }
              },
              error: error =>
                this.store.dispatch(
                  organisationMembersNotLoaded({
                    organisationError: error.payload
                  })
                )
            })
          );

          if (isAdmin && !moreAdmins && moreMembers) {
            this.adminOrgs.push(org);
            this.showConfirmation = true;
          }
        }
      });

    if (!this.showConfirmation) {
      const passedObject = {
        context: this.data.context,
        action: 'save'
      };
      this.deleteAccount(passedObject);
    }
  }

  onSubmitClicked(): void {
    const passedObject = {
      context: this.data.context,
      action: 'save'
    };

    this.deleteAccount(passedObject);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
