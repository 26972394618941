import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserOrganisationsState } from './organisations.state';

export const selectOrganisationsState =
  createFeatureSelector<UserOrganisationsState>('organisations');

export const selectOrganisations = createSelector(
  selectOrganisationsState,
  state => state.userOrganisations
);

export const selectDefaultOrganisation = createSelector(
  selectOrganisationsState,
  state => state.defaultOrganisationId
);

export const selectRegistrationSteps = createSelector(
  selectOrganisationsState,
  state => state.registrationSteps
);

export const selectRegistrationDefaultOrganisation = createSelector(
  selectOrganisationsState,
  state => state.registrationDefaultOrganisation
);

export const selectRegisteredOrganisation = createSelector(
  selectOrganisationsState,
  state => state.registeredOrganisation
);

export const selectCurrentOrganisation = createSelector(
  selectOrganisationsState,
  state => state.currentOrganisation
);

export const selectAddressDetails = createSelector(
  selectOrganisationsState,
  state => state.addressDetails
);

export const selectOrganisationError = createSelector(
  selectOrganisationsState,
  state => state.organisationError
);
