import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output
} from '@angular/core';
import {
  CircularProgressDiameter,
  ProgressColor
} from '@claas/claas-layout-components';
import { Icon, IconFontSize, IconSize } from '@claas/claas-form-components';
import { Store } from '@ngrx/store';
import {
  selectDealerByNameSearchData,
  selectDealerFiltersData,
  selectDefaultDealer,
  selectNearestDealerSearchData,
  selectNearestDealerSearchDataPending
} from '../shared/state/dealers/dealers.selectors';
import { BehaviorSubject, EMPTY, Observable, Subscription, take } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { OrganisationDealerModel } from '../shared/models/organisation-dealer.model';
import { OrganisationShopModel } from '../shared/models/organisation-shop.model';
import { environment } from 'src/environments/environment';
import {
  DialogWindowComponent,
  passedDataToDialog,
  ResponseDataFromDialog
} from '../shared/components/dialog-window/dialog-window.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  DialogMode,
  IRegistrationOrganisationAddress,
  OrganisationRegistrationDialogComponent
} from 'cde-fe-organization-registration-dialog';
import { OrganisationRegistrationDialogInputModel } from '../shared/models/organisationRegistrationDialogInput.model';
import { AuthService } from '../shared/services/auth.service';
import { OrganisationRegistrationDialogUserModel } from '../shared/models/organisationRegistrationDialogUser.model';
import {
  IAddressAutocompleteRequest,
  IAddressDetailsRequest,
  IRegistrationDialogStep
} from 'cde-fe-organization-registration-dialog/lib/models/organisation.model';
import {
  selectAddressDetails,
  selectCurrentOrganisation,
  selectOrganisationError,
  selectRegisteredOrganisation,
  selectRegistrationDefaultOrganisation
} from '../shared/state/organisations/organisations.selectors';
import {
  loadAddressDetails,
  loadRegistrationDefaultOrganisation,
  registerOrganisation,
  setCurrentOrganisation
} from '../shared/state/organisations';
import {
  selectAddressAutocompleteSuggestionsData,
  selectRegistrationDataPending,
  selectRegistrationDealerData,
  selectRegistrationErrorData,
  selectRegistrationPersonalData,
  selectRegistrationPersonalDataPending,
  selectRegistrationShopData
} from '../shared/state/global/global.selectors';
import {
  clearRegistrationData,
  loadAddressAutocompleteSuggestionsData,
  loadRegistrationDealerData,
  loadRegistrationPersonalData,
  loadRegistrationShopData,
  updateRegistrationPersonalData
} from '../shared/state/global';
import {
  loadDealerByNameSearchData,
  loadDealerFiltersData,
  loadNearestDealersSearchData
} from '../shared/state/dealers';
import { UserOrganisations } from '../shared/state/organisations/organisations.state';
import { ApiService } from '../shared/services/api.service';

export declare enum RegistrationShopName {
  ORGANIZATION = 'ORGANIZATION',
  USER_DATA = 'USER_DATA',
  DEALER = 'DEALER',
  SHOP = 'SHOP'
}

declare const window: any;

@Component({
  selector: 'app-organisation-data-dealers',
  templateUrl: './organisation-data-dealers.component.html',
  styleUrls: ['./organisation-data-dealers.component.scss']
})
export class OrganisationDataDealersComponent implements OnDestroy {
  @Input() isAdmin = false;
  @Input() organisation: UserOrganisations = {};
  @Input() currentUser: any = new OrganisationRegistrationDialogUserModel();
  @Input() organisationShops: OrganisationShopModel[] | null | undefined;
  @Input() registrationSteps: IRegistrationDialogStep[] | undefined | null;
  @Input() organisationConnectedDealers:
    | OrganisationDealerModel[]
    | null
    | undefined;

  @Output() disconnectDealers = new EventEmitter<
    OrganisationDealerModel[] | undefined | null
  >();

  @Output() setNewOrganisationDealer = new EventEmitter<any>();

  private subscriptions = new Subscription();

  editIcon: Icon = {
    iconId: '001326',
    iconStyle: 'bold',
    namespace: 'claas-id',
    size: 24
  };

  accessToken = '';
  largeSize = CircularProgressDiameter.LARGE;
  secondaryColor = ProgressColor.SECONDARY;
  mediumIconSize = IconSize.MEDIUM;
  mediumIconFontSize = IconFontSize.MEDIUM;
  organisationDefaultDealer: OrganisationDealerModel | undefined =
    new OrganisationDealerModel();
  isHandset = false;
  organisationDefaultDealerInfo$ = new BehaviorSubject(false);

  constructor(
    private authService: AuthService,
    private store: Store,
    private dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    private apiService: ApiService
  ) {
    this.subscriptions.add(
      this.authService
        .getTokenSilently$()
        .subscribe(token => (this.accessToken = token))
    );

    this.subscriptions.add(
      this.store
        .select(selectDefaultDealer)
        .pipe(filter(result => !!result))
        .subscribe(result => {
          this.organisationDefaultDealer = result;
          this.organisationDefaultDealerInfo$.next(true);
        })
    );
    this.subscriptions.add(this.isHandset$().subscribe());
  }

  isHandset$(): Observable<any> {
    return this.breakpointObserver
      .observe(Breakpoints.Handset)
      .pipe(map((state: any) => (this.isHandset = state.matches)));
  }

  editShops(): void {
    if (environment.shops_url) {
      window.location.href = environment.shops_url;
    } else {
      console.log('Error: missing link');
    }
  }

  openDialog(context: string): void {
    const data: passedDataToDialog = {
      context,
      connectedDealers: this.organisationConnectedDealers,
      largeDisplay: true,
      buttonSave: 'Save',
      buttonCancel: 'Cancel'
    };

    const dialogRef = this.dialog.open(DialogWindowComponent, {
      maxWidth: this.isHandset ? '100vw' : '80vw',
      data
    });

    this.subscriptions.add(
      dialogRef
        .afterClosed()
        .pipe(
          map((answer: ResponseDataFromDialog) => {
            if (answer.refreshIsRequired) {
              this.disconnectDealers.emit(answer.disconnectedDealers);
            }
          })
        )
        .subscribe()
    );
  }

  setOrganisationRegistrationDialogInput(){
    const input = new OrganisationRegistrationDialogInputModel();

    input.UC_UI = window.UC_UI;
    input.user = this.currentUser!;
    input.bearerToken = this.accessToken;
    input.apiBaseUrl = environment.base_urls.cc_3_api_base_url;
    input.dialogSteps = this.registrationSteps ? this.registrationSteps : [];
    input.clientId = `${environment.auth.azure_maps_client_id}`;
    input.disableAutostart = false;
    input.dialogMode = DialogMode.DEALER_ONLY_SELECTION;
    input.orgAddress = this.getRegistrationOrgaAddress(
      this.organisation['addresses'][0] ? this.organisation['addresses'][0] : {}
    );

    input.registrationDataPending$ = this.store.select(
      selectRegistrationDataPending
    );

    input.searchNearestDealerPending$ = this.store.select(
      selectNearestDealerSearchDataPending
    );

    input.registrationDealerData$ = this.store.select(
      selectRegistrationDealerData
    );
    input.currentOrganisation$ = this.store.select(selectCurrentOrganisation);
    input.searchNearestDealers$ = this.store.select(
      selectNearestDealerSearchData
    );

    this.store
      .select(selectNearestDealerSearchData)
      .subscribe(result => console.log(result));

    input.dealerFilters$ = this.store.select(selectDealerFiltersData);
    input.addressAutocompleteSuggestions$ = this.store.select(
      selectAddressAutocompleteSuggestionsData
    );
    input.addressDetails$ = this.store.select(selectAddressDetails);
    input.searchDealersByName$ = this.store.select(
      selectDealerByNameSearchData
    );
    input.registrationPersonalData$ = this.store.select(
      selectRegistrationPersonalData
    );
    input.registrationError$ = this.store.select(selectRegistrationErrorData);
    input.organisationsError$ = this.store.select(selectOrganisationError);
    input.getRegisteredOrganisation$ = this.store.select(
      selectRegisteredOrganisation
    );
    input.registrationDefaultOrganisation$ = this.store.select(
      selectRegistrationDefaultOrganisation
    );
    input.registrationPersonalDataPending$ = this.store.select(
      selectRegistrationPersonalDataPending
    );
    // input.getIsSocialUser  is not used anymore in the Input sctructure
    input.registrationShopData$ = this.store.select(selectRegistrationShopData);
    input.currentDefaultOrganisation$ = this.store.select(
      selectRegistrationDefaultOrganisation
    );

    input.userMarketingPermissions$ = input.user?.uuid
    ? this.apiService.getMarketingPermissionsForRegistrationDialog(input.user?.uuid).pipe(take(1))
    : EMPTY;


    return input;
  }

  openDefaultDealerDialog(): void {
    const data =
      this.setOrganisationRegistrationDialogInput();

    const dialogRef = this.dialog.open(
      OrganisationRegistrationDialogComponent,
      {
        data: data
      }
    );

    this.processWithDialogRef(dialogRef);

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(response => {
        if (response['dealerId']) {
          this.organisationDefaultDealer = undefined;
          this.organisationDefaultDealerInfo$.next(false);

          this.setNewOrganisationDealer.emit({
            org: this.organisation,
            dealerId: response['dealerId']
          });
        }
      });
  }

  processWithDialogRef(dialogRef: MatDialogRef<any>): void {
    this.subscriptions.add(
      dialogRef.componentInstance.getDealerFilter.subscribe(() =>
        this.store.dispatch(loadDealerFiltersData())
      )
    );

    this.subscriptions.add(
      dialogRef.componentInstance.getNearestDealers.subscribe(
        (request: any) => {
          this.store.dispatch(loadNearestDealersSearchData(request));
        }
      )
    );

    this.subscriptions.add(
      dialogRef.componentInstance.getAddressAutocomplete.subscribe(
        (request: IAddressAutocompleteRequest) =>
          this.store.dispatch(
            loadAddressAutocompleteSuggestionsData({
              addressDetailsRequest: request
            })
          )
      )
    );

    this.subscriptions.add(
      dialogRef.componentInstance.getDealersByName.subscribe((request: any) => {
        this.store.dispatch(loadDealerByNameSearchData(request));
      })
    );

    this.subscriptions.add(
      dialogRef.componentInstance.clearRegistrationData.subscribe(() =>
        this.store.dispatch(clearRegistrationData())
      )
    );

    this.subscriptions.add(
      dialogRef.componentInstance.getRegistrationDefaultOrganisation.subscribe(
        (country: string) =>
          this.store.dispatch(loadRegistrationDefaultOrganisation({ country }))
      )
    );

    this.subscriptions.add(
      dialogRef.componentInstance.getRegistrationShopData.subscribe(
        (request: any) =>
          this.store.dispatch(loadRegistrationShopData({ country: request }))
      )
    );

    this.subscriptions.add(
      dialogRef.componentInstance.getRegistrationPersonalData.subscribe(
        (request: any) => {
          this.store.dispatch(
            loadRegistrationPersonalData({
              country: request.country,
              language: request.language
            })
          );
        }
      )
    );

    this.subscriptions.add(
      dialogRef.componentInstance.getRegistrationDealerData.subscribe(
        (request: any) => {
          this.store.dispatch(
            loadRegistrationDealerData({
              language: request.language
            })
          );
        }
      )
    );

    this.subscriptions.add(
      dialogRef.componentInstance.setCurrentOrganisation.subscribe(
        (org: any) => {
          this.store.dispatch(
            setCurrentOrganisation({
              auth0Id: org.auth0Id ? org.auth0Id : ''
            })
          );
        }
      )
    );

    this.subscriptions.add(
      dialogRef.componentInstance.maintainPersonalData.subscribe(
        (request: any) =>
          this.store.dispatch(
            updateRegistrationPersonalData({
              request: request.request,
              language: request.language
            })
          )
      )
    );

    this.subscriptions.add(
      dialogRef.componentInstance.registerOrganisationWithDealerAndShops.subscribe(
        (request: any) => this.store.dispatch(registerOrganisation(request))
      )
    );

    this.subscriptions.add(
      dialogRef.componentInstance.stepperError.subscribe((message: any) => {
        console.log(message);
      })
    );

    this.subscriptions.add(
      dialogRef.componentInstance.getAddressDetails
        .pipe(take(1))
        .subscribe((request: IAddressDetailsRequest) =>
          this.store.dispatch(
            loadAddressDetails({ addressDetailsRequest: request })
          )
        )
    );
  }

  getRegistrationOrgaAddress(address: any): IRegistrationOrganisationAddress {
    return {
      type: address.type ? address.type.toString() : '',
      houseNumber: address.houseNumber ? address.houseNumber.toString() : '',
      postalCode: address.postalCode ? address.postalCode.toString() : '',
      postbox: address.postbox ? address.postbox.toString() : '',
      street: address.street ? address.street.toString() : '',
      city: address.city ? address.city.toString() : '',
      suburb: address.suburb ? address.suburb : '',
      state: address.state ? address.state : '',
      territory: address.territory ? address.territory.toString() : '',
      addressAddition: address.addressAddition
        ? address.addressAddition.toString()
        : '',
      country: address.country ? address.country : '',
      latitude: address.latitude ? address.latitude : '',
      longitude: address.longitude ? address.longitude : ''
    };
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
