<div class="personal-data">
  <section class="personal-data__header">
    <h1 class="personal-data__header-title">
      {{ 'navigation.personal_data' | translate }}
    </h1>
  </section>

  <section class="personal-data__content">
    <section class="personal-data__content-upper-container">
      <div class="avatar-container">
        <div class="avatar-picture">
          <img
            *ngIf="avatarUrlIsDefined$ | async; else avatarNotLoaded"
            class="avatar-picture__image"
            [src]="avatarUrl" />

          <ng-template #avatarNotLoaded>
            <span class="avatar-picture__loader">
              <claas-circular-progress
                [color]="secondaryColor"
                [diameter]="largeSize"></claas-circular-progress>
            </span>
          </ng-template>
        </div>
        <div class="avatar-action">
          <claas-icon-button
            class="edit-btn avatar"
            [icon]="editIcon"
            [iconSize]="mediumIconSize"
            [fontSize]="mediumIconFontSize"
            [disabled]="avatarUrl === ''"
            (click)="openDialog('profile-avatar')"></claas-icon-button>
        </div>
      </div>

      <div class="name-container">
        <div class="content">
          <div
            *ngIf="userInformation; else userInformationNotLoaded"
            class="content-line">
            <div class="name-container__name-details">
              <div class="user-name">
                <h3>
                  @if (userInformation.salutation != null && userInformation.salutation != '') {
                    {{ 'registration.formfield.select.'+(userInformation.salutation | uppercase) | translate }}
                  }
                  @if (userInformation.title != null && userInformation.title != '') {
                    {{ 'registration.formfield.select.'+(userInformation.title | uppercase) | translate }}
                  }
                  {{ userInformation.first_name }}
                  {{ userInformation.last_name }}
                </h3>
              </div>
              <div class="indication">
                <p>
                  {{ 'personal_data.information' | translate }}
                </p>
              </div>
            </div>

            <div class="name-container__action">
              <claas-icon-button
                class="edit-btn name"
                [icon]="editIcon"
                [iconSize]="mediumIconSize"
                [fontSize]="mediumIconFontSize"
                (click)="openDialog('profile-name')"></claas-icon-button>
            </div>
          </div>

          <ng-template #userInformationNotLoaded>
            <div class="content-line">
              <claas-circular-progress
                [color]="secondaryColor"

                [diameter]="largeSize"></claas-circular-progress>
            </div>
          </ng-template>
        </div>
      </div>
    </section>

    <section class="personal-data__content-email-container">
      <span class="label subtitle-2">{{ 'personal_data.email' | translate }}</span>
      <div *ngIf="userInformation?.email; else emailNotLoaded" class="content">
        <div class="content-line">
          <div class="user-email">
            <p>{{ userInformation.email }}</p>
          </div>
        </div>
      </div>

      <ng-template #emailNotLoaded>
        <div class="content">
          <div class="content-line">
            <claas-circular-progress
              [color]="secondaryColor"

              [diameter]="largeSize"></claas-circular-progress>
          </div></div
      ></ng-template>
    </section>

    <claas-divider
      [dividerOrientation]="horizontalDivider"
      [inset]="false"></claas-divider>

    <section class="personal-data__content-phone-container">
      <span class="label subtitle-2">{{ 'personal_data.phonenumber.label' | translate }}</span>

      <div
        *ngIf="phonenumberAreDefined$ | async; else phonenumbersNotLoaded"
        class="content phonenumbersList">
        <div
          *ngFor="let element of userPhoneNumbers; index as i"
          class="line-container">
          <div class="content-line">
            <div class="user-phonenumber">
              <span *ngIf="element.type === 'mobile'" class="label">{{
                'personal_data.phonenumber.types.mobile' | translate
              }}</span>
              <span *ngIf="element.type === 'phone'" class="label">{{
                'personal_data.phonenumber.types.phone' | translate
              }}</span>

              <span>{{ element.phone_number_value }}</span>
            </div>

            <div class="action">
              <claas-icon-button
                class="edit-btn phonenumber"
                [icon]="editIcon"
                [iconSize]="mediumIconSize"
                [fontSize]="mediumIconFontSize"
                (click)="
                  openDialog('profile-phonenumber', 'edit', i)
                "></claas-icon-button>
            </div>
          </div>

          <claas-divider
            *ngIf="userPhoneNumbers.length !== i + 1"
            [dividerOrientation]="horizontalDivider"
            [inset]="false"></claas-divider>
        </div>

        <div *ngIf="userPhoneNumbers.length < 2" class="content-line">
          <div class="action">
            <claas-button
              class="add-btn phonenumber"
              [icon]="addIcon"
              [text]="'personal_data.phonenumber.add_header' | translate"
              [buttonClass]="primaryTextButtonClass"
              [size]="mediumButtonSize"
              (onClick)="openDialog('profile-phonenumber', 'add')"></claas-button>
          </div>
        </div>
      </div>

      <ng-template #phonenumbersNotLoaded>
        <div class="content phonenumbersLoader">
          <div class="content-line">
            <claas-circular-progress
              [color]="secondaryColor"

              [diameter]="largeSize"></claas-circular-progress>
          </div>
        </div>
      </ng-template>
    </section>

    <claas-divider
      [dividerOrientation]="horizontalDivider"
      [inset]="false"></claas-divider>

    <section class="personal-data__content-address-container">
      <span class="label subtitle-2">{{
        'personal_data.address.label' | translate
      }}</span>
      <div
        *ngIf="addressesAreDefined$ | async; else addressesNotLoaded"
        class="content addressesList">
        <div
          *ngIf="userInformation && userInformation.addresses"
          class="line-container">
          <div *ngFor="let element of userInformation.addresses; index as i">
            <div class="content-line address">
              <div class="user-address">
                <span>{{ element | address | async }}</span>
              </div>

              <div class="action">
                <claas-icon-button
                  class="edit-btn address"
                  [icon]="editIcon"
                  [iconSize]="mediumIconSize"
                  [fontSize]="mediumIconFontSize"
                  (click)="
                    openDialog('profile-address', 'edit', i)
                  "></claas-icon-button>
              </div>
            </div>

            <claas-divider
              *ngIf="userInformation.addresses.length !== i + 1"
              [dividerOrientation]="horizontalDivider"
              [inset]="false"></claas-divider>
          </div>
        </div>

        <div class="content-line">
          <claas-button
            *ngIf="
              !userInformation.addresses || userInformation.addresses.length < 1
            "
            class="add-btn address"
            [icon]="addIcon"
            [text]="'personal_data.address.add_header' | translate"
            [buttonClass]="primaryTextButtonClass"
            [size]="mediumButtonSize"
            (onClick)="openDialog('profile-address', 'add')"></claas-button>
        </div>
      </div>

      <ng-template #addressesNotLoaded>
        <div class="content addressesLoader">
          <div class="content-line">
            <claas-circular-progress
              [color]="secondaryColor"

              [diameter]="largeSize"></claas-circular-progress>
          </div>
        </div>
      </ng-template>
    </section>
  </section>
</div>
