export class OrganisationDealerModel {
  dealerId: string;
  name: string;
  street: string;
  postalCode: string;
  city: string;
  state: string;
  country: string;
  phone: string;
  fax: string;
  email: string;
  url: string;
  deliveryProgramIcons: string[];

  constructor() {
    this.dealerId = '';
    this.name = '';
    this.street = '';
    this.postalCode = '';
    this.city = '';
    this.state = '';
    this.country = '';
    this.phone = '';
    this.fax = '';
    this.email = '';
    this.url = '';
    this.deliveryProgramIcons = [];
  }
}
