export const countriesArr = [
  {
    id: 'ar',
    label: 'registration.formfield.select.country.argentina',
    img: '/assets/images/flags/de.svg',
    code: '+54',
    iso: 'arg'
  },
  {
    id: 'at',
    label: 'registration.formfield.select.country.austria',
    img: '/assets/images/flags/de.svg',
    code: '+43',
    iso: 'aut'
  },
  {
    id: 'au',
    label: 'registration.formfield.select.country.australia',
    img: '/assets/images/flags/de.svg',
    code: '+61',
    iso: 'aus'
  },
  {
    id: 'be',
    label: 'registration.formfield.select.country.belgium',
    img: '/assets/images/flags/be.svg',
    code: '+32',
    iso: 'bel'
  },
  {
    id: 'ch',
    label: 'registration.formfield.select.country.switzerland',
    img: '/assets/images/flags/ch.svg',
    code: '+41',
    iso: 'che'
  },
  {
    id: 'ca',
    label: 'registration.formfield.select.country.canada',
    img: '/assets/images/flags/ca.svg',
    code: '+1',
    iso: 'can'
  },
  {
    id: 'cz',
    label: 'registration.formfield.select.country.czech',
    img: '/assets/images/flags/cz.svg',
    code: '+420',
    iso: 'cze'
  },
  {
    id: 'de',
    label: 'registration.formfield.select.country.germany',
    img: '/assets/images/flags/de.svg',
    code: '+49',
    iso: 'deu'
  },
  {
    id: 'dk',
    label: 'registration.formfield.select.country.denmark',
    img: '/assets/images/flags/dk.svg',
    code: '+45',
    iso: 'dnk'
  },
  {
    id: 'es',
    label: 'registration.formfield.select.country.spain',
    img: '/assets/images/flags/pl.svg',
    code: '+34',
    iso: 'esp'
  },
  {
    id: 'ee',
    label: 'registration.formfield.select.country.estonia',
    img: '/assets/images/flags/et.svg',
    code: '+372',
    iso: 'est'
  },
  {
    id: 'gb',
    label: 'registration.formfield.select.country.unitedkingdom',
    img: '/assets/images/flags/no.svg',
    code: '+44',
    iso: 'gbr'
  },
  {
    id: 'hr',
    label: 'registration.formfield.select.country.croatia',
    img: '/assets/images/flags/hr.svg',
    code: '+385',
    iso: 'hrv'
  },
  {
    id: 'lt',
    label: 'registration.formfield.select.country.lithuania',
    img: '/assets/images/flags/lt.svg',
    code: '+370',
    iso: 'ltu'
  },
  {
    id: 'lv',
    label: 'registration.formfield.select.country.latvia',
    img: '/assets/images/flags/lv.svg',
    code: '+371',
    iso: 'lva'
  },
  {
    id: 'lu',
    label: 'registration.formfield.select.country.luxembourg',
    img: '/assets/images/flags/lu.svg',
    code: '+352',
    iso: 'lux'
  },
  {
    id: 'fi',
    label: 'registration.formfield.select.country.finland',
    img: '/assets/images/flags/fr.svg',
    code: '+358',
    iso: 'fin'
  },
  {
    id: 'fr',
    label: 'registration.formfield.select.country.france',
    img: '/assets/images/flags/fr.svg',
    code: '+33',
    iso: 'fra'
  },
  {
    id: 'ie',
    label: 'registration.formfield.select.country.ireland',
    img: '/assets/images/flags/ie.svg',
    code: '+353',
    iso: 'irl'
  },
  {
    id: 'it',
    label: 'registration.formfield.select.country.italy',
    img: '/assets/images/flags/fr.svg',
    code: '+39',
    iso: 'ita'
  },
  {
    id: 'nl',
    label: 'registration.formfield.select.country.netherlands',
    img: '/assets/images/flags/nl.svg',
    code: '+31',
    iso: 'nld'
  },
  {
    id: 'no',
    label: 'registration.formfield.select.country.norway',
    img: '/assets/images/flags/no.svg',
    code: '+47',
    iso: 'nor'
  },
  {
    id: 'nz',
    label: 'registration.formfield.select.country.newzealand',
    img: '/assets/images/flags/nz.svg',
    code: '+64',
    iso: 'nzl'
  },
  {
    id: 'pl',
    label: 'registration.formfield.select.country.poland',
    img: '/assets/images/flags/pl.svg',
    code: '+48',
    iso: 'pol'
  },
  {
    id: 'pt',
    label: 'registration.formfield.select.country.portugal',
    img: '/assets/images/flags/pl.svg',
    code: '+351',
    iso: 'prt'
  },
  {
    id: 'py',
    label: 'registration.formfield.select.country.paraguay',
    img: '/assets/images/flags/py.svg',
    code: '+595',
    iso: 'pry'
  },
  {
    id: 'ro',
    label: 'registration.formfield.select.country.romania',
    img: '/assets/images/flags/ro.svg',
    code: '+40',
    iso: 'rou'
  },
  {
    id: 'se',
    label: 'registration.formfield.select.country.sweden',
    img: '/assets/images/flags/se.svg',
    code: '+46',
    iso: 'swe'
  },
  {
    id: 'si',
    label: 'registration.formfield.select.country.slovenia',
    img: '/assets/images/flags/si.svg',
    code: '+386',
    iso: 'svn'
  },
  {
    id: 'sk',
    label: 'registration.formfield.select.country.slovakia',
    img: '/assets/images/flags/sk.svg',
    code: '+421',
    iso: 'svk'
  },
  {
    id: 'ua',
    label: 'registration.formfield.select.country.ukraine',
    img: '/assets/images/flags/ua.svg',
    code: '+380',
    iso: 'ukr'
  },
  {
    id: 'us',
    label: 'registration.formfield.select.country.usa',
    img: '/assets/images/flags/us.svg',
    code: '+1',
    iso: 'usa'
  },
  {
    id: 'uy',
    label: 'registration.formfield.select.country.uruguay',
    img: '/assets/images/flags/uy.svg',
    code: '+598',
    iso: 'ury'
  }
];

export const statesArr = {
  us: [
    { id: 'al', label: 'registration.formfield.select.states.us.al' },
    { id: 'ak', label: 'registration.formfield.select.states.us.ak' },
    { id: 'az', label: 'registration.formfield.select.states.us.az' },
    { id: 'ar', label: 'registration.formfield.select.states.us.ar' },
    { id: 'ca', label: 'registration.formfield.select.states.us.ca' },
    { id: 'co', label: 'registration.formfield.select.states.us.co' },
    { id: 'ct', label: 'registration.formfield.select.states.us.ct' },
    { id: 'de', label: 'registration.formfield.select.states.us.de' },
    { id: 'dc', label: 'registration.formfield.select.states.us.dc' },
    { id: 'fl', label: 'registration.formfield.select.states.us.fl' },
    { id: 'ga', label: 'registration.formfield.select.states.us.ga' },
    { id: 'hi', label: 'registration.formfield.select.states.us.hi' },
    { id: 'id', label: 'registration.formfield.select.states.us.id' },
    { id: 'il', label: 'registration.formfield.select.states.us.il' },
    { id: 'in', label: 'registration.formfield.select.states.us.in' },
    { id: 'ia', label: 'registration.formfield.select.states.us.ia' },
    { id: 'ks', label: 'registration.formfield.select.states.us.ks' },
    { id: 'ky', label: 'registration.formfield.select.states.us.ky' },
    { id: 'la', label: 'registration.formfield.select.states.us.la' },
    { id: 'me', label: 'registration.formfield.select.states.us.me' },
    { id: 'md', label: 'registration.formfield.select.states.us.md' },
    { id: 'ma', label: 'registration.formfield.select.states.us.ma' },
    { id: 'mi', label: 'registration.formfield.select.states.us.mi' },
    { id: 'mn', label: 'registration.formfield.select.states.us.mn' },
    { id: 'ms', label: 'registration.formfield.select.states.us.ms' },
    { id: 'mo', label: 'registration.formfield.select.states.us.mo' },
    { id: 'mt', label: 'registration.formfield.select.states.us.mt' },
    { id: 'ne', label: 'registration.formfield.select.states.us.ne' },
    { id: 'nv', label: 'registration.formfield.select.states.us.nv' },
    { id: 'nh', label: 'registration.formfield.select.states.us.nh' },
    { id: 'nj', label: 'registration.formfield.select.states.us.nj' },
    { id: 'nm', label: 'registration.formfield.select.states.us.nm' },
    { id: 'ny', label: 'registration.formfield.select.states.us.ny' },
    { id: 'nc', label: 'registration.formfield.select.states.us.nc' },
    { id: 'nd', label: 'registration.formfield.select.states.us.nd' },
    { id: 'oh', label: 'registration.formfield.select.states.us.oh' },
    { id: 'ok', label: 'registration.formfield.select.states.us.ok' },
    { id: 'or', label: 'registration.formfield.select.states.us.or' },
    { id: 'pa', label: 'registration.formfield.select.states.us.pa' },
    { id: 'ri', label: 'registration.formfield.select.states.us.ri' },
    { id: 'sc', label: 'registration.formfield.select.states.us.sc' },
    { id: 'sd', label: 'registration.formfield.select.states.us.sd' },
    { id: 'tn', label: 'registration.formfield.select.states.us.tn' },
    { id: 'tx', label: 'registration.formfield.select.states.us.tx' },
    { id: 'ut', label: 'registration.formfield.select.states.us.ut' },
    { id: 'vt', label: 'registration.formfield.select.states.us.vt' },
    { id: 'va', label: 'registration.formfield.select.states.us.va' },
    { id: 'wa', label: 'registration.formfield.select.states.us.wa' },
    { id: 'wv', label: 'registration.formfield.select.states.us.wv' },
    { id: 'wi', label: 'registration.formfield.select.states.us.wi' },
    { id: 'wy', label: 'registration.formfield.select.states.us.wy' }
  ],
  ca: [
    { id: 'nl', label: 'registration.formfield.select.states.ca.nl' },
    { id: 'pe', label: 'registration.formfield.select.states.ca.pe' },
    { id: 'ns', label: 'registration.formfield.select.states.ca.ns' },
    { id: 'nb', label: 'registration.formfield.select.states.ca.nb' },
    { id: 'qc', label: 'registration.formfield.select.states.ca.qc' },
    { id: 'on', label: 'registration.formfield.select.states.ca.on' },
    { id: 'mb', label: 'registration.formfield.select.states.ca.mb' },
    { id: 'sk', label: 'registration.formfield.select.states.ca.sk' },
    { id: 'ab', label: 'registration.formfield.select.states.ca.ab' },
    { id: 'bc', label: 'registration.formfield.select.states.ca.bc' },
    { id: 'yt', label: 'registration.formfield.select.states.ca.yt' },
    { id: 'nu', label: 'registration.formfield.select.states.ca.nu' },
    { id: 'nt', label: 'registration.formfield.select.states.ca.nt' }
  ],
  au: [
    { id: 'act', label: 'registration.formfield.select.states.au.act' },
    { id: 'nsw', label: 'registration.formfield.select.states.au.nsw' },
    { id: 'nt', label: 'registration.formfield.select.states.au.nt' },
    { id: 'qld', label: 'registration.formfield.select.states.au.qld' },
    { id: 'sa', label: 'registration.formfield.select.states.au.sa' },
    { id: 'tas', label: 'registration.formfield.select.states.au.tas' },
    { id: 'vic', label: 'registration.formfield.select.states.au.vic' },
    { id: 'wa', label: 'registration.formfield.select.states.au.wa' }
  ],
  ar: [
    { id: '02', label: 'registration.formfield.select.states.ar.02' },
    { id: '06', label: 'registration.formfield.select.states.ar.06' },
    { id: '10', label: 'registration.formfield.select.states.ar.10' },
    { id: '14', label: 'registration.formfield.select.states.ar.14' },
    { id: '18', label: 'registration.formfield.select.states.ar.18' },
    { id: '22', label: 'registration.formfield.select.states.ar.22' },
    { id: '26', label: 'registration.formfield.select.states.ar.26' },
    { id: '30', label: 'registration.formfield.select.states.ar.30' },
    { id: '34', label: 'registration.formfield.select.states.ar.34' },
    { id: '38', label: 'registration.formfield.select.states.ar.38' },
    { id: '42', label: 'registration.formfield.select.states.ar.42' },
    { id: '46', label: 'registration.formfield.select.states.ar.46' },
    { id: '50', label: 'registration.formfield.select.states.ar.50' },
    { id: '54', label: 'registration.formfield.select.states.ar.54' },
    { id: '58', label: 'registration.formfield.select.states.ar.58' },
    { id: '62', label: 'registration.formfield.select.states.ar.62' },
    { id: '66', label: 'registration.formfield.select.states.ar.66' },
    { id: '70', label: 'registration.formfield.select.states.ar.70' },
    { id: '74', label: 'registration.formfield.select.states.ar.74' },
    { id: '78', label: 'registration.formfield.select.states.ar.78' },
    { id: '82', label: 'registration.formfield.select.states.ar.82' },
    { id: '86', label: 'registration.formfield.select.states.ar.86' },
    { id: '90', label: 'registration.formfield.select.states.ar.90' },
    { id: '94', label: 'registration.formfield.select.states.ar.94' }
  ],
  ua: [
    { id: '01', label: 'registration.formfield.select.states.ua.01' },
    { id: '05', label: 'registration.formfield.select.states.ua.05' },
    { id: '07', label: 'registration.formfield.select.states.ua.07' },
    { id: '12', label: 'registration.formfield.select.states.ua.12' },
    { id: '14', label: 'registration.formfield.select.states.ua.14' },
    { id: '18', label: 'registration.formfield.select.states.ua.18' },
    { id: '21', label: 'registration.formfield.select.states.ua.21' },
    { id: '23', label: 'registration.formfield.select.states.ua.23' },
    { id: '26', label: 'registration.formfield.select.states.ua.26' },
    { id: '32', label: 'registration.formfield.select.states.ua.32' },
    { id: '35', label: 'registration.formfield.select.states.ua.35' },
    { id: '44', label: 'registration.formfield.select.states.ua.44' },
    { id: '46', label: 'registration.formfield.select.states.ua.46' },
    { id: '48', label: 'registration.formfield.select.states.ua.48' },
    { id: '51', label: 'registration.formfield.select.states.ua.51' },
    { id: '53', label: 'registration.formfield.select.states.ua.53' },
    { id: '56', label: 'registration.formfield.select.states.ua.56' },
    { id: '59', label: 'registration.formfield.select.states.ua.59' },
    { id: '61', label: 'registration.formfield.select.states.ua.61' },
    { id: '63', label: 'registration.formfield.select.states.ua.63' },
    { id: '65', label: 'registration.formfield.select.states.ua.65' },
    { id: '68', label: 'registration.formfield.select.states.ua.68' },
    { id: '71', label: 'registration.formfield.select.states.ua.71' },
    { id: '73', label: 'registration.formfield.select.states.ua.73' },
    { id: '74', label: 'registration.formfield.select.states.ua.74' },
    { id: '80', label: 'registration.formfield.select.states.ua.80' },
    { id: '85', label: 'registration.formfield.select.states.ua.85' }
  ],
  it: [
    { id: '001', label: 'registration.formfield.select.states.it.001' },
    { id: '002', label: 'registration.formfield.select.states.it.002' },
    { id: '003', label: 'registration.formfield.select.states.it.003' },
    { id: '004', label: 'registration.formfield.select.states.it.004' },
    { id: '005', label: 'registration.formfield.select.states.it.005' },
    { id: '006', label: 'registration.formfield.select.states.it.006' },
    { id: '007', label: 'registration.formfield.select.states.it.007' },
    { id: '008', label: 'registration.formfield.select.states.it.008' },
    { id: '009', label: 'registration.formfield.select.states.it.009' },
    { id: '010', label: 'registration.formfield.select.states.it.010' },
    { id: '011', label: 'registration.formfield.select.states.it.011' },
    { id: '012', label: 'registration.formfield.select.states.it.012' },
    { id: '013', label: 'registration.formfield.select.states.it.013' },
    { id: '014', label: 'registration.formfield.select.states.it.014' },
    { id: '015', label: 'registration.formfield.select.states.it.015' },
    { id: '016', label: 'registration.formfield.select.states.it.016' },
    { id: '017', label: 'registration.formfield.select.states.it.017' },
    { id: '018', label: 'registration.formfield.select.states.it.018' },
    { id: '019', label: 'registration.formfield.select.states.it.019' },
    { id: '020', label: 'registration.formfield.select.states.it.020' },
    { id: '021', label: 'registration.formfield.select.states.it.021' },
    { id: '022', label: 'registration.formfield.select.states.it.022' },
    { id: '023', label: 'registration.formfield.select.states.it.023' },
    { id: '024', label: 'registration.formfield.select.states.it.024' },
    { id: '025', label: 'registration.formfield.select.states.it.025' },
    { id: '026', label: 'registration.formfield.select.states.it.026' },
    { id: '027', label: 'registration.formfield.select.states.it.027' },
    { id: '028', label: 'registration.formfield.select.states.it.028' },
    { id: '029', label: 'registration.formfield.select.states.it.029' },
    { id: '030', label: 'registration.formfield.select.states.it.030' },
    { id: '031', label: 'registration.formfield.select.states.it.031' },
    { id: '032', label: 'registration.formfield.select.states.it.032' },
    { id: '033', label: 'registration.formfield.select.states.it.033' },
    { id: '034', label: 'registration.formfield.select.states.it.034' },
    { id: '035', label: 'registration.formfield.select.states.it.035' },
    { id: '036', label: 'registration.formfield.select.states.it.036' },
    { id: '037', label: 'registration.formfield.select.states.it.037' },
    { id: '038', label: 'registration.formfield.select.states.it.038' },
    { id: '039', label: 'registration.formfield.select.states.it.039' },
    { id: '040', label: 'registration.formfield.select.states.it.040' },
    { id: '041', label: 'registration.formfield.select.states.it.041' },
    { id: '042', label: 'registration.formfield.select.states.it.042' },
    { id: '043', label: 'registration.formfield.select.states.it.043' },
    { id: '044', label: 'registration.formfield.select.states.it.044' },
    { id: '045', label: 'registration.formfield.select.states.it.045' },
    { id: '046', label: 'registration.formfield.select.states.it.046' },
    { id: '047', label: 'registration.formfield.select.states.it.047' },
    { id: '048', label: 'registration.formfield.select.states.it.048' },
    { id: '049', label: 'registration.formfield.select.states.it.049' },
    { id: '050', label: 'registration.formfield.select.states.it.050' },
    { id: '051', label: 'registration.formfield.select.states.it.051' },
    { id: '052', label: 'registration.formfield.select.states.it.052' },
    { id: '053', label: 'registration.formfield.select.states.it.053' },
    { id: '054', label: 'registration.formfield.select.states.it.054' },
    { id: '055', label: 'registration.formfield.select.states.it.055' },
    { id: '056', label: 'registration.formfield.select.states.it.056' },
    { id: '057', label: 'registration.formfield.select.states.it.057' },
    { id: '058', label: 'registration.formfield.select.states.it.058' },
    { id: '059', label: 'registration.formfield.select.states.it.059' },
    { id: '060', label: 'registration.formfield.select.states.it.060' },
    { id: '061', label: 'registration.formfield.select.states.it.061' },
    { id: '062', label: 'registration.formfield.select.states.it.062' },
    { id: '063', label: 'registration.formfield.select.states.it.063' },
    { id: '064', label: 'registration.formfield.select.states.it.064' },
    { id: '065', label: 'registration.formfield.select.states.it.065' },
    { id: '066', label: 'registration.formfield.select.states.it.066' },
    { id: '067', label: 'registration.formfield.select.states.it.067' },
    { id: '068', label: 'registration.formfield.select.states.it.068' },
    { id: '069', label: 'registration.formfield.select.states.it.069' },
    { id: '070', label: 'registration.formfield.select.states.it.070' },
    { id: '071', label: 'registration.formfield.select.states.it.071' },
    { id: '072', label: 'registration.formfield.select.states.it.072' },
    { id: '073', label: 'registration.formfield.select.states.it.073' },
    { id: '074', label: 'registration.formfield.select.states.it.074' },
    { id: '075', label: 'registration.formfield.select.states.it.075' },
    { id: '076', label: 'registration.formfield.select.states.it.076' },
    { id: '077', label: 'registration.formfield.select.states.it.077' },
    { id: '078', label: 'registration.formfield.select.states.it.078' },
    { id: '079', label: 'registration.formfield.select.states.it.079' },
    { id: '080', label: 'registration.formfield.select.states.it.080' },
    { id: '081', label: 'registration.formfield.select.states.it.081' },
    { id: '082', label: 'registration.formfield.select.states.it.082' },
    { id: '083', label: 'registration.formfield.select.states.it.083' },
    { id: '084', label: 'registration.formfield.select.states.it.084' },
    { id: '085', label: 'registration.formfield.select.states.it.085' },
    { id: '086', label: 'registration.formfield.select.states.it.086' },
    { id: '087', label: 'registration.formfield.select.states.it.087' },
    { id: '088', label: 'registration.formfield.select.states.it.088' },
    { id: '089', label: 'registration.formfield.select.states.it.089' },
    { id: '090', label: 'registration.formfield.select.states.it.090' },
    { id: '091', label: 'registration.formfield.select.states.it.091' },
    { id: '092', label: 'registration.formfield.select.states.it.092' },
    { id: '093', label: 'registration.formfield.select.states.it.093' },
    { id: '094', label: 'registration.formfield.select.states.it.094' },
    { id: '095', label: 'registration.formfield.select.states.it.095' },
    { id: '096', label: 'registration.formfield.select.states.it.096' },
    { id: '097', label: 'registration.formfield.select.states.it.097' },
    { id: '098', label: 'registration.formfield.select.states.it.098' },
    { id: '099', label: 'registration.formfield.select.states.it.099' },
    { id: '100', label: 'registration.formfield.select.states.it.100' },
    { id: '101', label: 'registration.formfield.select.states.it.101' },
    { id: '102', label: 'registration.formfield.select.states.it.102' },
    { id: '103', label: 'registration.formfield.select.states.it.103' },
    { id: '108', label: 'registration.formfield.select.states.it.108' },
    { id: '109', label: 'registration.formfield.select.states.it.109' },
    { id: '110', label: 'registration.formfield.select.states.it.110' },
    { id: '111', label: 'registration.formfield.select.states.it.111' }
  ]
};

export const territoriesArr = {
  gb: [
    { id: 'kh', label: 'registration.formfield.select.states.gb.kh' },
    { id: 'la', label: 'registration.formfield.select.states.gb.la' },
    { id: 'lbc', label: 'registration.formfield.select.states.gb.lbc' },
    { id: 'lc', label: 'registration.formfield.select.states.gb.lc' },
    { id: 'le', label: 'registration.formfield.select.states.gb.le' },
    { id: 'li', label: 'registration.formfield.select.states.gb.li' },
    { id: 'lk', label: 'registration.formfield.select.states.gb.lk' },
    { id: 'lo', label: 'registration.formfield.select.states.gb.lo' },
    { id: 'lt', label: 'registration.formfield.select.states.gb.lt' },
    { id: 'lu', label: 'registration.formfield.select.states.gb.lu' },
    { id: 'mea', label: 'registration.formfield.select.states.gb.mea' },
    { id: 'mg', label: 'registration.formfield.select.states.gb.mg' },
    { id: 'mi', label: 'registration.formfield.select.states.gb.mi' },
    { id: 'mk', label: 'registration.formfield.select.states.gb.mk' },
    { id: 'mon', label: 'registration.formfield.select.states.gb.mon' },
    { id: 'mr', label: 'registration.formfield.select.states.gb.mr' },
    { id: 'mul', label: 'registration.formfield.select.states.gb.mul' },
    { id: 'my', label: 'registration.formfield.select.states.gb.my' },
    { id: 'nc', label: 'registration.formfield.select.states.gb.nc' },
    { id: 'ne', label: 'registration.formfield.select.states.gb.ne' },
    { id: 'nh', label: 'registration.formfield.select.states.gb.nh' },
    { id: 'nk', label: 'registration.formfield.select.states.gb.nk' },
    { id: 'nl', label: 'registration.formfield.select.states.gb.nl' },
    { id: 'nmd', label: 'registration.formfield.select.states.gb.nmd' },
    { id: 'ns', label: 'registration.formfield.select.states.gb.ns' },
    { id: 'nt', label: 'registration.formfield.select.states.gb.nt' },
    { id: 'nu', label: 'registration.formfield.select.states.gb.nu' },
    { id: 'or', label: 'registration.formfield.select.states.gb.or' },
    { id: 'ox', label: 'registration.formfield.select.states.gb.ox' },
    { id: 'pb', label: 'registration.formfield.select.states.gb.pb' },
    { id: 'pm', label: 'registration.formfield.select.states.gb.pm' },
    { id: 'po', label: 'registration.formfield.select.states.gb.po' },
    { id: 'py', label: 'registration.formfield.select.states.gb.py' },
    { id: 'rd', label: 'registration.formfield.select.states.gb.rd' },
    { id: 're', label: 'registration.formfield.select.states.gb.re' },
    { id: 'rm', label: 'registration.formfield.select.states.gb.rm' },
    { id: 'ru', label: 'registration.formfield.select.states.gb.ru' },
    { id: 'sc', label: 'registration.formfield.select.states.gb.sc' },
    { id: 'sd', label: 'registration.formfield.select.states.gb.sd' },
    { id: 'se', label: 'registration.formfield.select.states.gb.se' },
    { id: 'sg', label: 'registration.formfield.select.states.gb.sg' },
    { id: 'sh', label: 'registration.formfield.select.states.gb.sh' },
    { id: 'sk', label: 'registration.formfield.select.states.gb.sk' },
    { id: 'so', label: 'registration.formfield.select.states.gb.so' },
    { id: 'sp', label: 'registration.formfield.select.states.gb.sp' },
    { id: 'ss', label: 'registration.formfield.select.states.gb.ss' },
    { id: 'sl', label: 'registration.formfield.select.states.gb.sl' },
    { id: 'sw', label: 'registration.formfield.select.states.gb.sw' },
    { id: 'sy', label: 'registration.formfield.select.states.gb.sy' },
    { id: 'ta', label: 'registration.formfield.select.states.gb.ta' },
    { id: 'th', label: 'registration.formfield.select.states.gb.th' },
    { id: 'to', label: 'registration.formfield.select.states.gb.to' },
    { id: 'tw', label: 'registration.formfield.select.states.gb.tw' },
    { id: 'wa', label: 'registration.formfield.select.states.gb.wa' },
    { id: 'wc', label: 'registration.formfield.select.states.gb.wc' },
    { id: 'wd', label: 'registration.formfield.select.states.gb.wd' },
    { id: 'wg', label: 'registration.formfield.select.states.gb.wg' },
    { id: 'wi', label: 'registration.formfield.select.states.gb.wi' },
    { id: 'wl', label: 'registration.formfield.select.states.gb.wl' },
    { id: 'wm', label: 'registration.formfield.select.states.gb.wm' },
    { id: 'wo', label: 'registration.formfield.select.states.gb.wo' },
    { id: 'wr', label: 'registration.formfield.select.states.gb.wr' },
    { id: 'wt', label: 'registration.formfield.select.states.gb.wt' },
    { id: 'ye', label: 'registration.formfield.select.states.gb.ye' },
    { id: 'yk', label: 'registration.formfield.select.states.gb.yk' },
    { id: 'yn', label: 'registration.formfield.select.states.gb.yn' },
    { id: 'ys', label: 'registration.formfield.select.states.gb.ys' },
    { id: 'yw', label: 'registration.formfield.select.states.gb.yw' }
  ],
  ie: [
    { id: '01', label: 'registration.formfield.select.states.ie.01' },
    { id: '021', label: 'registration.formfield.select.states.ie.21' },
    { id: '022', label: 'registration.formfield.select.states.ie.022' },
    { id: '023', label: 'registration.formfield.select.states.ie.023' },
    { id: '024', label: 'registration.formfield.select.states.ie.024' },
    { id: '03', label: 'registration.formfield.select.states.ie.03' },
    { id: '04', label: 'registration.formfield.select.states.ie.04' },
    { id: '05', label: 'registration.formfield.select.states.ie.05' },
    { id: '06', label: 'registration.formfield.select.states.ie.06' },
    { id: '07', label: 'registration.formfield.select.states.ie.07' },
    { id: '08', label: 'registration.formfield.select.states.ie.08' },
    { id: '09', label: 'registration.formfield.select.states.ie.09' },
    { id: '10', label: 'registration.formfield.select.states.ie.10' },
    { id: '11', label: 'registration.formfield.select.states.ie.11' },
    { id: '12', label: 'registration.formfield.select.states.ie.12' },
    { id: '13', label: 'registration.formfield.select.states.ie.13' },
    { id: '141', label: 'registration.formfield.select.states.ie.141' },
    { id: '142', label: 'registration.formfield.select.states.ie.142' },
    { id: '15', label: 'registration.formfield.select.states.ie.15' },
    { id: '16', label: 'registration.formfield.select.states.ie.16' },
    { id: '17', label: 'registration.formfield.select.states.ie.17' },
    { id: '18', label: 'registration.formfield.select.states.ie.18' },
    { id: '191', label: 'registration.formfield.select.states.ie.191' },
    { id: '192', label: 'registration.formfield.select.states.ie.192' },
    { id: '20', label: 'registration.formfield.select.states.ie.20' },
    { id: '21', label: 'registration.formfield.select.states.ie.21' },
    { id: '22', label: 'registration.formfield.select.states.ie.22' },
    { id: '23', label: 'registration.formfield.select.states.ie.23' },
    { id: '24', label: 'registration.formfield.select.states.ie.24' },
    { id: '25', label: 'registration.formfield.select.states.ie.25' },
    { id: '26', label: 'registration.formfield.select.states.ie.26' }
  ],
  ro: [
    { id: '01', label: 'registration.formfield.select.territories.ro.01' },
    { id: '02', label: 'registration.formfield.select.territories.ro.02' },
    { id: '03', label: 'registration.formfield.select.territories.ro.03' },
    { id: '04', label: 'registration.formfield.select.territories.ro.04' },
    { id: '05', label: 'registration.formfield.select.territories.ro.05' },
    { id: '06', label: 'registration.formfield.select.territories.ro.06' },
    { id: '07', label: 'registration.formfield.select.territories.ro.07' },
    { id: '08', label: 'registration.formfield.select.territories.ro.08' },
    { id: '09', label: 'registration.formfield.select.territories.ro.09' },
    { id: '10', label: 'registration.formfield.select.territories.ro.10' },
    { id: '11', label: 'registration.formfield.select.territories.ro.11' },
    { id: '12', label: 'registration.formfield.select.territories.ro.12' },
    { id: '13', label: 'registration.formfield.select.territories.ro.13' },
    { id: '14', label: 'registration.formfield.select.territories.ro.14' },
    { id: '15', label: 'registration.formfield.select.territories.ro.15' },
    { id: '16', label: 'registration.formfield.select.territories.ro.16' },
    { id: '17', label: 'registration.formfield.select.territories.ro.17' },
    { id: '18', label: 'registration.formfield.select.territories.ro.18' },
    { id: '19', label: 'registration.formfield.select.territories.ro.19' },
    { id: '20', label: 'registration.formfield.select.territories.ro.20' },
    { id: '21', label: 'registration.formfield.select.territories.ro.21' },
    { id: '22', label: 'registration.formfield.select.territories.ro.22' },
    { id: '23', label: 'registration.formfield.select.territories.ro.23' },
    { id: '24', label: 'registration.formfield.select.territories.ro.24' },
    { id: '25', label: 'registration.formfield.select.territories.ro.25' },
    { id: '26', label: 'registration.formfield.select.territories.ro.26' },
    { id: '27', label: 'registration.formfield.select.territories.ro.27' },
    { id: '28', label: 'registration.formfield.select.territories.ro.28' },
    { id: '29', label: 'registration.formfield.select.territories.ro.29' },
    { id: '30', label: 'registration.formfield.select.territories.ro.30' },
    { id: '31', label: 'registration.formfield.select.territories.ro.31' },
    { id: '32', label: 'registration.formfield.select.territories.ro.32' },
    { id: '33', label: 'registration.formfield.select.territories.ro.33' },
    { id: '34', label: 'registration.formfield.select.territories.ro.34' },
    { id: '35', label: 'registration.formfield.select.territories.ro.35' },
    { id: '36', label: 'registration.formfield.select.territories.ro.36' },
    { id: '37', label: 'registration.formfield.select.territories.ro.37' },
    { id: '38', label: 'registration.formfield.select.territories.ro.38' },
    { id: '39', label: 'registration.formfield.select.territories.ro.39' },
    { id: '40', label: 'registration.formfield.select.territories.ro.40' },
    { id: '51', label: 'registration.formfield.select.territories.ro.51' },
    { id: '52', label: 'registration.formfield.select.territories.ro.52' }
  ]
};
