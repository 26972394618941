import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {
  ResponseDataFromDialog,
  passedDataToDialog
} from '../dialog-window/dialog-window.component';
import { ButtonClass } from '@claas/claas-form-components';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subscription, finalize } from 'rxjs';
import { errorStatusObject } from '../address-view/address-view.component';
import { ApiService } from '../../services/api.service';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { Organization } from '../../models/organization.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-organisation-type-view',
  templateUrl: './organisation-type-view.component.html',
  styleUrls: ['./organisation-type-view.component.scss']
})
export class OrganisationTypeViewComponent implements OnInit, OnDestroy {
  @Input() data: passedDataToDialog = {};
  @Input() secondaryTextButtonClass?: ButtonClass;

  @Output() displayPopup = new EventEmitter();
  @Output() closeDialogWindow = new EventEmitter();

  tempType = '';
  organisationTypeDetails = environment.general.organisation_types;
  organisationTypeOptions: string[] = [];
  myForm: FormGroup | undefined;
  subscriptions = new Subscription();
  invalidSaveButtonState$ = new BehaviorSubject(true);
  errorsStatusObject$: errorStatusObject = {};

  typeOptions = [
    {
      "text": "label",
      "key": "key"
    }
  ];

  constructor(
    private apiService: ApiService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.organisationTypeOptions = this.setOrganisationOptions(
      this.organisationTypeDetails
    );
    this.tempType = this.data.organisationData?.type
      ? this.data.organisationData?.type
      : '';

    this.typeOptions = this.setOrgTypeOptions();

    this.myForm = this.setForm();
    this.trackForm(this.myForm);
  }

  setOrganisationOptions(typeDetails: any[]) {
    return typeDetails.map(element => element?.value);
  }

  setOrgTypeOptions() {
    var options: any[] = [];
    for(var ot of this.organisationTypeOptions) {
      var o = {"text": this.getType(ot),"key": ot};
      options.push(o);
    }
    return options;
  }

  getType(ot: string) {
    var label = '';
    this.translateService.get('registration.formfield.select.'+ot.toUpperCase()).subscribe(
      (data:string) => label = data
    );
    return label;
  }

  setForm(): FormGroup {
    return new FormGroup({
      type: new FormControl(this.tempType, [Validators.required])
    });
  }

  trackForm(myForm: FormGroup): void {
    // @ts-ignore
    this.errorsStatusObject$['type'] = this.myForm
      .get('type')
      .valueChanges.pipe(
        map(value => {
          return this.myForm?.get('type')?.status === 'INVALID'
            ? 'registration.validationMsgs.required'
            : undefined;
        })
      );

    this.subscriptions.add(
      myForm.valueChanges.subscribe(() => {
        // update the save button status
        this.invalidSaveButtonState$.next(!!this.myForm?.invalid);
      })
    );
  }

  onCancelClicked(): any {
    const passedObject = {
      action: 'cancel'
    };

    this.closeDialogWindow.emit(passedObject);
  }

  onSaveClicked(): any {
    const passedObject: ResponseDataFromDialog = {
      context: this.data.context,
      action: 'save'
    };

    const organisationUpdate = this.preUpdateOrganisation();

    this.saveNewTypeDetails(passedObject, organisationUpdate);
    this.invalidSaveButtonState$.next(true);
  }

  saveNewTypeDetails(
    passedObject: ResponseDataFromDialog,
    organisationUpdate: Organization
  ): void {
    this.subscriptions.add(
      this.apiService
        .updateOrganisation(
          this.data.organisationData.auth0Id,
          organisationUpdate
        )
        .pipe(finalize(() => this.closeDialogWindow.emit(passedObject)))
        .subscribe({
          next: value => {
            // Update the organisation data
            this.translateService.get('organization.edit_type.success').subscribe(text => {
              passedObject.refreshIsRequired = true;
              this.displayPopup.emit({ text, className: '' });
            });
          },
          error: err => {
            this.translateService.get('organization.edit_type.fail').subscribe(text => {
              this.displayPopup.emit({ text, className: 'error' });
            });
          }
        })
    );
  }

  preUpdateOrganisation(): Organization {
    const organisationUpdate = new Organization();

    switch (this.data.context) {
      case 'organisation-type':
        organisationUpdate.copyOrganisation(this.data.organisationData);
        organisationUpdate.type = this.myForm?.get('type')?.value
          ? this.myForm?.get('type')?.value
          : '';
        break;
    }

    return organisationUpdate;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
