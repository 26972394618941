<div class="organisation-users-view">
  <div class="organisation-users-view__header">
    <div class="organisation-users-view__header-main">
      <div [ngSwitch]="data.subContext">
        <ng-container *ngSwitchCase="'organisation-leave'">
          <h2 class="organisation-users-view__header-title subtitle-2">
            {{ 'organization.leave_organization.header' | translate }}
          </h2>
        </ng-container>

        <ng-container *ngSwitchCase="'organisation-remove-user'">
          <h2 class="organisation-users-view__header-title subtitle-2">
            {{ 'organization.remove_user.header' | translate }}
          </h2>
        </ng-container>

        <ng-container *ngSwitchCase="'organisation-invitation'">
          <h2 class="organisation-users-view__header-title subtitle-2">
            {{ 'organization.user_invitation.header' | translate }}
          </h2>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="organisation-users-view__body">
    <div class="organisation-users-view__body-content">
      <div
        *ngIf="(confirmationDialogIsActive$ | async) === false"
        [ngSwitch]="data.subContext">
        <!-- leave organisation body -->
        <ng-container *ngSwitchCase="'organisation-leave'">
          <span class="body-content__information">
            {{
              'organization.leave_organization.last_admin' | translate
            }}
          </span>
        </ng-container>

        <!-- remove user from the organisation body -->
        <ng-container *ngSwitchCase="'organisation-remove-user'">
          <span class="body-content__information">
            {{ 'generic.delete_text' | translate }}
          </span>
        </ng-container>

        <!-- invitation organisation body -->
        <ng-container *ngSwitchCase="'organisation-invitation'">
          <div class="organisation-users-view__body-content--line">
            <span class="body-content__information">
              {{ 'organization.user_invitation.info' | translate }}
            </span>
          </div>

          <div class="organisation-users-view__body-content--line email-line">
            <form *ngIf="myForm" [formGroup]="myForm">
              <claas-text-field
                ngDefaultControl
                class="full-width"
                formControlName="email"
                [label]="'organization.user_invitation.email_label' | translate"
                [placeholder]="
                  'organization.user_invitation.enter_email_address' | translate
                "
                [error]="
                  errorsStatusObject$['email'] | async | translate
                "></claas-text-field>

              <claas-button
                class="organisation-data-users__header-action add-btn"
                [icon]="addIcon"
                [text]="''"
                [buttonClass]="primaryButtonClass"
                [size]="mediumButtonSize"
                [disabled]="!!(invalidSaveButtonState$ | async)"
                (onClick)="addEmailToTheList()"></claas-button>
            </form>
          </div>

          <div class="organisation-users-view__body-content--line emails-line">
            <span class="emails-header">{{
              'organization.user_invitation.email_addresses' | translate
            }}</span>

            <div *ngIf="targetEmails.length" class="emails-container">
              <claas-chip
                *ngFor="let email of targetEmails; index as i"
                [label]="email"
                (click)="removeEmailFromTheList(i)"></claas-chip>
            </div>

            <div *ngIf="!targetEmails.length" class="no-emails-container">
              <span>{{ 'organization.user_invitation.no_email_addresses' | translate }}</span>
            </div>
          </div>

          <div class="organisation-users-view__body-content--line last-line">
            <claas-dropdown-key-value
              [defaultOption]="data.profileRole ? getRoleOption(data.profileRole) : undefined"
              [label]="'organization.user_role' | translate"
              [options]="data.roleOptions ? getRoleOptions(data.roleOptions) : []"
              (onChange)="updateProfileRole($event)"></claas-dropdown-key-value>

            <claas-icon-button
              [icon]="infoIcon"
              [iconSize]="mediumIconSize"
              [fontSize]="mediumIconFontSize"
              (click)="showRoleAndRightsInfo()"></claas-icon-button>
          </div>
        </ng-container>
      </div>

      <div *ngIf="confirmationDialogIsActive$ | async">
        <span class="organisation-users-view__confirmation-dialog--text">{{
          'generic.delete_text' | translate
        }}</span>
      </div>
    </div>
  </div>

  <div class="organisation-users-view__dialog-actions">
    <div [ngSwitch]="data.subContext">
      <!-- leave organisation actions -->
      <ng-container *ngSwitchCase="'organisation-leave'">
        <div class="organisation-leave">
          <claas-button
            *ngIf="confirmationDialogIsActive$ | async"
            class="btn-left"
            [buttonClass]="errorButtonClass"
            [text]="'organization.leave_organization.header' | translate"
            (onClick)="actionIsConfirmed()">
          </claas-button>

          <claas-button
            *ngIf="confirmationDialogIsActive$ | async"
            class="btn-left"
            [buttonClass]="secondaryTextButtonClass"
            [text]="'generic.cancel' | translate"
            (onClick)="actionIsCanceled()">
          </claas-button>

          <claas-button
            *ngIf="(confirmationDialogIsActive$ | async) === false"
            class="btn-right save-btn"
            [text]="'generic.understood' | translate"
            (onClick)="showConfirmationDialog()">
          </claas-button>

          <claas-button
            *ngIf="(confirmationDialogIsActive$ | async) === false"
            class="btn-left"
            [buttonClass]="secondaryTextButtonClass"
            [text]="'generic.cancel' | translate"
            (onClick)="actionIsCanceled()">
          </claas-button>
        </div>
      </ng-container>

      <!-- remove user from the organisation actions -->
      <ng-container *ngSwitchCase="'organisation-remove-user'">
        <div class="organisation-remove-user">
          <claas-button
            class="btn-left"
            [buttonClass]="errorButtonClass"
            [text]="'organization.remove_user.header' | translate"
            (onClick)="actionIsConfirmed()">
          </claas-button>

          <claas-button
            class="btn-left"
            [buttonClass]="secondaryTextButtonClass"
            [text]="'generic.cancel' | translate"
            (onClick)="actionIsCanceled()">
          </claas-button>
        </div>
      </ng-container>

      <!-- invitation organisation actions -->
      <ng-container *ngSwitchCase="'organisation-invitation'">
        <div class="organisation-invitation">
          <claas-button
            class="btn-right save-btn"
            [text]="'generic.confirm' | translate"
            [disabled]="!targetEmails.length || !data.profileRole"
            (onClick)="actionIsConfirmed()">
          </claas-button>

          <claas-button
            class="btn-left"
            [buttonClass]="secondaryTextButtonClass"
            [text]="'generic.cancel' | translate"
            (onClick)="actionIsCanceled()">
          </claas-button>
        </div>
      </ng-container>
    </div>
  </div>
</div>
