import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { passedDataToDialog } from '../dialog-window/dialog-window.component';
import { ButtonSize } from '@claas/claas-form-components';
import { BehaviorSubject } from 'rxjs';
import { UserAddress } from '../../models/user-address.model';
import {
  CircularProgressDiameter,
  ProgressColor
} from '@claas/claas-layout-components';

import { errorStatusObject } from '../address-view/address-view.component';
import { OrgAddressPatch } from '../../models/org-address-patch.model';

@Component({
  selector: 'app-address-validation-view',
  templateUrl: './address-validation-view.component.html',
  styleUrls: ['./address-validation-view.component.scss']
})
export class AddressValidationViewComponent implements OnInit {
  @Input() data: passedDataToDialog | undefined;

  @Output() closeDialogWindow = new EventEmitter();
  selectedAddress: UserAddress | OrgAddressPatch | undefined;

  largeSize = CircularProgressDiameter.LARGE;
  mediumButtonSize = ButtonSize.DEFAULT_MEDIUM;
  secondaryColor = ProgressColor.SECONDARY;

  errorsStatusObject$: errorStatusObject = {};
  invalidSaveButtonState$ = new BehaviorSubject(true);
  proposedAddresses$: BehaviorSubject<any> = new BehaviorSubject([]);
  inputAddress$: BehaviorSubject<any> = new BehaviorSubject([]);

  constructor() {}

  ngOnInit() {
    if (this.data?.inputAddress) {
      this.inputAddress$.next(this.data?.inputAddress);
    }
    if (this.data?.proposedAddresses) {
      this.proposedAddresses$.next(this.data?.proposedAddresses);
    }
  }

  changeSelectedAddress(address: UserAddress) {
    this.selectedAddress = address;
  }

  onSaveClicked(): void {
    const passedObject = {
      selectedAddress: this.selectedAddress
    };
    this.closeDialogWindow.emit(passedObject);
  }
}
