<div class="marketing-permission-view">
  <div class="marketing-permission-view__header">
    <div class="marketing-permission-view__header-title">
      <h2 *ngIf="data?.marketingPermission?.type === 'claas'" class="header-3-bold">
        {{ 'preference_center.claas' | translate }}
      </h2>

      <h2 *ngIf="data?.marketingPermission?.type === 'dealer'" class="header-3-bold">
        {{  'preference_center.dealer' | translate }}
      </h2>
    </div>
    <div class="marketing-permission-view__header-subtitle">
      <span *ngIf="data?.marketingPermission?.type === 'claas'">
        {{  'preference_center.mp_settings' | translate }}
      </span>

      <span *ngIf="data?.marketingPermission?.type === 'dealer'">
        {{ data?.marketingPermission?.dealer_name }}
      </span>

  </div>

  <div class="marketing-permission-view__body">
    <div *ngIf="data?.marketingPermission?.type === 'claas'" class="marketing-permission-view__body-text">
      {{ 'preference_center.edit.text1_claas' | translate }}
    </div>

    <div *ngIf="data?.marketingPermission?.type === 'dealer'" class="marketing-permission-view__body-text">
      {{ 'preference_center.edit.text1_dealer' | translate }}
    </div>

    <div *ngIf="data.marketingPermission && data.marketingPermission.channels" class="marketing-permission-view__body-choices">
      <div class="marketing-permission-view__body-line">
        <div
          class="marketing-permission-view__body-checkbox">
          <claas-checkbox
            [checked]="data.marketingPermission.channels.phone"
            [label]="'registration.terms.marketingPermissionPhone' | translate"
            (onChange)="updateChannel('phone')"
          ></claas-checkbox>
        </div>
        <div
          class="marketing-permission-view__body-checkbox">
          <claas-checkbox
            [checked]="data.marketingPermission.channels.email"
            [label]="'registration.terms.marketingPermissionEmail' | translate"
            (onChange)="updateChannel('email')"
          ></claas-checkbox>
        </div>
      </div>

      <div class="marketing-permission-view__body-line">
        <div
          class="marketing-permission-view__body-checkbox">
          <claas-checkbox
            [checked]="data.marketingPermission.channels.push"
            [label]="'registration.terms.marketingPermissionPushNotification' | translate"
            (onChange)="updateChannel('push')"
          ></claas-checkbox>
        </div>
        <div
          class="marketing-permission-view__body-checkbox">
          <claas-checkbox
            [checked]="data.marketingPermission.channels.post"
            [label]="'registration.terms.marketingPermissionPost' | translate"
            (onChange)="updateChannel('post')"
          ></claas-checkbox>
        </div>
      </div>
    </div>

    <div *ngIf="data?.marketingPermission?.type === 'claas'" class="marketing-permission-view__body-text">
      {{ 'preference_center.edit.text2_claas' | translate }}
    </div>

    <div *ngIf="data?.marketingPermission?.type === 'dealer'" class="marketing-permission-view__body-text">
      {{ 'preference_center.edit.text2_dealer' | translate }}
    </div>

    <p class="marketing-permission-view__body-text"
       [innerHTML]="'preference_center.edit.data_privacy' | translate">
    </p>

    <div class="marketing-permission-view__footer">
      <div *ngIf="data?.marketingPermission?.type === 'claas'" class="marketing-permission-view__footer-text">
        {{ 'preference_center.edit.subtext_claas' | translate }}
      </div>

      <div *ngIf="data?.marketingPermission?.type === 'dealer'" class="marketing-permission-view__footer-text">
        {{ 'preference_center.edit.subtext_dealer' | translate }}
      </div>
    </div>
  </div>

  <div class="marketing-permission-view__actions">
    <claas-button
      *ngIf="data?.buttonSave"
      [text]="'preference_center.edit.save' | translate"
      [disabled]=" !!(actionInProgress$ | async)"
      (click)="onSaveClicked()">
    </claas-button>

    <claas-button
      *ngIf="data?.buttonCancel"
      [buttonClass]="secondaryTextButtonClass"
      [text]="'generic.cancel' | translate"
      [disabled]="!!(actionInProgress$ | async)"
      (click)="onCancelClicked()">
    </claas-button>
  </div>
</div>
