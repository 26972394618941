import { environment } from '../../environments/environment';

export const applicationsArr = [
  {
    id: environment.auth.auth_client_id,
    name: 'CLAAS_ID',
    login_url: environment.base_urls.app_base_url
  },
  {
    id: environment.client_ids.auth_connect_id,
    name: 'CLAAS_connect',
    login_url: environment.login_urls.cc_login_url
  },
  {
    id: environment.client_ids.auth_collection_id,
    name: 'CLAAS_collection',
    login_url: environment.login_urls.collection_login_url
  },
  {
    id: environment.client_ids.auth_connect_app_id,
    name: 'CLAAS_connect_app',
    login_url: environment.base_urls.cc_app_base_url
  },
  {
    id: environment.client_ids.auth_connect_3_id,
    name: 'CLAAS_connect_3',
    login_url: environment.base_urls.cc_3_base_url
  },
  {
    id: environment.client_ids.auth_connect_3_app_id,
    name: 'CLAAS_connect_3_app',
    login_url: environment.base_urls.cc_3_base_url
  },
  {
    id: environment.client_ids.auth_fcr_id,
    name: 'FCR',
    login_url: environment.base_urls.fcr_base_url
  },
  {
    id: environment.client_ids.auth_fcu_id,
    name: 'FCU',
    login_url: environment.login_urls.fcu_login_url
  },
  {
    id: environment.client_ids.auth_ls_id,
    name: 'LS',
    login_url: environment.login_urls.ls_login_url
  },
  {
    id: environment.client_ids.auth_et_id,
    name: 'ET',
    login_url: environment.login_urls.et_login_url
  },
  {
    id: environment.client_ids.auth_et_id_uk,
    name: 'ET_UK',
    login_url: environment.login_urls.et_login_url_uk
  },
  {
    id: environment.client_ids.auth_et_id_fr,
    name: 'ET_FR',
    login_url: environment.login_urls.et_login_url_fr
  },
  {
    id: environment.client_ids.auth_et_id_nl,
    name: 'ET_NL',
    login_url: environment.login_urls.et_login_url_nl
  },
  {
    id: environment.client_ids.auth_et_id_pl,
    name: 'ET_PL',
    login_url: environment.login_urls.et_login_url_pl
  },
  {
    id: environment.client_ids.auth_et_id_ro,
    name: 'ET_RO',
    login_url: environment.login_urls.et_login_url_ro
  },
  {
    id: environment.client_ids.auth_et_id_at,
    name: 'ET_AT',
    login_url: environment.login_urls.et_login_url_at
  },
  {
    id: environment.client_ids.auth_et_id_dk,
    name: 'ET_DK',
    login_url: environment.login_urls.et_login_url_dk
  },
  {
    id: environment.client_ids.auth_et_id_ch,
    name: 'ET_CH',
    login_url: environment.login_urls.et_login_url_ch
  },
  {
    id: environment.client_ids.auth_et_id_sk,
    name: 'ET_SK',
    login_url: environment.login_urls.et_login_url_sk
  },
  {
    id: environment.client_ids.auth_et_id_cz,
    name: 'ET_CZ',
    login_url: environment.login_urls.et_login_url_cz
  },
  {
    id: environment.client_ids.auth_et_id_lt,
    name: 'ET_LT',
    login_url: environment.login_urls.et_login_url_lt
  },
  {
    id: environment.client_ids.auth_et_id_ee,
    name: 'ET_EE',
    login_url: environment.login_urls.et_login_url_ee
  },
  {
    id: environment.client_ids.auth_et_id_lv,
    name: 'ET_LV',
    login_url: environment.login_urls.et_login_url_lv
  },
  {
    id: environment.client_ids.auth_et_id_ar,
    name: 'ET_AR',
    login_url: environment.login_urls.et_login_url_ar
  },
  {
    id: environment.client_ids.auth_et_id_au,
    name: 'ET_AU',
    login_url: environment.login_urls.et_login_url_au
  },
  {
    id: environment.client_ids.auth_et_id_nz,
    name: 'ET_NZ',
    login_url: environment.login_urls.et_login_url_nz
  },
  {
    id: environment.client_ids.auth_iwc_client_id,
    name: 'IWC',
    login_url: environment.login_urls.cc_login_url
  },
  {
    id: '2KCyHX0MSXPwGhZdnL5kLKylrRrnzdFq',
    name: 'DEMO Partner App',
    login_url: 'https://app.local:4200'
  },
  {
    id: environment.client_ids.claas_connect_farm,
    name: 'CLAAS_connect_farm',
    login_url: environment.base_urls.claas_connect_farm
  },
  {
    id: environment.client_ids.claas_connect_farm_app,
    name: 'CLAAS_connect_farm_app',
    login_url: environment.base_urls.claas_connect_farm_app
  },
];
