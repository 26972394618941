/**
 * This is where we compose all of our *shared* child reducers and selectors.
 * These will all be made available via the Root State object and available to all features at bootstrap.
 *
 * Note: Individual feature state is managed at the feature module level. See `app/features/ngrx-example` for an example.
 */

import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector
} from '@ngrx/store';
import { UserEffects, UserState } from './user';

/**
 * Every reducer module's default export is the reducer function itself. In
 * addition, each module should export a type or interface that describes
 * the state of the reducer plus any selector functions. The `* as`
 * notation packages up all of the exports into a single object.
 */
import * as GlobalReducer from './global/global.reducer';

import * as UserReducer from './user/user.reducers';

import * as UserCoordinatesReducer from './user-coordinates/user-coordinates.reducers';

import * as OrganisationsReducer from './organisations/organisations.reducers';

import * as DealersReducer from './dealers/dealers.reducer';

import { GlobalEffects } from './global/global.effects';
import { GlobalState } from './global/global.state';
import { UserCoordinatesState } from './user-coordinates/user-coordinates.state';
import { UserCoordinatesEffects } from './user-coordinates/user-coordinates.effects';
import { UserOrganisationsState } from './organisations/organisations.state';
import { OrganisationsEffects } from './organisations/organisations.effects';
import { OrganisationDealersState } from './dealers/dealers.state';
import { DealersEffects } from './dealers/dealers.effects';

export type StateError = Error | string | null;

/**
 * As mentioned, we treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */
export interface State {
  global: GlobalState;
  user: UserState;
  userCoordinates: UserCoordinatesState;
  organisations: UserOrganisationsState;
  dealers: OrganisationDealersState;
}

/**
 * Our state is composed of a map of action reducer functions.
 * These reducer functions are called with each dispatched action
 * and the current or initial state and return a new immutable state.
 *
 * Registered in the `core` ngModule
 */
export const reducers: ActionReducerMap<State> = {
  global: GlobalReducer.reducer,
  user: UserReducer.reducer,
  userCoordinates: UserCoordinatesReducer.reducer,
  organisations: OrganisationsReducer.reducer,
  dealers: DealersReducer.reducer
};

// META-REDUCERS //

// console.log all actions
export function logger(reducer: ActionReducer<State>) {
  return (state: State, action: any) => {
    // tslint:disable-line:no-any
    // console.debug('previous state', state);
    // console.debug('action', action);

    return reducer(state, action);
  };
}

export function rehydrateState(reducer: ActionReducer<State>) {
  return (state: State, action: any) => {
    // tslint:disable-line:no-any
    if (action.type === 'REHYDRATE_STATE') {
      return action.payload;
    }

    return reducer(state, action);
  };
}

/**
 * Re-export all of our shared Effects.
 *
 * Registered in the `core` ngModule
 */
export const effects = [
  GlobalEffects,
  UserEffects,
  UserCoordinatesEffects,
  OrganisationsEffects,
  DealersEffects
];

/**
 * ROOT SELECTORS
 */

export const globalFeatureStateSelector =
  createFeatureSelector<GlobalState>('global');
