import { Component, OnDestroy, OnInit } from '@angular/core';
import { ButtonClass, ButtonSize } from '@claas/claas-form-components';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../../services/api.service';
import { finalize, Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  CircularProgressDiameter,
  ProgressColor
} from '@claas/claas-layout-components';

@Component({
  selector: 'app-email-view',
  templateUrl: './email-view.component.html',
  styleUrl: './email-view.component.scss'
})
export class EmailViewComponent implements OnInit, OnDestroy {
  subscriptions = new Subscription();
  primaryButtonClass = ButtonClass.PRIMARY;
  mediumButtonSize = ButtonSize.DEFAULT_MEDIUM;
  secondaryColor = ProgressColor.SECONDARY;
  largeSize = CircularProgressDiameter.LARGE;

  user_id: boolean | undefined;
  client_id: boolean | undefined;
  email_pending = true;
  email_missing = false;
  state: boolean | undefined;
  domain: boolean | undefined;
  successText = '';
  errorText = '';

  isLoading = false;
  constructor(
    private route: ActivatedRoute,
    private translate: TranslateService,
    private apiService: ApiService,
    private snackBar: MatSnackBar
  ) {
    this.successText = this.translate.instant('mail.success_text');
    this.errorText = this.translate.instant('mail.error_text');
  }

  ngOnInit() {
    this.route.queryParams.subscribe((res: any) => {
      this.state = res.state;
      this.domain = res.auth0_domain;
      this.email_pending = res.email_pending ? res.email_pending : true;
      this.user_id = res.user_id ? res.user_id.replace('_', '|') : undefined;
      this.client_id = res.client_id ? res.client_id : undefined;
      this.email_missing = res.email_missing;
      this.email_pending = res.email_missing ? false : this.email_pending;
    });
  }

  resentConfirmationEmail(): void {
    this.isLoading = true;

    const payload = {
      user_id: this.user_id,
      client_id: this.client_id
    };

    this.subscriptions.add(
      this.apiService
        .sendConfirmationEmail(payload)
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe(
          () => {
            this.snackBar.open(this.successText, '', {
              duration: 5000,
              panelClass: 'success'
            });
          },
          () => {
            this.snackBar.open(this.errorText, '', {
              duration: 5000,
              panelClass: 'error'
            });
          }
        )
    );
  }

  routeToFacebook(): void {
    window.open('https://www.facebook.com/settings?tab=applications', '_blank');
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
