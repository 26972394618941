import { Pipe, PipeTransform } from '@angular/core';
import { UserOrgaAddress } from '../../models/user-orga-address.model';
import { countriesArr } from '../../components/language-view/countries-source';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, switchMap } from 'rxjs';
import { ApiService } from '../../services/api.service';
import { FieldDetails } from '../../components/address-view/address-view.component';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'address'
})
export class AddressPipe implements PipeTransform {
  withName = false;
  countriesArray = countriesArr;
  existingAddressesFormatsObject: any = {};
  currentAddressFormatObject: FieldDetails = {};

  constructor(
    private translateService: TranslateService,
    private apiService: ApiService
  ) {}

  transform(
    value: UserOrgaAddress | UserOrgaAddress[],
    type?: 'INVOICE' | 'DELIVERY',
    withName?: boolean
  ): Observable<string> {
    this.withName = !!withName;
    if (Array.isArray(value)) {
      value = value.filter(el => el.type === type)[0];
    }

    return this.getDisplayedAddress(value);
  }

  getAddressesFormats(country: string): Observable<any> {
    return this.getSelectedAddressFormat$(country).pipe(
      map(singleFormat => {
        const multipleFormFields: any = {};
        multipleFormFields[country] = singleFormat.allAddressDataRows.filter(
          (row: any[]) => row.length
        );
        this.existingAddressesFormatsObject = multipleFormFields;
      })
    );
  }

  setCurrentAddressFormatObject(currentCountry: string): void {
    const currentAddressFormatArray = this.existingAddressesFormatsObject[
      currentCountry
    ].flatMap((subArray: any) => [...subArray]);
    this.currentAddressFormatObject = {};
    currentAddressFormatArray.map(
      (details: any) => (this.currentAddressFormatObject[details.id] = details)
    );
  }

  getSelectedAddressFormat$(country: string): Observable<any> {
    return this.apiService.getFormFieldsForCountry(country);
  }

  getDisplayedAddress(value: UserOrgaAddress): Observable<string> {
    return this.getAddressesFormats(value.country).pipe(
      switchMap(() => {
        this.setCurrentAddressFormatObject(value.country);
        let displayedAddress = '';
        if (this.currentAddressFormatObject['street'] && value.street) {
          displayedAddress = displayedAddress.concat(value.street);
        }
        if (
          this.currentAddressFormatObject['houseNumber'] &&
          value.houseNumber
        ) {
          displayedAddress = displayedAddress.concat(' ', value.houseNumber);
        }
        if (
          this.currentAddressFormatObject['houseNumber'] &&
          value.house_number
        ) {
          displayedAddress = displayedAddress.concat(' ', value.house_number);
        }
        displayedAddress = displayedAddress.concat('\n');
        if (
          this.currentAddressFormatObject['addressAddition'] &&
          value.addressAddition
        ) {
          displayedAddress = displayedAddress.concat(value.addressAddition);
          displayedAddress = displayedAddress.concat('\n');
        }
        if (this.currentAddressFormatObject['postalCode'] && value.postalCode) {
          displayedAddress = displayedAddress.concat(' ', value.postalCode);
        }
        if (
          this.currentAddressFormatObject['postalCode'] &&
          value.postal_code
        ) {
          displayedAddress = displayedAddress.concat(' ', value.postal_code);
        }
        if (this.currentAddressFormatObject['city'] && value.city) {
          displayedAddress = displayedAddress.concat(' ', value.city, ' ');
        }
        displayedAddress = displayedAddress.concat('\n');
        if (this.currentAddressFormatObject['suburb'] && value.suburb) {
          displayedAddress = displayedAddress.concat(' ', value.suburb, ' ');
          displayedAddress = displayedAddress.concat('\n');
        }
        if (this.currentAddressFormatObject['territory'] && value.territory) {
          displayedAddress = displayedAddress.concat(
            ' ',
            this.getTerritoryTranslation(value.territory, value.country),
            ' '
          );
          displayedAddress = displayedAddress.concat('\n');
        }
        if (this.currentAddressFormatObject['state'] && value.state) {
          displayedAddress = displayedAddress.concat(
            ' ',
            this.getStateTranslation(value.state, value.country),
            ' '
          );
          displayedAddress = displayedAddress.concat('\n');
        }
        if (value.country) {
          displayedAddress = displayedAddress.concat(
            ' ',
            this.getCountryTranslation(value.country)
          );
        }
        return of(displayedAddress);
      })
    );
  }

  private getCountry(country: string) {
    const c = this.countriesArray.find(function (element) {
      return element.id === country;
    });
    return c === undefined ? '' : c.label;
  }

  private getCountryTranslation(country: string): string {
    let countryTranslation: string = '';
    this.translateService
      .get(this.getCountry(country))
      .subscribe((text: string) => {
        countryTranslation = text.toString();
      });
    return countryTranslation;
  }

  private getStateTranslation(state: string, country: string): string {
    let stateTranslation: string = '';
    this.translateService
      .get(
        'registration.formfield.select.states.' +
          country.toLowerCase() +
          '.' +
          state.toLowerCase()
      )
      .subscribe((text: string) => {
        stateTranslation = text.toString();
      });
    return stateTranslation;
  }

  private getTerritoryTranslation(territory: string, country: string): string {
    let territoryTranslation: string = '';
    this.translateService
      .get(
        'registration.formfield.select.territories.' +
          country.toLowerCase() +
          '.' +
          territory.toLowerCase()
      )
      .subscribe((text: string) => {
        territoryTranslation = text.toString();
      });
    return territoryTranslation;
  }
}
