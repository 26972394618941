<div class="name-view">
  <div class="name-view__header">
    <div class="name-view__header-main">
      <h2 class="name-view__header-title subtitle-2">
        {{ 'personal_data.name.edit_header' | translate }}
      </h2>
      <claas-circular-progress
        *ngIf="isSaving"
        class="spinner"
        [color]="color"
        [diameter]="size"
      ></claas-circular-progress>
    </div>
  </div>

  <div class="name-view__body">
    <div class="name-view__body-content">
      <div class="name-view__body-content--line exception">
        <claas-dropdown-key-value
          class="half-width"
          [label]="'registration.formfield.salutation' | translate"
          [options]="optionsSalutation"
          [ngModel]="data.nameDetails && data.nameDetails.salutation"
          (onChange)="updatedSalutation($event)"></claas-dropdown-key-value>

        <claas-dropdown-key-value
          class="half-width"
          [label]="'registration.formfield.title' | translate"
          [options]="optionsTitle"
          [ngModel]="data.nameDetails && data.nameDetails.title"
          (onChange)="updatedTitle($event)"></claas-dropdown-key-value>
      </div>
      <div class="name-view__body-content--line">
        <claas-text-field
          class="full-width"
          [label]="('registration.formfield.givenName' | translate) + '*'"
          [error]="
            !!(invalidStateGivenName$ | async) && fieldsAreTouched
              ? ('registration.validationMsgs.required' | translate)
              : ''
          "
          [ngModel]="data.nameDetails && data.nameDetails.first_name"
          (input)="updatedGivenName($event)">
        </claas-text-field>
      </div>
      <div class="name-view__body-content--line">
        <claas-text-field
          class="full-width"
          [label]="('registration.formfield.familyName' | translate) + '*'"
          [error]="
            !!(invalidStateFamilyName$ | async) && fieldsAreTouched
              ? ('registration.validationMsgs.required' | translate)
              : ''
          "
          [ngModel]="data.nameDetails && data.nameDetails.last_name"
          (input)="updatedFamilyName($event)">
        </claas-text-field>
      </div>
    </div>
  </div>

  <div class="name-view__dialog-actions">
    <div class="left-side"></div>
    <div class="right-side">
      <claas-button
        *ngIf="data && data.buttonCancel"
        class="btn-left"
        [buttonClass]="secondaryTextButtonClass"
        [text]="'generic.cancel' | translate"
        [disabled]="!!(inProgress$ | async)"
        (onClick)="onCancelClicked()">
      </claas-button>

      <claas-button
        *ngIf="data && data.buttonSave"
        class="btn-right save-btn"
        [text]="'generic.save' | translate"
        [disabled]="!!(invalidState$ | async) || !!(inProgress$ | async)"
        (onClick)="onSaveClicked()">
      </claas-button>
    </div>
  </div>
</div>
