import { environment } from '../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { ApiService } from '../shared/services/api.service';
import { ICouplingConsentTexts } from '../shared/models/couplings';
import { ButtonClass, ButtonSize, DropdownOptionObject } from '@claas/claas-form-components';
import { CircularProgressDiameter, ProgressColor } from '@claas/claas-layout-components';
import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: './org-selection.component.html',
  styleUrls: ['./org-selection.component.scss']
})
export class OrgSelectionComponent implements OnInit {

  constructor(private route: ActivatedRoute,
    private translate: TranslateService,
    private apiService: ApiService) { }

  organizations = [];
  organizationOptions = [
    {
      "text": "label",
      "key": "key"
    }
  ];
  public selectedOrganization: any;
  params = new URLSearchParams();
  login_domain = environment.auth.auth_domain;
  orgSelection = true;
  showConsent = false;
  consentText: ICouplingConsentTexts | undefined;
  consentGiven = false;
  clientId: string | undefined;
  scope = '';
  showSpinner = true;
  largeSize = CircularProgressDiameter.LARGE;
  color = ProgressColor.PRIMARY;
  primaryButtonClass = ButtonClass.PRIMARY;
  secondaryButtonClass = ButtonClass.SECONDARY_OUTLINE;
  buttonSize = ButtonSize.LARGE;

  ngOnInit() {
    this.route.queryParams.subscribe((res) => {
      const token = res['session_token'];
      const decoded:any = jwt_decode(token);
      const language = decoded['language'];
      this.translate.use(language);
      const country = decoded['country'];
      this.scope = decoded['query']['scope'];
      
      if(decoded['query']['orgSelection']) {
        this.organizations = decoded['organizations']['organizations'];
        this.organizationOptions = this.setOrgOptions();
        this.selectedOrganization = this.organizations[0];
        const queryParameters = decoded['query'];
        delete queryParameters['orgSelection'];

        if(queryParameters['client_id'] && queryParameters['coupling']) {
          this.clientId = queryParameters['client_id'];
          this.showConsent = true;
        }

        for(let key in queryParameters){
          this.params.set(key, queryParameters[key]); 
        }
      } else {
        this.orgSelection = false;
        const queryParameters = decoded['query'];
        this.selectedOrganization = decoded['organization'];
        for(let key in queryParameters){
          this.params.set(key, queryParameters[key]); 
        }
        this.params.set('organization', this.selectedOrganization);
        window.open(`https://${this.login_domain}/authorize?${this.params.toString()}`, '_self');
      }
      if(this.showConsent) {
        console.log(this.clientId, this.scope);
        this.getConsentText(language, this.scope, country);
        
      }
    });
  }

  setOrgOptions() {
    var options: any[] = [];
    for(var ot of this.organizations) {
      var o = {"text": ot["name"],"key": ot["auth0Id"]};
      options.push(o);
    }
    return options;
  }

  getDefaultOrgOption() {
    return {"text": this.selectedOrganization["name"],"key": this.selectedOrganization["auth0Id"]};
  }

  selectOrg(o: DropdownOptionObject): void {
    this.selectedOrganization = this.organizations.find(org => org["auth0Id"] == o as unknown as string);
    console.log(this.selectedOrganization);
  }

  sendOrg(consent: boolean) {
    this.params.set('organization', this.selectedOrganization['auth0Id']);
    // Set this parameter to get the org also in the current namespace in access token
    this.params.set('organizationId', this.selectedOrganization['uuid']);
    this.params.set('consentGiven', !!consent ? "true" : "false");
    this.params.set('scope', this.scope);
    console.log(this.params.get("consentGiven"));
    window.open(`https://${this.login_domain}/authorize?${this.params.toString()}`, '_self');
  }

  getConsentText(language: string, scope: string, country: string) {
    this.apiService.getCouplingConsentText(language, scope, country).subscribe(
      (res) => {
          this.consentText = res;
          // replace all {{country}} placeholders by the actual country
          const regex = /{{\w+}}/g
          this.consentText.texts = this.consentText.texts.map(item => {
            item.value = item.value.replace(regex, country);
            return item;
          });
          this.showSpinner = false;
      },
      (err) => {
          const errorString = JSON.stringify(err);
          console.log(err);
      }
  );
  }

  getConsentTextKey(key: string) {
    return this.consentText?.texts.find(t => t.key == key)?.value
  }

  cancel() {
    window.open(environment.login_urls.cc_login_url, '_self')
  }

}
