<div class="organisation-data">
  <div>
    <section class="organisation-data__header">
      <div class="organisation-data__header-left">
        <div class="organisation-data__header-left--previous-page">
          <claas-icon-button
            class="edit-btn"
            [icon]="previousIcon"
            [iconSize]="mediumIconSize"
            [fontSize]="mediumIconFontSize"
            (click)="goPreviousPage()"></claas-icon-button>
        </div>

        <h1 class="organisation-data__header-left--title">
          {{ currentOrganisation?.name }}
        </h1>
      </div>

      <div class="organisation-data__header-right">
        <span *ngIf="isDefaultOrganisation$ | async" class="default-label">{{
          'organization.current_default' | translate
        }}</span>

        <mat-icon
          *ngIf="isDefaultOrganisation$ | async"
          fontIcon="star"></mat-icon>

        <claas-icon-button
          *ngIf="(isDefaultOrganisation$ | async) === false"
          class="star-btn"
          [icon]="starIcon"
          [iconSize]="mediumIconSize"
          [fontSize]="mediumIconFontSize"
          (click)="setAsDefaultOrganisation()"></claas-icon-button>
      </div>
    </section>

    <section *ngIf="(isLoadingOrganisationDetails$ | async) === false">
      <div class="organisation-data__content">
        <claas-tabs
          [tabs]="tabs"
          [selectedIndex]="
            (currentIndex$ | async) ? (currentIndex$ | async) : 0
          "></claas-tabs>

        <ng-template #overview>
          <app-organisation-data-overview
            *ngIf="organisationData$ | async as organisation"
            [organisation]="organisation"
            [orgaId]="orgaId"
            [isAdmin]="!!(isAdmin$ | async)"
            (goPreviousPage$)="goPreviousPage()"
            (loadOrganisationDetails$)="
              reloadOrganisationDetails(0)
            "></app-organisation-data-overview>
        </ng-template>

        <ng-template #usersManagement>
          <app-organisation-data-users
            [isAdmin]="!!(isAdmin$ | async)"
            [isUniqueAdmin]="!!(isUniqueAdmin$ | async)"
            [myUserId]="myUserId$ | async"
            [organisationUsers]="organisationMembers$ | async"
            [roleOptions]="roleOptions$ | async"
            [requestInProgress]="requestInProgress$ | async"
            (selectNewRoleOutput)="updateMemberRole($event)"
            (leaveOrganisationOutput)="leaveOrganisation($event)"
            (RemoveUserFromOrganisationOutput)="
              deleteOrganisationInvitation($event?.target, $event?.targetIsMember)
            "
            (inviteUserToOrganisationOutput)="
              inviteUserToOrganisation($event)
            "></app-organisation-data-users>
        </ng-template>

        <ng-template #dealersManagement>
          <app-organisation-data-dealers
            *ngIf="organisationData$ | async as organisation"
            [organisation]="organisation"
            [isAdmin]="!!(isAdmin$ | async)"
            [currentUser]="currentUser$ | async"
            [organisationShops]="organisationShops$ | async"
            [organisationConnectedDealers]="
              organisationConnectedDealers$ | async
            "
            [registrationSteps]="registrationSteps$ | async"
            (disconnectDealers)="
              disconnectDealers($event)
            "
            (setNewOrganisationDealer)="setNewOrganisationDealer($event)"></app-organisation-data-dealers>
        </ng-template>
      </div>
    </section>

    <div *ngIf="isLoadingOrganisationDetails$ | async">
      <span class="organisation-data__loader">
        <claas-circular-progress
          [color]="secondaryColor"
          [diameter]="largeSize"></claas-circular-progress>
      </span>
    </div>
  </div>
</div>
