import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {
  ResponseDataFromDialog,
  passedDataToDialog
} from '../dialog-window/dialog-window.component';
import {
  ButtonClass,
  ButtonSize,
  DropdownOptionObject,
  Icon,
  IconFontSize,
  IconSize
} from '@claas/claas-form-components';
import { BehaviorSubject, Subscription } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { errorStatusObject } from '../address-view/address-view.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-organisation-users-view',
  templateUrl: './organisation-users-view.component.html',
  styleUrls: ['./organisation-users-view.component.scss']
})
export class OrganisationUsersViewComponent implements OnInit, OnDestroy {
  @Input() data: passedDataToDialog = {};
  @Input() errorButtonClass?: ButtonClass;
  @Input() primaryTextButtonClass?: ButtonClass;
  @Input() secondaryTextButtonClass?: ButtonClass;

  @Output() displayPopup = new EventEmitter();
  @Output() closeDialogWindow = new EventEmitter();

  private subscriptions = new Subscription();
  infoIcon: Icon = {
    iconId: '001110',
    iconStyle: 'bold',
    namespace: 'claas-id'
  };
  addIcon: Icon = {
    iconId: '000973',
    iconStyle: 'bold',
    namespace: 'claas-id',
    size: 19
  };

  primaryButtonClass = ButtonClass.PRIMARY;
  mediumButtonSize = ButtonSize.DEFAULT_MEDIUM;
  mediumIconSize = IconSize.MEDIUM;
  mediumIconFontSize = IconFontSize.MEDIUM;
  ignoreLastChanges = false;
  currentEmail = '';
  targetEmails: string[] = [];
  myForm: FormGroup = new FormGroup<any>({});
  confirmationDialogIsActive$ = new BehaviorSubject(false);
  errorsStatusObject$: errorStatusObject = {};
  invalidSaveButtonState$ = new BehaviorSubject(true);

  constructor(public translateService: TranslateService) {}

  ngOnInit() {
    this.myForm = this.setForm();
    this.trackForm(this.myForm);

    if (this.data.initialEmail) {
      this.targetEmails.push(this.data.initialEmail);
    }
  }

  setForm(): FormGroup {
    return new FormGroup({
      email: new FormControl(this.currentEmail, [
        Validators.required,
        Validators.email
      ])
    });
  }

  trackForm(myForm: FormGroup): void {
    // @ts-ignore
    this.errorsStatusObject$['email'] = this.myForm
      .get('email')
      .valueChanges.pipe(
        map(value => {
          if (this.ignoreLastChanges) {
            return (this.ignoreLastChanges = false);
          } else {
            return this.myForm?.get('email')?.status === 'INVALID'
              ? 'registration.validationMsgs.required'
              : undefined;
          }
        })
      );

    this.subscriptions.add(
      myForm.valueChanges.subscribe(() => {
        // update the save button status
        this.invalidSaveButtonState$.next(!!this.myForm?.invalid);
      })
    );
  }

  addEmailToTheList(): void {
    if (!this.myForm.invalid) {
      this.targetEmails.push(this.myForm?.get('email')?.value);
      this.ignoreLastChanges = true;
      this.myForm.get('email')?.setValue('');
    }
  }

  removeEmailFromTheList(i: number): void {
    this.targetEmails = this.targetEmails.filter(
      email => email !== this.targetEmails[i]
    );
  }

  updateProfileRole(newValue: DropdownOptionObject): void {
    this.data.profileRole = newValue as unknown as string;
  }

  getRoleOption(role: string) {
    return { text: this.getRole(role), key: role };
  }

  getRoleOptions(roles: any[]) {
    var options: any[] = roles;
    return options;
  }

  getRole(r: string) {
    var label = '';
    this.translateService
      .get('organization.roles.' + r.toLowerCase())
      .subscribe((data: string) => (label = data));
    return label;
  }

  showConfirmationDialog(): void {
    return this.data.isUniqueAdmin
      ? this.actionIsCanceled()
      : this.confirmationDialogIsActive$.next(true);
  }

  actionIsCanceled(): void {
    const passedObject = {
      action: 'cancel'
    };

    this.closeDialogWindow.emit(passedObject);
  }

  actionIsConfirmed(): void {
    const passedObject: ResponseDataFromDialog = {
      action: this.data.subContext ? this.data.subContext : ''
    };

    if (this.data.subContext === 'organisation-invitation') {
      passedObject.targetEmails = this.targetEmails;
      passedObject.role = this.data.profileRole;
    }

    this.confirmationDialogIsActive$.next(false);
    this.closeDialogWindow.emit(passedObject);
  }

  showRoleAndRightsInfo(): void {
    window.location.href = '';
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
