import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  Renderer2
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import {
  allAuthActions,
  selectIsLoggedIn,
  selectUserAvatarUrl,
  selectUserDetails
} from './shared/state/user';
import { allGlobalActions } from './shared/state/global';
import { ApiService } from './shared/services/api.service';
import { DOCUMENT } from '@angular/common';
import { AuthService as Auth0Service } from '@auth0/auth0-angular';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { BehaviorSubject, distinctUntilChanged, tap } from 'rxjs';
import { NavigationItem } from '@claas/claas-navigation-components';
import {
  ButtonClass,
  ButtonSize,
  Icon,
  IconFontSize,
  IconSize
} from '@claas/claas-form-components';
import { AuthService } from './shared/services/auth.service';
import { DividerOrientation } from '@claas/claas-layout-components';
import {
  loadMarketingPermissions,
  loadUserCoordinates
} from './shared/state/user-coordinates/user-coordinates.actions';
import { NavigationEnd, Router } from '@angular/router';
import { loadOrganisationRegistrationSteps } from './shared/state/organisations';
import {
  selectMarketingPermissions,
  selectUserCoordinates
} from './shared/state/user-coordinates/user-coordinates.selectors';
import { filter } from 'rxjs/operators';
import { UserAddress } from './shared/models/user-address.model';
import * as UserActions from './shared/state/user/user.actions';

export const FALLBACK_LANGUAGE = 'en-gb';
export let isHandset = false;
export let isTablet = false;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  private outsideClickListener: any;

  marketingPermissionsLink = {
    label: 'navigation.preference_center',
    route: '/preference-center',
    activeRoutes: ['/preference-center'],
    iconId: '013435',
    iconNamespace: 'claas-id'
  };

  navigationItems: NavigationItem[] = [];

  bottomMenuItems: NavigationItem[] = [
    {
      label: 'footer.help',
      action: this.printStuff,
      iconId: '006200',
      iconNamespace: 'claas-id',
      size: 24
    },
    {
      label: 'footer.notification',
      action: this.printStuff,
      iconId: '648468',
      iconNamespace: 'claas-id'
    },
    {
      label: 'footer.applications',
      action: this.printStuff,
      iconId: '001450',
      iconNamespace: 'claas-id'
    }
  ];

  // translate strings
  logout = 'Logout';

  // Variables
  smallButtonSize = ButtonSize.SMALL;
  smallIconSize = IconSize.SMALL;
  smallIconFontSize = IconFontSize.SMALL;
  secondaryOutlineButtonClass = ButtonClass.SECONDARY_OUTLINE;
  verticalDivider = DividerOrientation.VERTICAL;

  // User Profile Variables
  salutationValue = '';
  language = '';
  country = '';
  profileJson: any;
  avatarUrl = '/assets/images/_Avatar_.png';

  loggedIn = false;
  menuExpanded = true;
  avatarMenu = false;

  isFirstTime = true;
  isLoggedIn = false;

  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );

  menuIconOpen: Icon = {
    iconId: '648425',
    iconStyle: 'bold',
    namespace: 'claas-id',
    size: 24
  };

  menuIconClosed: Icon = {
    iconId: '648420',
    iconStyle: 'bold',
    namespace: 'claas-id',
    size: 24
  };

  helpIcon: Icon = {
    iconId: '006200',
    iconStyle: 'bold',
    namespace: 'claas-id',
    size: 24
  };

  notificationIcon: Icon = {
    iconId: '648468',
    iconStyle: 'bold',
    namespace: 'claas-id',
    size: 24
  };

  logoutIcon: Icon = {
    iconId: '659471',
    iconStyle: 'bold',
    namespace: 'claas-id'
  };

  get isHandset() {
    return isHandset;
  }

  get isTablet() {
    return isTablet;
  }

  salutation$ = new BehaviorSubject('');
  title$ = new BehaviorSubject('');
  givenName$ = new BehaviorSubject('');
  familyName$ = new BehaviorSubject('');
  email$ = new BehaviorSubject('');

  constructor(
    private translate: TranslateService,
    private store: Store,
    private apiService: ApiService,
    public auth0: Auth0Service,
    public authService: AuthService,
    @Inject(DOCUMENT) public document: Document,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {
    this.store.dispatch(allAuthActions.checkLogin());
    this.navigationItems = this.getBasicNavigationItems();
    this.store.select(selectIsLoggedIn).subscribe(isLoggedIn => {
      this.isLoggedIn = isLoggedIn;

      if (isLoggedIn) {
        this.store.dispatch(loadUserCoordinates());
        this.store.dispatch(loadOrganisationRegistrationSteps());
      }
    });
  }

  ngOnInit() {
    // get browser language
    let browser_Lang = navigator.language;
    const browser_Country = browser_Lang.substring(3, 5);
    browser_Lang = browser_Lang.substring(0, 2);
    if (browser_Lang === 'en') {
      if (browser_Country.toLowerCase() === 'us') {
        browser_Lang = 'en-us';
      } else {
        browser_Lang = 'en-gb';
      }
    }

    this.store
      .select(selectUserCoordinates)
      .pipe(filter((user: any) => !!user))
      .subscribe((user: UserAddress) =>
        this.store.dispatch(
          loadMarketingPermissions({
            claasId: user.user_id ? user.user_id : ''
          })
        )
      );

    this.store.select(selectMarketingPermissions).subscribe(permissions => {
      if (permissions && permissions.length) {
        this.navigationItems = this.getBasicNavigationItems();
        this.navigationItems.push(this.marketingPermissionsLink);
      } else {
        this.navigationItems = this.navigationItems.filter(
          item => item.route !== '/preference-center'
        );
      }

      if (environment.general.languages.indexOf(browser_Lang) >= 0) {
        this.i18nInit(browser_Lang);
      } else {
        this.i18nInit(FALLBACK_LANGUAGE);
      }
    });

    this.store.dispatch(allGlobalActions.rehydrateState());

    this.breakpoint$.subscribe(() => this.breakpointChanged());

    this.store.select(selectIsLoggedIn).subscribe(status => {
      this.loggedIn = status;

      if (status) {
        this.store.dispatch(UserActions.allAuthActions.loadUserAvatarUrl());
      }
    });

    this.store
      .select(selectUserDetails)
      .pipe(filter(res => !!res && !!res?.user_metadata))
      .subscribe((res: any) => {
        if (res.user_metadata['given_name']) {
          this.givenName$.next(res.user_metadata['given_name']);
        }

        if (res.user_metadata['family_name']) {
          this.familyName$.next(res.user_metadata['family_name']);
        }

        if (res.user_metadata['salutation']) {
          this.salutationValue = res.user_metadata['salutation'];
          this.salutation$.next(res.user_metadata['salutation']);
        }

        if (res.user_metadata['title']) {
          this.title$.next(res.user_metadata['title']);
        }

        if (res.user_metadata['language']) {
          this.language = res.user_metadata['language'].toLowerCase();
          this.changeLanguage(this.language);
        }

        if (res.user_metadata['country']) {
          this.country = res.user_metadata['country'].toLowerCase();
        }

        if (res['email']) {
          this.email$.next(res['email']);
        }
      });

    this.store.select(selectUserAvatarUrl).subscribe(url => {
      if (url) {
        this.avatarUrl = url;
      }
    });

    this.trackRouting();
  }

  getBasicNavigationItems(): NavigationItem[] {
    return [
      {
        label: 'navigation.personal_data',
        route: '/personal-data',
        activeRoutes: ['/personal-data'],
        iconId: '001608',
        iconNamespace: 'claas-id',
        size: 24
      },
      {
        label: 'navigation.account_settings',
        route: '/user-settings',
        activeRoutes: ['/user-settings'],
        iconId: '648466',
        iconNamespace: 'claas-id'
      },
      {
        label: 'navigation.organization_settings',
        route: '/organisations',
        activeRoutes: ['/organisations'],
        iconId: '018412',
        iconNamespace: 'claas-id'
      },
      {
        label: 'navigation.connected_apps',
        route: '/applications',
        activeRoutes: ['/applications'],
        iconId: '017745',
        iconNamespace: 'claas-id'
      }
    ];
  }

  private trackRouting(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd && (this.isHandset || this.isTablet)) {
        this.menuExpanded = false;
      }
    });
  }

  private i18nInit(lang: string) {
    this.translate.setDefaultLang(FALLBACK_LANGUAGE);
    this.store
      .select(selectUserCoordinates)
      .pipe(filter((user: any) => !!user))
      .subscribe((res: any) => {
        lang = res.language;
      }
      );
    this.translate.use(lang);
    this.triggerTranslations();
  }

  private triggerTranslations() {
    this.translate.get('navigation.personal_data').subscribe(data => {
      this.navigationItems[0].label = data;
    });
    this.translate.get('navigation.account_settings').subscribe(data => {
      this.navigationItems[1].label = data;
    });
    this.translate.get('navigation.organization_settings').subscribe(data => {
      this.navigationItems[2].label = data;
    });
    this.translate.get('navigation.connected_apps').subscribe(data => {
      this.navigationItems[3].label = data;
    });

    if (this.navigationItems[4]) {
      this.translate.get('navigation.preference_center').subscribe(data => {
        this.navigationItems[4].label = data;
      });
    }

    this.translate.get('generic.logout').subscribe(data => {
      this.logout = data;
    });
    this.translate.get('footer.help').subscribe(data => {
      this.bottomMenuItems.at(0)!.label = data;
    });
    this.translate.get('footer.notification').subscribe(data => {
      this.bottomMenuItems.at(1)!.label = data;
    });
    this.translate.get('footer.applications').subscribe(data => {
      this.bottomMenuItems.at(2)!.label = data;
    });
  }

  private breakpointChanged() {
    if (
      this.breakpointObserver.isMatched(
        '(max-width: 599.98px) and (orientation: portrait), (max-width: 959.98px) and (orientation: landscape)'
      )
    ) {
      console.log('handset');
      isHandset = true;
      isTablet = false;
    } else if (
      this.breakpointObserver.isMatched(
        '(min-width: 600px) and (max-width: 1023.98px) and (orientation: portrait), ' +
          '(min-width: 960px) and (max-width: 1279.98px) and (orientation: landscape)'
      )
    ) {
      console.log('tablet');
      isHandset = false;
      isTablet = true;
      this.menuExpanded = false;
    } else if (
      this.breakpointObserver.isMatched(
        '(min-width: 1024px) and (orientation: portrait), (min-width: 1280px) and (orientation: landscape)'
      )
    ) {
      console.log('web');
      isHandset = false;
      isTablet = false;
      this.menuExpanded = this.isFirstTime;
      this.isFirstTime = false;
    }
  }

  logoutUser(): void {
    this.auth0
      .logout({ logoutParams: { returnTo: document.location.origin } })
      .subscribe(result => {
        this.store.dispatch(allAuthActions.logoutComplete());
      });
  }

  changeLanguage(lang: string) {
    if (environment.general.languages.includes(lang.toLowerCase())) {
      this.translate.use(lang);
    } else {
      this.translate.use(FALLBACK_LANGUAGE);
    }

    this.triggerTranslations();
  }

  toggleMenu() {
    this.menuExpanded = !this.menuExpanded;
  }

  toggleAvatarMenu() {
    this.avatarMenu = !this.avatarMenu;

    if (this.avatarMenu) {
      this.startListeningForOutsideClicks();
    } else {
      this.stopListeningForOutsideClicks();
    }
  }

  startListeningForOutsideClicks(): void {
    this.outsideClickListener = this.renderer.listen(
      'document',
      'click',
      (event: MouseEvent) => {
        const clickedElement = event.target as HTMLElement;
        const classNames: string = clickedElement.className;

        const clickedInside = classNames.includes('avatar-menu-element');

        if (!clickedInside) {
          this.avatarMenu = false;
          this.stopListeningForOutsideClicks();
        }
      }
    );
  }

  stopListeningForOutsideClicks(): void {
    if (this.outsideClickListener) {
      this.avatarMenu = false;
      this.outsideClickListener();
      this.outsideClickListener = null;
    }
  }

  printStuff() {
    console.log('Stuff');
  }

  redirectToInfo(): void {
    if (environment.info_url) {
      window.open(environment.info_url+"page/de/web/id/claas-id", '_blank');
    }
  }
}
