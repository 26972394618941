import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';

import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { AuthService } from '@auth0/auth0-angular';
import { Observable } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
  constructor(private auth: AuthService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      (req.url.includes(environment.base_urls.api_base_url) ||
        req.url.includes(environment.base_urls.user_api_base_url) ||
        req.url.includes(environment.base_urls.cc_3_api_base_url)) &&
      !req.url.startsWith(
        'https://' + environment.auth.auth_domain + '/continue?state='
      )
    ) {
      return this.auth.getAccessTokenSilently().pipe(
        mergeMap(token => {
          const tokenReq = req.clone({
            setHeaders: { Authorization: `Bearer ${token}` }
          });
          return next.handle(tokenReq);
        }),
        catchError(e => {
          console.log(e);
          req = req.clone({ setHeaders: {} });
          return next.handle(req);
        })
      );
    } else {
      req = req.clone({ setHeaders: {} });
      return next.handle(req);
    }
  }
}
