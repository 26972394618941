import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, forkJoin, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  CountryDetails,
  Option,
  OptionObs
} from '../address-view/address-view.component';
import { environment } from '../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { applicationsArr } from '../../../applications/applications-source';
import { ApiService } from '../../services/api.service';
import { Icon, IconSize } from '@claas/claas-form-components';

@Component({
  selector: 'app-complete-view',
  templateUrl: './complete-view.component.html',
  styleUrl: './complete-view.component.scss'
})
export class CompleteViewComponent implements OnInit, OnDestroy {
  subscriptions = new Subscription();

  mediumIconSize = IconSize.MEDIUM;
  showPasswordIcon: Icon = {
    iconId: '001003',
    iconStyle: 'bold',
    namespace: 'claas-id',
    size: 24
  };

  hidePasswordIcon: Icon = {
    iconId: '648582',
    iconStyle: 'bold',
    namespace: 'claas-id',
    size: 24
  };

  tempCompleteDetails: any = {
    given_name: undefined,
    family_name: undefined,
    country: undefined,
    password_new: undefined,
    password_new_confirm: undefined
  };

  fieldsAreTouched = {
    given_name: false,
    family_name: false,
    country: false,
    password_new: false,
    password_new_confirm: false
  };

  profileFormGroup: FormGroup | undefined;
  passwordNewFormGroup: FormGroup;
  consentsFormGroup: FormGroup | undefined;
  termsAccepted = false;
  termsClaasIdAccepted = false;
  marketingCountry: any = '';
  marketingFootnotes: string = '';
  userId = '';
  userCountry = '';
  clients: any;
  claasIdConsentIsIncluded = false;
  currentClientID: string | undefined;
  currentClientName = '';

  cid_id = environment.auth.auth_client_id;
  countryDetails: CountryDetails[] = environment.general.countriesDetails;
  countryOptions: Option[] = [];
  state: any;
  domain: any;
  applicationConsent = false;

  familyNameReq = false;
  givenNameReq = false;
  countryReq = false;
  emailReq = false;

  newPasswordReq = false;

  connectionReq = false;
  languageReq = false;

  reconsentReq = false;

  newsletterReq = false;

  selectedCountry = '';
  invitationUuid = '';
  queryString = '';
  userLang = 'en-gb';
  stepsArray: string[] = [];
  showPasswordFields: any = {
    firstField: false,
    secondField: false
  };
  passwordConditions = {
    first: false,
    second: false,
    third: false
  };
  specificPermissionsGranted;

  // Profile fields Obs
  invalidStateGivenName$ = new BehaviorSubject(false);
  invalidStateFamilyName$ = new BehaviorSubject(false);
  invalidStateCountry$ = new BehaviorSubject(false);
  invalidProfileStepState$ = new BehaviorSubject(true);

  // password fields Obs
  invalidStatePasswordNew$ = new BehaviorSubject(this.passwordConditions);
  invalidStatePasswordNewConfirm$ = new BehaviorSubject(false);
  invalidPasswordStepState$ = new BehaviorSubject(true);
  showPassword$ = new BehaviorSubject(this.showPasswordFields);

  // consent fields Obs
  termsState$ = new BehaviorSubject(true);

  // marketing permissions fields Obs
  marketingPermissionGranted$ = new BehaviorSubject(false);
  marketingSpecificPermissionGranted$ = new BehaviorSubject(
    this.resetSpecificPermissionsGranted()
  );

  lastStep$ = new BehaviorSubject('');

  constructor(
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private apiService: ApiService,
    public route: ActivatedRoute,
    public router: Router
  ) {
    this.specificPermissionsGranted = this.resetSpecificPermissionsGranted();
    this.countryOptions = this.setCountryOptions();
    this.getOptionsTranslation(this.countryOptions);

    this.passwordNewFormGroup = this.setPasswordNewForm();
    this.defineStepperRequirements();
  }

  ngOnInit() {
    // get browser Settings
    const browserSettings: string[] = this.getBrowserSet();
    // set language and country
    this.setCountryAndLanguage(browserSettings);
  }

  // COPIED FROM OLD FE
  // returns an array with browser language [0] and country [1]
  getBrowserSet() {
    const browserSetString = window.navigator.language;
    const browserSet = ['', '']; // array for language and country
    if (browserSetString.indexOf('-') !== -1) {
      browserSet[0] = browserSetString.split('-')[0].toLowerCase(); // language
      browserSet[1] = browserSetString.split('-')[1].toLowerCase(); // country
    }
    if (browserSetString.indexOf('_') !== -1) {
      browserSet[0] = browserSetString.split('_')[0].toLowerCase();
      browserSet[1] = browserSetString.split('_')[1].toLowerCase();
    }
    if (browserSetString.length <= 2) {
      browserSet[0] = browserSetString;
      browserSet[1] = browserSetString;
    }
    return browserSet;
  }

  // COPIED FROM OLD FE
  setCountryAndLanguage(browserSet: string[]) {
    // set language
    this.userLang = browserSet[0];
    // en Mapping
    if (this.userLang === 'en') {
      if (['en', 'gb', 'uk'].indexOf(browserSet[1]) >= 0) {
        this.userLang = 'en-gb';
      } else if (['ca', 'us'].indexOf(browserSet[1]) >= 0) {
        this.userLang = 'en-us';
      } else {
        this.userLang = 'en-gb';
      }
    }
    // Fallback, if language is not supportet
    if (!environment.general.languages.includes(this.userLang)) {
      this.userLang = 'en-gb';
    }
    // set country
    if (environment.general.countries.indexOf(browserSet[1]) >= 0) {
      this.selectedCountry = browserSet[1];
      // UK Mapping
      if (['en', 'gb', 'uk'].indexOf(browserSet[1]) >= 0) {
        this.selectedCountry = 'gb';
      }
    } else {
      this.selectedCountry = 'de';
    }
  }

  setCountryOptions(): Option[] {
    return this.countryDetails.map(detail => {
      return { key: detail.id, text: detail.label };
    });
  }

  getOptionsTranslation(options: Option[]): void {
    const obsArray: OptionObs = {};

    options.forEach(
      (option: Option) =>
        (obsArray[option.key] = this.translateService.get(option.text))
    );

    this.subscriptions.add(
      forkJoin(obsArray).subscribe(translation => {
        for (const key in translation) {
          const target: Option | undefined = options.find(
            (element: Option) => element.key === key
          );

          if (target) {
            target.text = translation[key];
          }
        }
      })
    );
  }

  setProfileForm(): FormGroup {
    const formControls: any = {};

    if (this.givenNameReq) {
      formControls['givenNameCtrl'] = ['', Validators.required];
    }

    if (this.givenNameReq) {
      formControls['familyNameCtrl'] = ['', Validators.required];
    }

    if (this.givenNameReq) {
      formControls['countryCtrl'] = ['', Validators.required];
    }

    return this.formBuilder.group(formControls);
  }

  setPasswordNewForm(): FormGroup {
    return this.formBuilder.group({
      passwordNewCtrl: ['', Validators.required],
      passwordNewConfirmCtrl: ['', Validators.required]
    });
  }

  setConsentsFormGroup(claasIdConsentIsIncluded?: boolean): FormGroup {
    const formFields: any = {
      applicationsConsent: [false, Validators.requiredTrue]
    };

    if (claasIdConsentIsIncluded) {
      formFields['claasIdConsent'] = [false, Validators.requiredTrue];
    }

    return this.formBuilder.group(formFields);
  }

  defineStepperRequirements(): void {
    this.route.queryParams.subscribe(params => {
      this.domain = params['auth0_domain'];
      this.state = params['state'];
      this.invitationUuid = params['invitationUuid'];
      this.marketingCountry = params['marketing_country'];
      this.marketingFootnotes =
        // @ts-ignore
        environment.marketing_footnotes[this.marketingCountry];

      this.userCountry = params['marketing_country'];
      this.userId = params['user_id']?.replace('_', '|')
        ? params['user_id']?.replace('_', '|')
        : this.userId;

      // for profile Step
      this.givenNameReq = params['given_name'] === 'true';
      this.familyNameReq = params['family_name'] === 'true';
      this.countryReq = params['country'] === 'true';
      this.profileFormGroup = this.setProfileForm();

      this.emailReq = params['email'] === 'true';

      // for Password Step
      this.newPasswordReq = params['new_password'] === 'true';

      // for connection Step
      this.connectionReq = params['connection'] === 'true';
      this.languageReq = params['language'] === 'true';

      // for the consent Step
      if (params['client']) {
        this.reconsentReq = params['reconsent'] === 'true';
        this.clients = params['client'].split('_');
        this.claasIdConsentIsIncluded = this.clients.includes(this.cid_id);
        this.consentsFormGroup = this.setConsentsFormGroup(
          this.claasIdConsentIsIncluded
        );

        // find the current client id and set variables for html content (Copier from Old FE)
        this.clients.forEach((clientId: string) => {
          if (clientId !== this.cid_id) {
            const target = applicationsArr.find(
              app => app.id === clientId
            )?.name;

            this.currentClientName = target ? target : this.currentClientName;
            this.currentClientName = this.currentClientName.startsWith('ET_')
              ? 'ET'
              : this.currentClientName;

            this.currentClientID = clientId;
            this.applicationConsent = true;
          }
        });
      }

      // for newsletter Step
      this.newsletterReq = params['newsletter'] === 'true';

      // Set the stepsArray based on the passed Url parameters
      this.stepsArray = this.setCompletionSteps();

      // Get all query_ parameters to pass them to the continue endpoint
      // Ignore the state parameter as this will be controlled by Auth0
      this.getContinueQueryValue(params);
    });
  }

  setCompletionSteps(): string[] {
    const stepsArray: string[] = [];

    if (this.givenNameReq || this.familyNameReq || this.countryReq) {
      stepsArray.push('profile');
      this.lastStep$.next('profile');
    }

    if (this.newPasswordReq) {
      stepsArray.push('password');
      this.lastStep$.next('password');
    }

    if (this.connectionReq) {
      stepsArray.push('linking');
      this.lastStep$.next('linking');
    }

    if (this.clients?.length === 0 || this.clients?.length) {
      stepsArray.push('consent');
      this.lastStep$.next('consent');
    }

    if (this.newsletterReq) {
      stepsArray.push('newsletter');
      this.lastStep$.next('newsletter');
    }

    return stepsArray;
  }

  getContinueQueryValue(params: any): void {
    const queryParams = Object.getOwnPropertyNames(params);

    for (let param of queryParams) {
      if (param.startsWith('query_') && param != 'query_state') {
        this.queryString +=
          '&' + param.split('query_')[1] + '=' + params[param];
      }
    }
  }

  // Profile Step fields
  updatedGivenName(newValue: any): void {
    this.fieldsAreTouched.given_name = true;
    this.tempCompleteDetails.given_name = newValue.target.value.trim();
    this.updateProfileStepStatus();
  }

  updatedFamilyName(newValue: any): void {
    this.fieldsAreTouched.family_name = true;
    this.tempCompleteDetails.family_name = newValue.target.value.trim();
    this.updateProfileStepStatus();
  }

  updatedCountry(selection: any): void {
    this.fieldsAreTouched.country = true;
    this.tempCompleteDetails.country = selection.trim();
    this.updateProfileStepStatus();
  }

  updateProfileStepStatus(): void {
    const given_name_invalid =
      !this.tempCompleteDetails.given_name ||
      /^\s*$/.test(this.tempCompleteDetails.given_name);
    this.invalidStateGivenName$.next(given_name_invalid);

    const family_name_invalid =
      !this.tempCompleteDetails.family_name ||
      /^\s*$/.test(this.tempCompleteDetails.family_name);
    this.invalidStateFamilyName$.next(family_name_invalid);

    const country_invalid =
      !this.tempCompleteDetails.country ||
      /^\s*$/.test(this.tempCompleteDetails.country);
    this.invalidStateCountry$.next(country_invalid);

    this.invalidProfileStepState$.next(
      given_name_invalid || family_name_invalid || country_invalid
    );
  }

  // Password Step fields
  updatedPasswordNew(newValue: any): void {
    this.fieldsAreTouched.password_new = true;
    this.tempCompleteDetails.password_new = newValue.target.value;

    this.passwordConditions.first = /^\S{10,}$/.test(
      this.tempCompleteDetails.password_new
    );
    this.passwordConditions.second = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/.test(
      this.tempCompleteDetails.password_new
    );
    this.passwordConditions.third = /^(?=.*[\W_]).+$/.test(
      this.tempCompleteDetails.password_new
    );

    this.invalidStatePasswordNew$.next(this.passwordConditions);
    this.updatePasswordStepStatus();
  }

  updatedPasswordNewConfirm(newValue: any): void {
    this.fieldsAreTouched.password_new_confirm = true;
    this.tempCompleteDetails.password_new_confirm = newValue.target.value;

    this.invalidStatePasswordNewConfirm$.next(
      !this.tempCompleteDetails.password_new_confirm ||
        this.tempCompleteDetails.password_new_confirm !==
          this.tempCompleteDetails.password_new
    );

    this.updatePasswordStepStatus();
  }

  updatePasswordStepStatus(): void {
    this.invalidPasswordStepState$.next(
      !this.passwordConditions.first ||
        !this.passwordConditions.second ||
        !this.passwordConditions.third ||
        this.tempCompleteDetails.password_new !==
          this.tempCompleteDetails.password_new_confirm
    );
  }

  // Consent Step fields
  updateTermsState(checkboxValue: any, isClaasIdConsent?: boolean): void {
    this.termsClaasIdAccepted = isClaasIdConsent
      ? !this.termsClaasIdAccepted
      : this.termsClaasIdAccepted;
    this.termsAccepted = !isClaasIdConsent
      ? !this.termsAccepted
      : this.termsAccepted;

    const newState = this.claasIdConsentIsIncluded
      ? !this.termsAccepted || !this.termsClaasIdAccepted
      : !this.termsAccepted;

    this.termsState$.next(newState);
  }

  showHidePassword(key: string): void {
    this.showPasswordFields[key] = !this.showPasswordFields[key];
    this.showPassword$.next(this.showPasswordFields);
  }

  updateMarketingPermission(
    checkboxValue: any,
    specificPermission?: string
  ): void {
    if (specificPermission) {
      // @ts-ignore
      this.specificPermissionsGranted[specificPermission] = checkboxValue;

      // update marketingPermissionGranted$ if all permissions are revoked
      if (
        !this.specificPermissionsGranted['post'] &&
        !this.specificPermissionsGranted['phone'] &&
        !this.specificPermissionsGranted['email'] &&
        !this.specificPermissionsGranted['push']
      ) {
        this.marketingPermissionGranted$.next(false);
      }
    } else {
      this.marketingPermissionGranted$.next(checkboxValue);
      // update all other permissions
      this.specificPermissionsGranted =
        this.resetSpecificPermissionsGranted(checkboxValue);
    }

    this.marketingSpecificPermissionGranted$.next(
      this.specificPermissionsGranted
    );
  }

  resetSpecificPermissionsGranted(mainValue?: boolean): any {
    return {
      post: !!mainValue,
      phone: !!mainValue,
      email: !!mainValue,
      push: !!mainValue
    };
  }

  submitStepper(): void {
    const formData: any = {};
    const continueUrl =
      'https://' +
      environment.auth.auth_domain +
      '/continue?state=' +
      this.state +
      this.queryString;

    if (this.stepsArray.find(stepName => stepName === 'profile')) {
      formData['given_name'] = this.tempCompleteDetails.given_name;
      formData['family_name'] = this.tempCompleteDetails.family_name;
      formData['country'] = this.tempCompleteDetails.country;
    }

    if (this.stepsArray.find(stepName => stepName === 'password')) {
      formData['password_new'] = this.tempCompleteDetails.password_new;
    }

    if (this.stepsArray.find(stepName => stepName === 'consent')) {
      if (this.applicationConsent) {
        formData['confirm'] = 'yes';
      }

      if (this.clients.includes(this.cid_id)) {
        formData['newsletter'] = 'yes';
      }
    }

    if (this.stepsArray.find(stepName => stepName === 'newsletter')) {
      formData['marketingFeedback'] = true;
      formData['invitationUuid'] = this.invitationUuid;
      formData['mpGeneralMail'] = 'on';

      if (this.specificPermissionsGranted.post) {
        formData['mpPost'] = 'on';
      }

      if (this.specificPermissionsGranted.phone) {
        formData['mpPhone'] = 'on';
      }

      if (this.specificPermissionsGranted.email) {
        formData['mpEmail'] = 'on';
      }

      if (this.specificPermissionsGranted.push) {
        formData['mpPush'] = 'on';
      }
    }

    if (this.languageReq) {
      formData['language'] = this.userLang;
    }

    this.apiService
      .postFormDataRequest(continueUrl, formData)
      .subscribe(response => console.log(response));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
