import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { environment } from '../../../environments/environment';
import { IChangePasswordModel } from '../models/change-password.model';
import { PhoneNumber } from '../models/phone-number.model';
import { Observable } from 'rxjs';
import { UserBasicInformation } from '../../personal-data/personal-data.component';
import { UserAddress } from '../models/user-address.model';
import { OrgPhoneNumber } from '../models/org-phone-number.model';
import { DefaultOrganisation } from '../../organisation-data/organisation-data.component';
import { InvitationCreateModel } from '../models/invitation-create.model';
import { Consent } from '../models/consent.model';
import { Application } from '../models/application.model';
import { MarketingPermission } from '../models/marketing-permission.model';
import { DealerResponse } from '../../preference-center/preference-center.component';
import { MarketingPermissionPatch } from '../models/marketing-permission-patch.model';
import { OrganizationInvitation } from '../models/organisation-invitation.model';
import {
  IAddressAutocompleteRequest,
  IAddressAutocompleteResponse,
  IAddressDetailsRequest,
  IAddressDetailsResponse,
  IDealerDataRegistrationResponse,
  INearestDealersResponse,
  IPersonalDataRegistrationRequest,
  IPersonalDataRegistrationResponse,
  IRegisterOrganisationRequestWithMoreParams,
  IRegisterOrganisationResponse,
  IRegistrationPersonalData
} from 'cde-fe-organization-registration-dialog/lib/models/organisation.model';
import { IDealerByNameRequest, IMarketingPermission } from 'cde-fe-organization-registration-dialog';
import {
  IDealerData,
  IDealerFilterResponse
} from 'cde-fe-organization-registration-dialog/lib/models/dealer.model';
import { UserUpdateRequest } from '../models/user-update-request.model';
import { ICouplingConsentTexts } from '../models/couplings';
import { MarketingUpdatePayload } from '../models/marketing-update-payload.model';

@Injectable()
export class ApiService {
  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(
    private http: HttpClient,
    public auth: AuthService
  ) {}

  private organizationRegistrationServiceUrl = environment.base_urls.registration_service_base_url + "/v3/registrations";
  private organizationRegistrationUrl = environment.base_urls.registration_service_base_url + "/v4/registrations";

  postFormDataRequest(continueUrl: string, payload: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };

    return this.http.post(continueUrl, payload, httpOptions);
  }

  sendConfirmationEmail(payload: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.post(
      environment.base_urls.user_api_base_url + '/v3/users/send_email',
      payload,
      httpOptions
    );
  }

  getUserProfile() {
    return this.http.get(
      environment.base_urls.user_api_base_url + '/v3/user',
      this.httpOptions
    );
  }

  setUserProfile(payload: UserBasicInformation) {
    return this.http.patch(
      environment.base_urls.user_api_base_url + '/v3/user',
      payload,
      this.httpOptions
    );
  }

  setProfilePicture(formData: any, token: any) {
    const httpHeaders = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + token
      })
    };

    return this.http.post(
      environment.base_urls.user_api_base_url + '/v3/profile-picture',
      formData,
      httpHeaders
    );
  }

  deleteProfilePicture(token: any) {
    const httpHeaders = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + token
      })
    };

    return this.http.delete(
      environment.base_urls.user_api_base_url + '/v3/profile-picture',
      httpHeaders
    );
  }

  // Change Password
  changePassword(payload: IChangePasswordModel) {
    return this.http.post(
      environment.base_urls.user_api_base_url + '/v3/password',
      payload,
      this.httpOptions
    );
  }

  // Address
  getAddressAutoComplete(input: string, language: string, countrySet: string) {
    return this.http.get(
      environment.base_urls.cc_3_api_base_url +
        '/v2/map-services/address-search?query=' +
        input +
        '&language=' +
        language +
        '&countrySet=' +
        countrySet,
      this.httpOptions
    );
  }

  updateAddress(payload: UserAddress): Observable<object> {
    return this.http.patch(
      environment.base_urls.user_api_base_url + '/v3/address',
      payload,
      this.httpOptions
    );
  }

  addAddress(payload: UserAddress): Observable<object> {
    return this.http.post(
      environment.base_urls.user_api_base_url + '/v3/address',
      payload,
      this.httpOptions
    );
  }

  getAddressDetails(
    id: string,
    language: string,
    country: string
  ): Observable<any> {
    return this.http.get(
      environment.base_urls.cc_3_api_base_url +
        '/v2/map-services/address-details?id=' +
        id +
        '&language=' +
        language +
        '&countrySet=' +
        country,
      this.httpOptions
    );
  }

  deleteAddress(addressUUID: string) {
    return this.http.delete(
      environment.base_urls.user_api_base_url + '/v3/address/' + addressUUID,
      this.httpOptions
    );
  }

  getFormFieldsForCountry(country: string) {
    return this.http.get(
      this.organizationRegistrationServiceUrl +
        '/form-fields/' +
        country,
      this.httpOptions
    );
  }

  getAddressAutocomplete(
    addressAutocompleteRequest: IAddressAutocompleteRequest
  ): Observable<IAddressAutocompleteResponse[]> {
    return this.http.get<IAddressAutocompleteResponse[]>(
      environment.base_urls.cc_3_api_base_url +
        '/v2/map-services' +
        '/address-search',
      {
        params: { ...addressAutocompleteRequest }
      }
    );
  }

  // Phone Number
  listUserPhoneNumbers(): Observable<object> {
    return this.http.get(
      environment.base_urls.user_api_base_url + '/v3/phonenumber',
      this.httpOptions
    );
  }

  createUserPhoneNumber(payload: PhoneNumber) {
    return this.http.post(
      environment.base_urls.user_api_base_url + '/v3/phonenumber',
      payload,
      this.httpOptions
    );
  }

  updateUserPhoneNumber(payload: PhoneNumber): Observable<object> {
    return this.http.patch(
      environment.base_urls.user_api_base_url + '/v3/phonenumber',
      payload,
      this.httpOptions
    );
  }

  deleteUserPhoneNumber(type: string): Observable<object> {
    return this.http.delete(
      environment.base_urls.user_api_base_url + '/v3/phonenumber/' + type,
      this.httpOptions
    );
  }

  // MFA
  getUsersMfa() {
    return this.http.get(
      environment.base_urls.user_api_base_url + '/v3/mfa',
      this.httpOptions
    );
  }

  getMfaEnrollmentTicket() {
    return this.http.post(
      environment.base_urls.user_api_base_url + '/v3/mfa',
      this.httpOptions
    );
  }

  deleteUsersMfa() {
    return this.http.delete(
      environment.base_urls.user_api_base_url + '/v3/mfa',
      this.httpOptions
    );
  }

  // Delete User
  deleteAccount(payload: { email: string; password: string }, token: string) {
    const httpHeaders = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + token
      }),
      body: payload
    };
    return this.http.delete(
      environment.base_urls.user_api_base_url + '/v3/user',
      httpHeaders
    );
  }

  deleteSocialAccount(payload: { email: string }, token: string) {
    const httpHeaders = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + token
      }),
      body: payload
    };
    return this.http.delete(
      environment.base_urls.user_api_base_url + '/v3/social-user',
      httpHeaders
    );
  }

  // Organisation
  getOrganisations(userId: string) {
    userId = encodeURIComponent(userId as string);

    return this.http.get(
      environment.base_urls.api_base_url +
        '/v3/organizations/idp-users/' +
        userId +
        '/organizations',
      {}
    );
  }

  getOrganisation(orgId: string) {
    return this.http.get(
      environment.base_urls.api_base_url + '/v3/organizations/' + orgId,
      {}
    );
  }

  getOrganisationMembers(orgId: string) {
    return this.http.get(
      environment.base_urls.api_base_url +
        '/v3/organizations/' +
        orgId +
        '/members',
      {}
    );
  }

  updateOrganisationMember(
    orgId: string,
    userId: string,
    payload: any,
    token?: string
  ) {
    userId = encodeURIComponent(userId as string);

    return this.http.patch(
      environment.base_urls.api_base_url +
        '/v3/organizations/' +
        orgId +
        '/members/' +
        payload?.idpUser,
      payload,
      {}
    );
  }

  deleteOrganisationMember(orgId: string, userId: string, token?: string) {
    userId = encodeURIComponent(userId as string);

    return this.http.delete(
      environment.base_urls.api_base_url +
        '/v3/organizations/' +
        orgId +
        '/members/' +
        userId,
      {}
    );
  }

  deleteOrganisation(orgId: string, token?: string) {
    return this.http.delete(
      environment.base_urls.api_base_url + '/v3/organizations/' + orgId,
      {}
    );
  }

  updateOrganisation(orgId: string, payload: any, token?: string) {
    return this.http.patch(
      environment.base_urls.api_base_url + '/v3/organizations/' + orgId,
      payload,
      {}
    );
  }

  getRolesInOrganisation() {
    return this.http.get(
      environment.base_urls.api_base_url + '/v4/organizations/roles',
      {}
    );
  }

  updateOrganisationPhoneNumber(
    orgId: string,
    payload: OrgPhoneNumber,
    token?: string
  ) {
    return this.http.patch(
      environment.base_urls.api_base_url +
        '/v3/organizations/' +
        orgId +
        '/phonenumber',
      payload,
      {}
    );
  }

  createOrganisationPhoneNumber(
    orgId: string,
    payload: OrgPhoneNumber,
    token?: string
  ) {
    return this.http.post(
      environment.base_urls.api_base_url +
        '/v3/organizations/' +
        orgId +
        '/phonenumber',
      payload,
      {}
    );
  }

  updateOrganisationAddress(
    orgId: string,
    addressId: string,
    payload: any,
    token?: string
  ) {
    return this.http.patch(
      environment.base_urls.api_base_url +
        '/v3/organizations/' +
        orgId +
        '/addresses/' +
        addressId,
      payload,
      {}
    );
  }

  getDefaultOrganisation(userId: string, token?: string) {
    return this.http.get(
      environment.base_urls.api_base_url +
        '/v3/organizations/idp-users/' +
        userId +
        '/default-organization',
      {}
    );
  }

  setDefaultOrganisation(
    payload: DefaultOrganisation,
    userId: string,
    token?: string
  ) {
    return this.http.put(
      environment.base_urls.api_base_url +
        '/v3/organizations/idp-users/' +
        userId +
        '/default-organization',
      payload,
      {}
    );
  }

  createInvitation(
    orgaId: string,
    payload: InvitationCreateModel,
    token?: string
  ) {
    return this.http.post(
      environment.base_urls.api_base_url +
        '/v3/organizations/' +
        orgaId +
        '/invitation',
      payload,
      {}
    );
  }

  getOrganisationInvitations(orgId: string, token?: any) {
    const httpHeaders = {
      headers: new HttpHeaders({
        Authorization: 'Bearer',
        'Content-Type': 'application/json'
      })
    };

    return this.http.get(
      environment.base_urls.api_base_url +
        '/v3/organizations/' +
        orgId +
        '/invitations',
      httpHeaders
    );
  }

  deleteOrganisationInvitation(
    orgId: string,
    invitationId: string,
    token?: any
  ) {
    const httpHeaders = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      })
    };

    return this.http.delete(
      environment.base_urls.api_base_url +
        '/v3/organizations/' +
        orgId +
        '/invitations/' +
        invitationId,
      httpHeaders
    );
  }

  getDealer(
    dealerId: string,
    language: string,
    token?: string
  ): Observable<IDealerData> {
    dealerId = dealerId.replace(/^0+/, '');
    const httpHeaders = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      }),
      options: {
        language
      }
    };

    return this.http.get<IDealerData>(
      environment.base_urls.cc_3_api_base_url + '/v2/dealers/' + dealerId,
      httpHeaders
    );
  }

  getDealerDetails(orgId: string, dealerId: string, token?: any) {
    const httpHeaders = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      })
    };

    return this.http.get(
      environment.base_urls.api_base_url +
        '/v3/organizations/' +
        orgId +
        '/dealers/' +
        dealerId,
      httpHeaders
    );
  }

  updateOrganisationDealer(orgId: string, payload: any, token?: string) {
    const httpHeaders = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      })
    };

    return this.http.patch(
      environment.base_urls.api_base_url +
        '/v3/organizations/' +
        orgId +
        '/dealers',
      payload,
      httpHeaders
    );
  }

  getOrganisationShops(orgId: string, token?: string) {
    const httpHeaders = {
      headers: new HttpHeaders({
        Authorization: 'Bearer',
        'Content-Type': 'application/json'
      })
    };

    return this.http.get(
      environment.base_urls.api_base_url +
        '/v3/organizations/' +
        orgId +
        '/shops',
      httpHeaders
    );
  }

  getOrganisationConnectedDealers(orgId: string, token?: string) {
    const httpHeaders = {
      headers: new HttpHeaders({
        Authorization: 'Bearer',
        'Content-Type': 'application/json'
      })
    };

    return this.http.get(
      environment.base_urls.api_base_url +
        '/v3/organizations/' +
        orgId +
        '/connected-dealers',
      httpHeaders
    );
  }

  disconnectDealer(orgId: string, dealerId: string, token?: string) {
    const httpHeaders = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      })
    };

    return this.http.delete(
      environment.base_urls.api_base_url +
        '/v3/organizations/' +
        orgId +
        '/connected-dealers/' +
        dealerId,
      httpHeaders
    );
  }

  getRegistrationAddressDetails(
    request: IAddressDetailsRequest
  ): Observable<any> {
    const params = {
      ...request
    };

    return this.http.get(
      environment.base_urls.cc_3_api_base_url +
        '/v2/map-services' +
        '/address-details',
      { params }
    ) as Observable<IAddressDetailsResponse>;
  }

  //  registration

  getRegistrationSteps(token?: string): Observable<any> {
    return this.http.get<any[]>(
      environment.base_urls.api_base_url + '/v3/registrations/step',
      { params: { context: 'REGISTRATION' } }
    );
  }

  getRegistrationDefaultOrganisation(country: string): Observable<any> {
    return this.http.get<any>(
      this.organizationRegistrationServiceUrl + '/organization',
      { params: { country } }
    );
  }

  getRegistrationPersonalData(
    country: string,
    language: string
  ): Observable<IRegistrationPersonalData> {
    return this.http.get<any>(
      this.organizationRegistrationServiceUrl + '/personal-data',
      { params: { country, language } }
    );
  }

  updateRegistrationPersonalData(
    registerRequest: IPersonalDataRegistrationRequest,
    language: string
  ): Observable<IPersonalDataRegistrationResponse> {
    return this.http.patch<any>(
      this.organizationRegistrationServiceUrl + '/personal-data',
      registerRequest,
      { params: { language } }
    );
  }

  getRegistrationDealerData(
    language: string
  ): Observable<IDealerDataRegistrationResponse> {
    return this.http.get<any>(
      this.organizationRegistrationServiceUrl + '/dealer',
      { params: { language } }
    );
  }

  getRegistrationNearestDealers(
    request: any
  ): Observable<INearestDealersResponse> {
    const COUNTRY = request.country.toUpperCase();
    const { filter, country, type, ...filteredRequest } = request;
    const params = {
      ...filteredRequest,
      country: COUNTRY
    };

    return this.http.get<any>(
      this.organizationRegistrationServiceUrl + '/dealers',
      { params }
    );
  }

  getRegistrationShopData(
    country: string,
    organisationID: string
  ): Observable<any> {
    return this.http.get<any>(
      this.organizationRegistrationServiceUrl + '/shop',
      { params: { country, organizationId: organisationID } }
    );
  }

  getRegistrationDealerByName(
    request: IDealerByNameRequest
  ): Observable<INearestDealersResponse> {
    const params = this.createDictionaryFromRequest(request);
    return this.http.get<any>(
      this.organizationRegistrationServiceUrl + '/dealers/name',
      { params }
    );
  }

  getDealerFilters(): Observable<IDealerFilterResponse> {
    return this.http.get<any>(
      environment.base_urls.cc_3_api_base_url + '/v2/dealer-filters'
    );
  }

  registerOrganisation(
    registerRequest: IRegisterOrganisationRequestWithMoreParams
  ): Observable<IRegisterOrganisationResponse> {
    const { request, language, country } = registerRequest;
    const params = {
      params: { language, country }
    };

    return this.http.post<any>(
      this.organizationRegistrationUrl + '/organization',
      request,
      params
    );
  }

  // applications
  getApplications(): Observable<Application[]> {
    return this.http.get<Application[]>(
      environment.base_urls.user_api_base_url + '/v3/consent/applications',
      this.httpOptions
    );
  }

  getConsent(): Observable<Consent[]> {
    return this.http.get<Consent[]>(
      environment.base_urls.user_api_base_url + '/v3/consent',
      this.httpOptions
    );
  }

  grantConsent(client_id: string) {
    return this.http.post(
      environment.base_urls.user_api_base_url + '/v3/consent/' + client_id,
      this.httpOptions
    );
  }

  revokeConsent(client_id: string) {
    return this.http.delete(
      environment.base_urls.user_api_base_url + '/v3/consent/' + client_id,
      this.httpOptions
    );
  }

  // marketing permissions
  getMarketingPermissions(claasId: string): Observable<MarketingPermission[]> {
    claasId = encodeURIComponent(claasId as string);
    {
      return this.http.get<MarketingPermission[]>(
        environment.base_urls.mps_base_url + '/' + claasId + `/permission`,
        this.httpOptions
      );
    }
  }

  getMarketingPermissionsForRegistrationDialog(
    claasId: string
  ): Observable<IMarketingPermission[]> {
    claasId = encodeURIComponent(claasId as string);
    {
      return this.http.get<IMarketingPermission[]>(
        environment.base_urls.mps_base_url + '/' + claasId + `/permission`,
        this.httpOptions
      );
    }
  }

  updateMarketingPermission(
    claasId: string,
    permissionId: string,
    payload: MarketingPermissionPatch
  ) {
    claasId = encodeURIComponent(claasId as string);
    return this.http.patch(
      environment.base_urls.mps_base_url +
        '/' +
        claasId +
        `/permission/` +
        permissionId,
      payload,
      this.httpOptions
    );
  }

  deleteAllMarketingPermissions(claasId: string) {
    claasId = encodeURIComponent(claasId as string);

    return this.http.delete(
      environment.base_urls.mps_base_url + '/' + claasId,
      this.httpOptions
    );
  }

  getPreferredDealer(dealerId: string): Observable<DealerResponse> {
    return this.http.get<DealerResponse>(
      environment.base_urls.user_api_base_url + '/v3/debitor/' + dealerId,
      this.httpOptions
    );
  }

  getOrganisationName(orgId: string) {
    return this.http.get(
      environment.base_urls.api_base_url + '/v3/organizations/' + orgId,
      this.httpOptions
    );
  }

  createOrganisationMember(
    payload: { idpUser: string; role: string },
    orgId: string
  ) {
    return this.http.post(
      environment.base_urls.api_base_url +
        '/v3/organizations/' +
        orgId +
        '/members',
      payload,
      this.httpOptions
    );
  }

  // USER INVITATIONS
  // ADMIN APPROVES USER INVITATION
  setOrganisationAdminInvitationStatus(
    payload: OrganizationInvitation,
    orgId: string
  ) {
    return this.http.patch(
      environment.base_urls.api_base_url +
        '/v3/organizations/' +
        orgId +
        '/members/invitation-admin',
      payload,
      this.httpOptions
    );
  }

  getOrganisationAdminInvitation(orgId: string) {
    return this.http.get(
      environment.base_urls.api_base_url +
        '/v3/organizations/' +
        orgId +
        '/members/invitation-admin'
    );
  }

  // USER APPROVES INVITATION
  setOrganisationInvitationStatus(
    payload: OrganizationInvitation,
    userId: string
  ) {
    return this.http.patch(
      environment.base_urls.api_base_url +
        '/v3/organizations/members/' +
        userId +
        '/invitation',
      payload,
      this.httpOptions
    );
  }

  getOrganisationInvitation(userId: string) {
    return this.http.get(
      environment.base_urls.api_base_url +
        '/v3/organizations/members/' +
        userId +
        '/invitation'
    );
  }

  // USER UPDATE
  getUserUpdateRequest() {
    return this.http.get(
      environment.base_urls.user_api_base_url + '/v3/user/request-update'
    );
  }

  setUserUpdateRequestStatus(payload: UserUpdateRequest) {
    return this.http.patch(
      environment.base_urls.user_api_base_url + '/v3/user/request-update',
      payload,
      this.httpOptions
    );
  }

  saveNewAddress(payload: UserAddress) {
    return this.http.post(
      environment.base_urls.user_api_base_url + '/v3/address',
      payload,
      this.httpOptions
    );
  }

  // ORGANISATION UPDATE
  getOrganisationUpdateRequest(orgId: string) {
    return this.http.get(
      environment.base_urls.api_base_url +
        '/v3/organizations/' +
        orgId +
        '/request-update',
      this.httpOptions
    );
  }

  setOrganisationUpdateRequestStatus(orgId: string, payload: any) {
    return this.http.patch(
      environment.base_urls.api_base_url +
        '/v3/organizations/' +
        orgId +
        '/request-update',
      payload,
      this.httpOptions
    );
  }

  createOrganisationAddress(orgId: string, payload: any) {
    return this.http.post(
      environment.base_urls.api_base_url +
        '/v3/organizations/' +
        orgId +
        '/addresses',
      payload,
      this.httpOptions
    );
  }

  getPostalCodeRegex() {
    return this.http.get(
      environment.base_urls.user_api_base_url + '/v3/address/postal-code-regex',this.httpOptions
    );
  }
  getMarketingInvitationsById(claasId: string, invitationId?: string) {
    let invUrl: string = '/invitation';

    if (invitationId) {
      invUrl = `/invitation?uuids=${invitationId}`;
    }

    return this.http.get<MarketingPermission[]>(
      environment.base_urls.mps_base_url + '/' + claasId + invUrl,
      this.httpOptions
    );
  }

  getDealerName(dealerId: string) {
    return this.http.get(
      environment.base_urls.user_api_base_url + '/v3/debitor/' + dealerId,
      this.httpOptions
    );
  }

  getCouplingConsentText(
    language: string,
    scope: string,
    country: string
  ): Observable<ICouplingConsentTexts> {
    return this.http.get<ICouplingConsentTexts>(
      environment.base_urls.api_base_url +
        '/v4/coupling-api/consent?language=' +
        language +
        '&scope=' +
        scope +
        '&country=' +
        country,
      this.httpOptions
    );
  }


  validateAddress(payload: any) {
    return this.http.post(
      environment.base_urls.user_api_base_url + '/v3/validate-address',
      payload,
      this.httpOptions
    );
  }

  getFormFieldsForAddress(country: string) {
    return this.http.get(
      environment.base_urls.registration_service_base_url +
        '/form-fields/' +
        country,
      this.httpOptions
    );
  }

  acceptMarketingInvitation(
    userId: string,
    invitationId: string,
    payload: MarketingUpdatePayload
  ) {
    return this.http.patch(
      environment.base_urls.mps_base_url +
        '/' +
        userId +
        `/invitation/` +
        invitationId,
      payload,
      this.httpOptions
    );
  }

  // Note: copied from the old frontend s code
  private createDictionaryFromRequest<T extends {}>(request: T) {
    const params = Object.entries(request)
      // do not send null values
      .filter(dictEntry => !!dictEntry[1])
      .reduce(
        (obj, current) => {
          obj[current[0]] = current[1];
          return obj;
        },
        {} as { [key: string]: any }
      );

    return params;
  }
}
