<div class="complete-view">
  <div class="complete-view__body">
    <div class="complete-view__body-content">
      <mat-stepper linear #stepper>
        <!-- profile step -->
        <mat-step
          *ngIf="profileFormGroup && (givenNameReq || familyNameReq || countryReq)"
          [stepControl]="profileFormGroup"
        >
          <form [formGroup]="profileFormGroup">
            <ng-template matStepLabel>{{ 'generic.profile' | translate }}</ng-template>

            <div class="complete-view__body-content--header">
              <span class="header-3-bold">{{ 'complete.headline' | translate }}</span>
            </div>

            <div class="complete-view__body-content--message">
              <span>{{ 'complete.sub_headline' | translate }}</span>
            </div>

            <div class="complete-view__body-content--fields">
              <div class="complete-view__body-content--fields-line">
                <claas-text-field
                  class="full-width"
                  formControlName="givenNameCtrl"
                  [label]="('overview.given_name' | translate) + '*'"
                  [placeholder]="('overview.given_name' | translate) + '*'"
                  [error]="!!(invalidStateGivenName$ | async) && fieldsAreTouched.given_name
                    ? ('registration.validationMsgs.required' | translate)
                    : ''
                  "
                  (input)="updatedGivenName($event)"
                ></claas-text-field>
              </div>

              <div class="complete-view__body-content--fields-line">
                <claas-text-field
                  class="full-width"
                  formControlName="familyNameCtrl"
                  [label]="('overview.family_name' | translate) + '*'"
                  [placeholder]="('overview.family_name' | translate) + '*'"
                  [error]="!!(invalidStateFamilyName$ | async) && fieldsAreTouched.family_name
                    ? ('registration.validationMsgs.required' | translate)
                    : ''
                  "
                  (input)="updatedFamilyName($event)"
                ></claas-text-field>
              </div>

              <div class="complete-view__body-content--fields-line">
                <claas-dropdown-key-value
                  ngDefaultControl
                  class="full-width"
                  formControlName="countryCtrl"
                  [label]="('overview.country' | translate) + '*'"
                  [options]="countryOptions"
                  [error]="!!(invalidStateCountry$ | async) && fieldsAreTouched.country
                    ? ('registration.validationMsgs.required' | translate)
                    : ''
                  "
                  (onChange)="updatedCountry($event)"></claas-dropdown-key-value>
              </div>
            </div>

            <div class="complete-view__body-content--actions">
              <claas-button
                *ngIf="(lastStep$ | async) !== 'profile'; else submitButton"
                class="btn-right next-btn"
                [text]="'generic.next' | translate"
                [disabled]="!!(invalidProfileStepState$ | async)"
                (click)="stepper.next()">
              </claas-button>

              <ng-template #submitButton>
                <claas-button
                  class="btn-right submit-btn"
                  type="submit"
                  [text]="'complete.submit_button' | translate"
                  [disabled]="!!(invalidProfileStepState$ | async)"
                  (click)="submitStepper()">
                </claas-button>
              </ng-template>
            </div>
          </form>
        </mat-step>

        <!-- New Password step -->
        <mat-step
          *ngIf="newPasswordReq"
          [stepControl]="passwordNewFormGroup"
        >
          <form [formGroup]="passwordNewFormGroup">
            <ng-template matStepLabel>{{ 'overview.password' | translate }}</ng-template>

            <div class="complete-view__body-content--header">
              <span class="header-3-bold">{{ 'complete.headline' | translate }}</span>
            </div>

            <div class="complete-view__body-content--message">
              <p [innerHtml]="'password.new_pw_intro' | translate"></p>

              <ul class="password-requirements">
                <mat-hint> {{ 'password.passwordStrength.shouldContain' | translate }} </mat-hint>

                <li [ngClass]="{'fulfilled': (invalidStatePasswordNew$ | async)?.first}">
                  <mat-hint>
                    {{ 'password.passwordStrength.lengthAtLeast' | translate}}
                  </mat-hint>
                </li>

                <li [ngClass]="{'fulfilled': (invalidStatePasswordNew$ | async)?.second}">
                  <mat-hint>
                    {{ 'password.passwordStrength.lowerCase' | translate }},
                    {{ 'password.passwordStrength.upperCase' | translate }},
                    {{ 'password.passwordStrength.numbers' | translate }}
                  </mat-hint>
                </li>

                <li [ngClass]="{'fulfilled': (invalidStatePasswordNew$ | async)?.third}">
                  <mat-hint>
                    {{ 'password.passwordStrength.specialCharacters' | translate }}
                  </mat-hint>
                </li>
              </ul>
            </div>

            <div class="complete-view__body-content--fields">
              <div class="complete-view__body-content--fields-line password-field">
                <claas-text-field
                  class="full-width"
                  [ngClass]="{'hidden-content': !(showPassword$ | async).firstField}"
                  formControlName="passwordNewCtrl"
                  [label]="('overview.password_new' | translate) + '*'"
                  [placeholder]="('overview.password_new' | translate) + '*'"
                  [error]="
                    (
                      !(invalidStatePasswordNew$ | async)?.first ||
                      (!(invalidStatePasswordNew$ | async)?.second) ||
                      (!(invalidStatePasswordNew$ | async)?.third)
                    ) &&
                    fieldsAreTouched.password_new
                      ? ('XXX.invalid' | translate)
                      : ''
                  "
                  (input)="updatedPasswordNew($event)"
                ></claas-text-field>

                <claas-icon-button
                  *ngIf="!(showPassword$ | async).firstField"
                  [iconSize]="mediumIconSize"
                  [icon]="hidePasswordIcon"
                  (click)="showHidePassword('firstField')"
                />

                <claas-icon-button
                  *ngIf="!!(showPassword$ | async).firstField"
                  [iconSize]="mediumIconSize"
                  [icon]="showPasswordIcon"
                  (click)="showHidePassword('firstField')"
                />
              </div>

              <div class="complete-view__body-content--fields-line password-field">
                <claas-text-field
                  class="full-width"
                  [ngClass]="{'hidden-content': !(showPassword$ | async).secondField}"
                  formControlName="passwordNewConfirmCtrl"
                  [label]="('overview.password_new_confirm' | translate ) + '*'"
                  [placeholder]="('overview.password_new_confirm' | translate ) + '*'"
                  [error]="!!(invalidStatePasswordNewConfirm$ | async) && fieldsAreTouched.password_new_confirm
                      ? ('XXX.invalid' | translate)
                      : ''
                    "
                  (input)="updatedPasswordNewConfirm($event)"
                ></claas-text-field>

                <claas-icon-button
                  *ngIf="!(showPassword$ | async).secondField"
                  [iconSize]="mediumIconSize"
                  [icon]="hidePasswordIcon"
                  (click)="showHidePassword('secondField')"
                />

                <claas-icon-button
                  *ngIf="!!(showPassword$ | async).secondField"
                  [iconSize]="mediumIconSize"
                  [icon]="showPasswordIcon"
                  (click)="showHidePassword('secondField')"
                />
              </div>
            </div>

            <div class="complete-view__body-content--actions">
              <claas-button
                *ngIf="(lastStep$ | async) !== 'password'; else submitButton"
                class="btn-right next-btn"
                [text]="'generic.next' | translate"
                [disabled]="!!(invalidPasswordStepState$ | async)"
                (click)="stepper.next()">
              </claas-button>

              <ng-template #submitButton>
                <claas-button
                  class="btn-right submit-btn"
                  type="submit"
                  [text]="'complete.submit_button' | translate"
                  [disabled]="!!(invalidPasswordStepState$ | async)"
                  (click)="submitStepper()">
                </claas-button>
              </ng-template>
            </div>
          </form>
        </mat-step>

        <!-- Account linking step -->
        <mat-step *ngIf="this.connectionReq">
          <ng-template matStepLabel>{{ 'generic.info' | translate }}</ng-template>
          <div class="complete-view__body-content--header">
            <span class="header-3-bold">{{ 'complete.headline_al' | translate }}</span>
          </div>

          <div class="complete-view__body-content--message">
            <p [innerHTML]="'account_linking.intro' | translate"></p>
          </div>

          <div class="complete-view__body-content--actions">
            <claas-button
              *ngIf="(lastStep$ | async) !== 'linking'; else submitButton"
              class="btn-right next-btn"
              [text]="'generic.next' | translate"
              (click)="stepper.next()">
            </claas-button>

            <ng-template #submitButton>
              <claas-button
                class="btn-right submit-btn"
                type="submit"
                [text]="'complete.submit_button' | translate"
                (click)="submitStepper()">
              </claas-button>
            </ng-template>
          </div>
        </mat-step>

        <!-- Consents step -->
        <mat-step
          *ngIf="clients && clients?.length && consentsFormGroup"
          [stepControl]="consentsFormGroup"
        >
          <form [formGroup]="consentsFormGroup">
            <ng-template matStepLabel>{{ 'consent.consent' | translate }}</ng-template>

            <div class="complete-view__body-content--header">
              <span class="header-3-bold">{{ 'complete.headline' | translate }}</span>
            </div>
            <!-- Applications Consent -->
            <div
              *ngIf="applicationConsent"
              class="complete-view__body-content--message"
            >
              <p class="application-name">{{ 'applications.' + this.currentClientName + '.name' | translate }}</p>
              <br/>
              <span *ngIf="reconsentReq">{{ 'overview.not_connected_info_label' | translate }}</span>
              <ul *ngIf="reconsentReq" class="consent-info-access-list">
                <li class="consent-info-access-list-item">{{ 'overview.name' | translate }}</li>
                <li class="consent-info-access-list-item">{{ 'overview.email' | translate }}</li>
                <li class="consent-info-access-list-item">{{ 'overview.country' | translate }}</li>
                <li class="consent-info-access-list-item">{{ 'overview.addresses' | translate }}</li>
                <li class="consent-info-access-list-item">{{ 'overview.phones' | translate }}</li>
              </ul>

              <div class="complete-view__body-content--fields">
                <div class="complete-view__body-content--fields-line checkbox-container">
                  <claas-checkbox
                    class="checkbox"
                    formControlName="applicationsConsent"
                    [checked]="termsAccepted"
                    (onChange)="this.updateTermsState(!($event))"
                  ></claas-checkbox>

                  <p [innerHtml]="'applications.' + this.currentClientName + '.accept_terms' | translate"></p>
                </div>
              </div>
            </div>

            <!-- CLAAS ID  Consent-->
            <div
              *ngIf="claasIdConsentIsIncluded"
              class="complete-view__body-content--message"
            >
              <span class="application-name">CLAAS ID</span>
              <div class="complete-view__body-content--fields">
                <div class="complete-view__body-content--fields-line">
                  <span>{{ 'consent.intro_3' | translate }}</span>
                </div>

                <div class="complete-view__body-content--fields-line checkbox-container">
                  <claas-checkbox
                    class="checkbox"
                    formControlName="claasIdConsent"
                    [checked]="termsClaasIdAccepted"
                    (onChange)="this.updateTermsState(!($event), true)"
                  ></claas-checkbox>

                  <p [innerHtml]="'applications.CLAAS_ID.accept_terms' | translate"></p>
                </div>
              </div>
            </div>

            <div class="complete-view__body-content--actions">
              <claas-button
                *ngIf="(lastStep$ | async) !== 'consent'; else submitButton"
                class="btn-right next-btn"
                [text]="'generic.next' | translate"
                [disabled]="!!(termsState$ | async)"
                (click)="stepper.next()">
              </claas-button>

              <ng-template #submitButton>
                <claas-button
                  class="btn-right next-btn"
                  [text]="'complete.submit_button' | translate"
                  [disabled]="!!(termsState$ | async)"
                  (click)="submitStepper()">
                </claas-button>
              </ng-template>
            </div>
          </form>
        </mat-step>

        <!-- Newsletter/ Marketing step -->
        <mat-step *ngIf="newsletterReq">
          <ng-template matStepLabel>{{ 'overview.marketing.consent' | translate }}</ng-template>

          <div class="complete-view__body-content--header">
            <span class="header-3-bold">{{ 'overview.marketing.intro_headline' | translate }}</span>
          </div>

          <div class="complete-view__body-content--message">
            <div class="complete-view__body-content--fields">
              <div class="complete-view__body-content--fields-line">
                <span>{{ 'overview.marketing.headline' | translate }}</span>
              </div>

              <div class="complete-view__body-content--fields-line checkbox-container">
                <claas-checkbox
                  class="checkbox"
                  [label]="'overview.central_marketing_permission.consent.acceptance.claas_only' | translate "
                  [checked]="(marketingPermissionGranted$ | async) === true"
                  (onChange)="updateMarketingPermission($event)"
                ></claas-checkbox>
              </div>

              <div
                *ngIf="marketingPermissionGranted$ | async"
                class="complete-view__body-content--fields-line"
              >
                <ul class="marketing-permissions-list">
                  <li>
                    <claas-checkbox
                      class="checkbox"
                      [label]="'overview.central_marketing_permission.channels.post' | translate "
                      [checked]="(marketingSpecificPermissionGranted$ | async)?.post"
                      (onChange)="updateMarketingPermission($event, 'post')"
                    ></claas-checkbox>
                  </li>

                  <li>
                    <claas-checkbox
                      class="checkbox"
                      [label]="'overview.central_marketing_permission.channels.phone' | translate "
                      [checked]="(marketingSpecificPermissionGranted$ | async)?.phone"
                      (onChange)="updateMarketingPermission($event, 'phone')"
                    ></claas-checkbox>
                  </li>

                  <li>
                    <claas-checkbox
                      class="checkbox"
                      [label]="'overview.central_marketing_permission.channels.email' | translate "
                      [checked]="(marketingSpecificPermissionGranted$ | async)?.email"
                      (onChange)="updateMarketingPermission($event, 'email')"
                    ></claas-checkbox>
                  </li>

                  <li>
                    <claas-checkbox
                      class="checkbox"
                      [label]="'overview.central_marketing_permission.channels.push' | translate "
                      [checked]="(marketingSpecificPermissionGranted$ | async)?.push"
                      (onChange)="updateMarketingPermission($event, 'push')"
                    ></claas-checkbox>
                  </li>
                </ul>
              </div>

              <div
                *ngIf="marketingPermissionGranted$ | async"
                class="complete-view__body-content--fields-line"
              >
                <span>{{ 'overview.central_marketing_permission.consent.subtext.claas_only' | translate }}</span>
              </div>

              <div
                *ngIf="marketingPermissionGranted$ | async"
                class="complete-view__body-content--fields-line"
              >
                <span [innerHtml]="'overview.central_marketing_permission.consent.subtext_2' | translate: {country: this.userCountry}"></span>
              </div>

              <div
                *ngIf="marketingFootnotes && marketingFootnotes !== ''"
                class="complete-view__body-content--fields-line"
              >
                <span>{{ marketingFootnotes }}</span>
              </div>
            </div>
          </div>

          <div class="complete-view__body-content--actions">
            <claas-button
              class="btn-right next-btn"
              [text]="'complete.submit_button' | translate"
              [disabled]="!(marketingPermissionGranted$ | async)"
              (click)="submitStepper()">
            </claas-button>
          </div>
        </mat-step>
      </mat-stepper>
    </div>
  </div>
</div>
