import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IMarketingPermission, IPersistClaasMarketingPermission, IPersistDealerMarketingPermission, IPersistMarketingPermission } from 'cde-fe-organization-registration-dialog/lib/models/marketing-permission.model';
import { map, take } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MarketingPermissionService {

  private marketingBaseUrl = environment.base_urls.mps_base_url;

  constructor(private http: HttpClient
  ) { }


  persistClaasMarketingPermission(persistClaasMarketingPermission: IPersistClaasMarketingPermission) {
    this.postClaasMarketingPermission(
      persistClaasMarketingPermission.userId,
      persistClaasMarketingPermission.request
    ).pipe(take(1)).subscribe();
  }

  persistDealerMarketingPermissions(persistDealerMarketingPermission: IPersistDealerMarketingPermission) {
    this.postDealerMarketingPermissions(
      persistDealerMarketingPermission.userId,
      persistDealerMarketingPermission.dealerId,
      persistDealerMarketingPermission.request
    ).pipe(take(1)).subscribe();
  }

  postDealerMarketingPermissions(userId: string, dealerId: string, request: IPersistMarketingPermission) {
    return this.http
      .post(`${this.marketingBaseUrl}/${userId}/dealer/${dealerId}`, request)
      .pipe(map((response: any) => response.data as IMarketingPermission));
  }

  postClaasMarketingPermission(userId: string, request: IPersistMarketingPermission) {
    return this.http
      .post(`${this.marketingBaseUrl}/${userId}/claas`, request)
      .pipe(map((response: any) => response.data as IMarketingPermission));
  }

}
