import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output
} from '@angular/core';
import { UserOrganisations } from '../shared/state/organisations/organisations.state';
import {
  ButtonClass,
  ButtonSize,
  Icon,
  IconFontSize,
  IconSize
} from '@claas/claas-form-components';
import {
  DialogWindowComponent,
  ElementTypeOptions,
  ResponseDataFromDialog,
  TargetOptions,
  passedDataToDialog
} from '../shared/components/dialog-window/dialog-window.component';
import { map } from 'rxjs/operators';
import { loadUserOrganisations } from '../shared/state/organisations';
import { Observable, Subscription } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from '../shared/services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { IUser } from '../shared/models/auth.model';
import { DividerOrientation } from '@claas/claas-layout-components';
import { countriesArr } from '../shared/components/language-view/countries-source';

@Component({
  selector: 'app-organisation-data-overview',
  templateUrl: './organisation-data-overview.component.html',
  styleUrls: ['./organisation-data-overview.component.scss']
})
export class OrganisationDataOverviewComponent implements OnDestroy {
  private currentContext: undefined | string;
  private subscriptions = new Subscription();

  countries = countriesArr;

  editIcon: Icon = {
    iconId: '001326',
    iconStyle: 'bold',
    namespace: 'claas-id',
    size: 24
  };

  addIcon: Icon = {
    iconId: '000973',
    iconStyle: 'bold',
    namespace: 'claas-id',
    size: 19
  };

  primaryTextButtonClass = ButtonClass.PRIMARY_TEXT;
  mediumButtonSize = ButtonSize.DEFAULT_MEDIUM;
  errorButtonClass = ButtonClass.ERROR;
  mediumIconSize = IconSize.MEDIUM;
  mediumIconFontSize = IconFontSize.MEDIUM;
  horizontalDivider = DividerOrientation.HORIZONTAL;
  invoiceValue = ElementTypeOptions.INVOICE;
  deliveryValue = ElementTypeOptions.DELIVERY;
  isHandset = false;

  @Input() organisation: UserOrganisations | undefined;
  @Input() currentUser: IUser | undefined;
  @Input() orgaId: string | null = null;
  @Input() isAdmin = false;
  @Output() goPreviousPage$ = new EventEmitter<void>();
  @Output() loadOrganisationDetails$ = new EventEmitter<void>();

  constructor(
    private store: Store,
    public dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    public apiService: ApiService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService
  ) {
    this.subscriptions.add(this.isHandset$().subscribe());
  }

  getCountry(country: string, prop: string) {
    if (!country) {
      return '';
    }
    const c = this.countries.find(function (el) {
      return el.id === country.toLowerCase();
    });
    // @ts-ignore
    return c === undefined ? '' : c[prop];
  }

  isHandset$(): Observable<any> {
    return this.breakpointObserver
      .observe(Breakpoints.Handset)
      .pipe(map((state: any) => (this.isHandset = state.matches)));
  }

  displayPopup(text: string, className?: string): void {
    this.snackBar.open(text, '', {
      duration: 5000,
      panelClass: className ? className : ''
    });
  }

  openDialog(
    context: string,
    subContext?: string,
    elementType?: ElementTypeOptions
  ): void {
    this.currentContext = context;
    const data: passedDataToDialog = {
      context,
      buttonCancel: 'Cancel'
    };

    if (context === 'organisation-deletion') {
      data.confirmationHeader = 'organization.delete_organization.label';
      data.confirmationText = 'generic.delete_text';
      data.buttonDelete = 'Delete';
    }

    if (
      context === 'organisation-name' ||
      context === 'organisation-type' ||
      context === 'organisation-country' ||
      context === 'organisation-timezone' ||
      context === 'organisation-unitsystem' ||
      context === 'organisation-phonenumber' ||
      context === 'organisation-address'
    ) {
      data.organisationData = this.organisation;
      data.buttonSave = 'Save';
    }

    if (context === 'organisation-phonenumber') {
      data.subContext = subContext;
      data.countriesForPhone = [];
      if (this.organisation) {
        data.countriesForPhone.push(this.organisation['country'].toLowerCase());
      }
    }

    if (context === 'organisation-address') {
      data.subContext = 'edit';
      data.target = TargetOptions.ORGANISATION;
      data.elementType = elementType;
    }

    const dialogRef = this.dialog.open(DialogWindowComponent, {
      maxWidth: this.isHandset ? '100vw' : '80vw',
      data
    });

    this.subscriptions.add(
      dialogRef
        .afterClosed()
        .pipe(
          map((answer: ResponseDataFromDialog) => {
            if (
              this.orgaId &&
              answer?.action === 'delete' &&
              answer?.context === 'organisation-deletion'
            ) {
              this.subscriptions.add(
                this.apiService.deleteOrganisation(this.orgaId).subscribe({
                  next: value => {
                    // Delete the organisation
                    this.translateService
                      .get('organization.delete_organization.success')
                      .subscribe((text: string) => {
                        this.displayPopup(text, '');
                        this.goPreviousPage$.emit();
                      });
                  },
                  error: err => {
                    this.translateService
                      .get('organization.delete_organization.fail')
                      .subscribe((text: string) => {
                        this.displayPopup(text, 'error');
                      });
                  }
                })
              );
            }

            if (answer?.action === 'save' && answer?.refreshIsRequired) {
              this.loadOrganisationDetails$.emit();

              if (this.currentUser?.uuid) {
                this.store.dispatch(
                  loadUserOrganisations({ userId: this.currentUser?.uuid })
                );
              }
            }
          })
        )
        .subscribe()
    );
  }

  showConfirmationDialog(context: string): void {
    this.openDialog(context);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
