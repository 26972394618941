<div class='org-selection'>
  <div class='org-selection-panel' *ngIf="orgSelection">
    <h1 class='org-selection-headline'>
      {{ 'orgSelection.org_headline' | translate }}
    </h1>
    <div *ngIf="this.organizations.length; else noOrgsFound">
      <claas-dropdown-key-value
        [defaultOption]="getDefaultOrgOption()"
        [label]="'orgSelection.organization' | translate"
        [options]="organizationOptions"
        (onChange)="
          selectOrg($event)
      ">
      </claas-dropdown-key-value>
      <!-- Consent -->
      <div *ngIf="this.showConsent && this.consentText" style="margin-bottom: 50px;">
        <claas-circular-progress
          *ngIf="showSpinner"
          [color]="color"
          [diameter]="largeSize"></claas-circular-progress>

        <h2 class="consent-header">{{ getConsentTextKey("headline") }}</h2>
        <p> {{ getConsentTextKey("prefix_text_0") }}</p>
        <p [innerHTML]="getConsentTextKey('prefix_text_1')"></p>
        <ul class="data-types">
          <li>{{ getConsentTextKey("data_types_operational_data") }}</li>
          <li>{{ getConsentTextKey("data_types_machine_identification") }}</li>
          <li>{{ getConsentTextKey("data_types_machine_master_data") }}</li>
          <li>{{ getConsentTextKey("data_types_machine_performance_data") }}</li>
          <li>{{ getConsentTextKey("data_types_machine_movement_data") }}</li>
          <li>{{ getConsentTextKey("data_types_environmental_data") }}</li>
        </ul>
        <p>
          {{ getConsentTextKey("service_request_text") }}
        </p>
        <ul class="data-types">
          <li>{{ getConsentTextKey("service_request_data_personal_identification_data") }}</li>
          <li>{{ getConsentTextKey("service_request_data_contact_data") }}</li>
        </ul>
        <p [innerHTML]="getConsentTextKey('data_privacy_notice')"></p>
        <br>
        <p>{{ getConsentTextKey("international_data_transfer") }}</p>
        <br>
        <p>{{ getConsentTextKey("consent_revocation_notice") }}</p>

    </div>
    <div class="org-selection-actions">
      <claas-button
        *ngIf="this.showConsent && this.consentText"
        class="org-selection-button"
        [text]="'generic.cancel' | translate"
        [buttonClass]="secondaryButtonClass"
        [size]="buttonSize"
        (onClick)="
          cancel()
        "></claas-button>
      <claas-button
        *ngIf="!this.showConsent"
        class="org-selection-button"
        [text]="'orgSelection.submit_button' | translate"
        [buttonClass]="primaryButtonClass"
        [size]="buttonSize"
        (onClick)="
          sendOrg(false)
        "></claas-button>
      <claas-button
        *ngIf="this.showConsent && this.consentText"
        class="org-selection-button"
        [text]="getConsentTextKey('button_confirm')"
        [buttonClass]="primaryButtonClass"
        [size]="buttonSize"
        (onClick)="
          sendOrg(true)
        "></claas-button>
    </div>

    </div>
  </div>

  <div class='org-selection-panel' *ngIf="!orgSelection">
    <claas-circular-progress
    [color]="color"
    [diameter]="largeSize"></claas-circular-progress>
  </div>
</div>

<ng-template #noOrgsFound>
  {{ 'orgSelection.no_orgs' | translate }}
</ng-template>