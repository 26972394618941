import { createAction, props } from '@ngrx/store';
import { User } from '../../models/auth.model';

export const allAuthActions = {
  loginFlowInitiated: createAction('[Auth] Login Flow Initiated'),
  logoutFlowInitiated: createAction('[Auth] Logout Flow Initiated'),
  loginCallback: createAction('[Auth] Login Callback'),
  checkLogin: createAction('[Auth] Check Login'),
  loginComplete: createAction(
    '[Auth] Login Complete',
    props<{ profile: any; isLoggedIn: boolean }>()
  ),
  logoutComplete: createAction('[Auth] Logout Complete'),
  setOrganisation: createAction(
    '[Auth] Set Organisation',
    props<{ orgId: string; organisationWasRegistered?: { country: string } }>()
  ),
  setOrganisationSuccess: createAction(
    '[Auth] Set organisation success',
    props<{ token: string; organisationWasRegistered?: { country: string } }>()
  ),
  setOrganisationFailure: createAction(
    '[Auth] Set organisation failure',
    props<any>()
  ),
  loadUserAvatarUrl: createAction('[Auth] get user avatar Url'),
  userAvatarUrlLoaded: createAction('', props<{ url: string | null }>())
};

export const userChangedFromAuth0SDK = createAction(
  '[Auth0 SDK] User Changed',
  props<{ userDetails: User }>()
);
