import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ButtonSize,
  Icon,
  IconFontSize,
  IconSize,
  ToggleSize
} from '@claas/claas-form-components';
import { MatDialog } from '@angular/material/dialog';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  skipWhile,
  take
} from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { DialogWindowComponent } from '../shared/components/dialog-window/dialog-window.component';
import { ApiService } from '../shared/services/api.service';
import { languagesArr } from '../shared/components/dialog-window/languages-source';
import { AuthService } from '../shared/services/auth.service';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import { countriesArr } from '../shared/components/language-view/countries-source';
import { Store } from '@ngrx/store';
import { selectUserCoordinates } from '../shared/state/user-coordinates/user-coordinates.selectors';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { loadUserCoordinates } from '../shared/state/user-coordinates/user-coordinates.actions';
import { Consent } from '../shared/models/consent.model';
import {
  CircularProgressDiameter,
  ProgressColor
} from '@claas/claas-layout-components';
import { OrganizationInvitation } from '../shared/models/organisation-invitation.model';
import { IUser } from 'cde-fe-organization-registration-dialog';
import { Organization } from '../shared/models/organization.model';
import { selectUserDetails } from '../shared/state/user';
import { loadUserOrganisations } from '../shared/state/organisations';
import { selectOrganisations } from '../shared/state/organisations/organisations.selectors';
import { UserOrganisations } from '../shared/state/organisations/organisations.state';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent implements OnDestroy, OnInit {
  largeSize = CircularProgressDiameter.LARGE;
  mediumButtonSize = ButtonSize.DEFAULT_MEDIUM;
  mediumToggleSize = ToggleSize.DEFAULT_MEDIUM;
  mediumIconSize = IconSize.MEDIUM;
  mediumIconFontSize = IconFontSize.MEDIUM;
  isLoadingMfa$ = new BehaviorSubject(true);
  isLoadingCountry$ = new BehaviorSubject(true);
  isLoadingLanguage$ = new BehaviorSubject(true);
  organisations$: BehaviorSubject<any> = new BehaviorSubject(undefined);
  // MFA Variables
  mfaChecked = false;
  ticketUrl = '';
  language = '';
  user: IUser | undefined;
  isSocial = false;
  currentUser: IUser | undefined;
  organisationInvitation: OrganizationInvitation | undefined;
  countriesArray = countriesArr;
  country = '';
  userEmail = '';
  isHandset = false;
  consents: Consent[] = [];
  adminOrgs: Organization[] = [];
  orgAdminInvitations: OrganizationInvitation[] = [];
  secondaryColor = ProgressColor.SECONDARY;
  editIcon: Icon = {
    iconId: '001326',
    iconStyle: 'bold',
    namespace: 'claas-id',
    size: 24
  };
  infoIcon: Icon = {
    iconId: '497661',
    iconStyle: 'bold',
    namespace: 'claas-id',
    size: 24
  };
  private dialogSubscriptions = new Subscription();

  constructor(
    private store: Store,
    private apiService: ApiService,
    public dialog: MatDialog,
    private auth: AuthService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.dialogSubscriptions.add(this.isHandset$().subscribe());
  }

  isHandset$(): Observable<any> {
    return this.breakpointObserver
      .observe(Breakpoints.Handset)
      .pipe(map((state: any) => (this.isHandset = state.matches)));
  }

  ngOnInit() {
    this.getMfa();
    this.getUserBaseProfile();
    this.isSocialAccount();
    this.getUserConsents();

    this.dialogSubscriptions.add(
      this.store
        .select(selectUserDetails)
        .pipe(
          filter((user: any) => !!user),
          tap((user: IUser) => {
            this.user = user;
            this.getInvitations();
            this.getOrganisations();
          })
        )
        .subscribe()
    );
  }

  getAdminInvitations() {
    if (this.adminOrgs.length !== 0) {
      for (const org of this.adminOrgs) {
        if (org.auth0Id) {
          this.dialogSubscriptions.add(
            this.apiService
              .getOrganisationAdminInvitation(org.auth0Id)
              .subscribe({
                next: (result: any) => {
                  this.orgAdminInvitations = result;
                  if (this.orgAdminInvitations !== null) {
                    for (const inv of this.orgAdminInvitations) {
                      if (this.dialog.openDialogs.length === 0) {
                        this.openDialog('admin-invitation', inv);
                      }
                    }
                  }
                },
                error: err => {
                  console.log(err);
                }
              })
          );
        }
      }
    }
  }

  getInvitations() {
    if (this.user) {
      this.dialogSubscriptions.add(
        this.apiService.getOrganisationInvitation(this.user.sub).subscribe({
          next: (res: any) => {
            this.organisationInvitation = res;
            if (
              this.organisationInvitation !== null &&
              this.dialog.openDialogs.length === 0
            ) {
              this.openDialog('user-invitation');
            }
          },
          error: err => {
            console.log(err);
          }
        })
      );
    }
  }

  getOrganisations() {
    this.dialogSubscriptions.add(
      this.store
        .select(selectUserDetails)
        .pipe(
          filter((user: any) => !!user),
          tap((user: IUser) => {
            this.user = user;
            // in case the token is required please use "user.sub" for it
            this.store.dispatch(loadUserOrganisations({ userId: user.uuid }));

            this.dialogSubscriptions.add(
              this.store
                .select(selectOrganisations)
                .pipe(
                  filter((organisations: any) => !!organisations),
                  map((organisations: UserOrganisations) => {
                    if (Object.keys(organisations).length === 0) {
                      // the user has no registered organisation
                    } else {
                      // convert the dictionary into an array
                      this.organisations$.next(Object.values(organisations));
                      this.getAdminOrgs();
                    }
                  })
                )
                .subscribe()
            );
          })
        )
        .subscribe()
    );
  }

  getAdminOrgs() {
    this.dialogSubscriptions.add(
      this.organisations$
        .pipe(
          skipWhile(value => !value),
          take(1)
        )
        .subscribe((res: Organization[]) => {
          for (const org of res) {
            this.dialogSubscriptions.add(
              this.apiService.getOrganisationMembers(org.auth0Id!).subscribe({
                next: (members: any) => {
                  for (const member of members.content) {
                    if (member.role === 'ADMIN') {
                      if (member.idpUser === this.user!.sub) {
                        this.adminOrgs.push(org);
                      }
                    }
                  }

                  this.getAdminInvitations();
                },
                error: (err: Error) => {
                  const errorString = JSON.stringify(err);
                  console.log(errorString);
                }
              })
            );
          }
        })
    );
  }

  getUserBaseProfile() {
    this.dialogSubscriptions.add(
      this.store.select(selectUserCoordinates).subscribe({
        next: (coordinates: any) => {
          if (!coordinates) {
            this.store.dispatch(loadUserCoordinates());
          } else {
            if (coordinates['email']) {
              this.userEmail = coordinates['email'];
            }

            if (coordinates['language']) {
              this.language = coordinates['language'].toLowerCase();
              this.isLoadingLanguage$.next(false);
            }

            if (coordinates['country']) {
              this.country = coordinates['country'].toLowerCase();
              this.isLoadingCountry$.next(false);
            }
          }
        }
      })
    );
  }

  getUserConsents() {
    this.dialogSubscriptions.add(
      this.apiService.getConsent().subscribe({
        next: res => {
          if (res) {
            this.consents = res;
          } else {
            this.consents = [];
          }
        },
        error: (err: Error) => {
          const errorString = JSON.stringify(err);
          console.log(errorString);
        }
      })
    );
  }

  isSocialAccount() {
    this.auth.auth0Client$.subscribe(async (client: Auth0Client) => {
      const claims = await client.getIdTokenClaims();
      const json0 = JSON.parse(JSON.stringify(claims));
      this.isSocial = json0['https://claas.com/isSocialConnection'];
    });
  }

  getLanguage(lang: string) {
    if (lang === 'en') {
      lang = 'en-us';
    }
    const c = languagesArr.find(function (element) {
      return element.id === lang;
    });
    return c === undefined ? '' : c.label;
  }

  getCountry(country: string, prop: string) {
    if (!country) {
      return '';
    }
    const c = this.countriesArray.find(function (el) {
      return el.id === country.toLowerCase();
    });
    // @ts-ignore
    return c === undefined ? '' : c[prop];
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  getMfa() {
    this.dialogSubscriptions.add(
      this.apiService.getUsersMfa().subscribe({
        next: res => {
          if (Array.isArray(res)) {
            if (res.length > 0) {
              this.mfaChecked = true;
            }
          } else {
            this.mfaChecked = false;
          }
        },
        error: err => {
          const errorString = JSON.stringify(err);
          console.log(errorString);
        },
        complete: () => {
          this.isLoadingMfa$.next(false);
        }
      })
    );
  }

  async toggleMfa() {
    this.mfaChecked = !this.mfaChecked;
    if (this.mfaChecked) {
      await this.delay(500);
      const ticketWindow: Window = window.open()!;

      this.dialogSubscriptions.add(
        this.apiService.getMfaEnrollmentTicket().subscribe({
          next: res => {
            // @ts-ignore
            this.ticketUrl = res['ticketUrl'];
            ticketWindow.location.href = this.ticketUrl;
          },
          error: err => {
            const errorString = JSON.stringify(err);
            console.log(errorString);
          }
        })
      );
    } else {
      this.dialogSubscriptions.add(
        this.apiService.deleteUsersMfa().subscribe({
          next: () => {},
          error: err => {
            const errorString = JSON.stringify(err);
            console.log(errorString);
          }
        })
      );
    }
  }

  openDialog(context: string, invitation?: OrganizationInvitation): void {
    let config: any;

    switch (context) {
      case 'profile-two-factor':
        config = {
          data: {
            context,
            buttonSave: 'generic.confirm'
          }
        };
        break;

      case 'profile-password':
        config = {
          data: {
            context,
            profileEmail: this.userEmail,
            buttonCancel: 'generic.cancel',
            buttonSave: 'account_settings.password.change.label'
          }
        };
        break;

      case 'profile-language':
        config = {
          data: {
            context,
            language: this.language,
            buttonCancel: 'generic.cancel',
            buttonSave: 'generic.confirm'
          }
        };
        break;

      case 'profile-deletion':
        config = {
          data: {
            context,
            consents: this.consents,
            isSocial: this.isSocial,
            buttonSave: 'account_settings.delete_account',
            buttonCancel: 'generic.cancel',
            buttonConfirm: 'generic.confirm'
          }
        };
        break;

      case 'admin-invitation':
        config = {
          data: {
            context,
            buttonCancel: 'generic.cancel',
            buttonSave: 'generic.confirm',
            organisationInvitation: invitation
          }
        };
        break;

      case 'user-invitation':
        config = {
          data: {
            context,
            buttonCancel: 'generic.cancel',
            buttonSave: 'generic.confirm',
            organisationInvitation: this.organisationInvitation
          }
        };
        break;

      case 'user-update':
        config = {
          data: {
            context,
            buttonCancel: 'generic.cancel',
            buttonSave: 'generic.confirm'
          }
        };
        break;
    }

    config.maxWidth = this.isHandset ? '100vw' : '80vw';

    const dialogRef = this.dialog.open(DialogWindowComponent, config);

    this.dialogSubscriptions.add(
      dialogRef
        .afterClosed()
        .pipe(
          tap((answer: any) => {
            switch (answer?.context) {
              case 'profile-language':
                this.language = answer?.language;
                break;
            }
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy() {
    this.dialogSubscriptions.unsubscribe();
  }
}
