import { OrganisationDealerModel } from '../../models/organisation-dealer.model';
import {
  IDealerByNameResponse,
  INearestDealersResponse
} from 'cde-fe-organization-registration-dialog/lib/models/organisation.model';
import { IDealerFilter } from 'cde-fe-organization-registration-dialog/lib/models/dealer.model';

export interface OrganisationDealersState {
  connectedDealers: OrganisationDealerModel[];
  defaultDealer: OrganisationDealerModel | undefined;
  nearestDealersSearchData: INearestDealersResponse | null;
  nearestDealersSearchDataPending: boolean;
  dealerByNameSearchData: IDealerByNameResponse | null;
  dealerFilters: IDealerFilter[] | null;
}

export const initialState: OrganisationDealersState = {
  connectedDealers: [],
  defaultDealer: undefined,
  nearestDealersSearchData: null,
  nearestDealersSearchDataPending: false,
  dealerByNameSearchData: null,
  dealerFilters: null
};
