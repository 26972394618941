import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {
  ResponseDataFromDialog,
  passedDataToDialog
} from '../dialog-window/dialog-window.component';
import { ButtonClass } from '@claas/claas-form-components';
import { environment } from '../../../../environments/environment';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subscription, finalize, forkJoin } from 'rxjs';
import {
  OptionObs,
  errorStatusObject
} from '../address-view/address-view.component';
import { ApiService } from '../../services/api.service';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { Organization } from '../../models/organization.model';

export interface unitSystemOption {
  value: string;
}

export interface option {
  [key: string]: string;
}

@Component({
  selector: 'app-organisation-unitsystem-view',
  templateUrl: './organisation-unitsystem-view.component.html',
  styleUrls: ['./organisation-unitsystem-view.component.scss']
})
export class OrganisationUnitsystemViewComponent implements OnInit, OnDestroy {
  @Input() data: passedDataToDialog = {};
  @Input() secondaryTextButtonClass?: ButtonClass;

  @Output() displayPopup = new EventEmitter();
  @Output() closeDialogWindow = new EventEmitter();

  tempType = '';
  organisationUnitsystemDetails = environment.general.unitSystems;
  organisationUnitsystemOptions: string[] = [];
  myForm: FormGroup | undefined;
  subscriptions = new Subscription();
  translationsDictionary: option | undefined;
  invalidSaveButtonState$ = new BehaviorSubject(true);
  errorsStatusObject$: errorStatusObject = {};

  constructor(
    private apiService: ApiService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.getOptionsTranslation();
  }

  getOptionsTranslation(): void {
    const obsArray: OptionObs = {};

    this.organisationUnitsystemDetails.forEach(
      (option: unitSystemOption) =>
        (obsArray[option.value] = this.translateService.get(
          'registration.formfield.select.'.concat(option.value)
        ))
    );

    forkJoin(obsArray).subscribe(translations => {
      this.translationsDictionary = translations;
      // define the options array
      for (const key in translations) {
        this.organisationUnitsystemOptions.push(translations[key]);
      }

      // start with the form creation
      this.tempType = translations[this.data.organisationData?.unitSystem]
        ? translations[this.data.organisationData?.unitSystem]
        : '';

      this.myForm = this.setForm();
      this.trackForm(this.myForm);
    });
  }

  setForm(): FormGroup {
    return new FormGroup({
      unitSystem: new FormControl(this.tempType, [Validators.required])
    });
  }

  trackForm(myForm: FormGroup): void {
    // @ts-ignore
    this.errorsStatusObject$['unitSystem'] = this.myForm
      .get('unitSystem')
      .valueChanges.pipe(
        map(value => {
          return this.myForm?.get('unitSystem')?.status === 'INVALID'
            ? 'registration.validationMsgs.required'
            : undefined;
        })
      );

    this.subscriptions.add(
      myForm.valueChanges.subscribe(() => {
        // update the save button status
        this.invalidSaveButtonState$.next(!!this.myForm?.invalid);
      })
    );
  }

  onCancelClicked(): any {
    const passedObject = {
      action: 'cancel'
    };

    this.closeDialogWindow.emit(passedObject);
  }

  onSaveClicked(): any {
    const passedObject: ResponseDataFromDialog = {
      context: this.data.context,
      action: 'save'
    };

    const organisationUpdate = this.preUpdateOrganisation();

    this.saveUnitsystemDetails(passedObject, organisationUpdate);
    this.invalidSaveButtonState$.next(true);
  }

  saveUnitsystemDetails(
    passedObject: ResponseDataFromDialog,
    organisationUpdate: Organization
  ): void {
    this.subscriptions.add(
      this.apiService
        .updateOrganisation(
          this.data.organisationData.auth0Id,
          organisationUpdate
        )
        .pipe(finalize(() => this.closeDialogWindow.emit(passedObject)))
        .subscribe({
          next: value => {
            // Update the organisation data
            this.translateService.get('organization.edit_unitsystem.success').subscribe(text => {
              passedObject.refreshIsRequired = true;
              this.displayPopup.emit({ text, className: '' });
            });
          },
          error: err => {
            this.translateService.get('organization.edit_unitsystem.fail').subscribe(text => {
              this.displayPopup.emit({ text, className: 'error' });
            });
          }
        })
    );
  }

  getKeyFromDictionary(translation: string): string {
    let targetKey = 'IMPERIAL';

    for (const key in this.translationsDictionary) {
      targetKey =
        this.translationsDictionary[key] === translation ? key : targetKey;
    }
    return targetKey;
  }

  preUpdateOrganisation(): Organization {
    const organisationUpdate = new Organization();
    const preValue = this.myForm?.get('unitSystem')?.value
      ? this.myForm?.get('unitSystem')?.value
      : '';
    const value = this.getKeyFromDictionary(preValue);

    switch (this.data.context) {
      case 'organisation-unitsystem':
        organisationUpdate.copyOrganisation(this.data.organisationData);
        organisationUpdate.unitSystem = value;
        break;
    }

    return organisationUpdate;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
