<div class="organisation-data-users">
  <section class="organisation-data-users__header">
    <claas-button
      class="organisation-data-users__header-action info-btn"
      [buttonClass]="secondaryTextButtonClass"
      [icon]="extendIcon"
      [text]="'organization.user_role_info' | translate"
      (onClick)="redirectToInfo()">
    </claas-button>

    <claas-button
      *ngIf="isAdmin"
      class="organisation-data-users__header-action add-btn"
      [icon]="addIcon"
      [text]="'organization.invite_user' | translate"
      [buttonClass]="primaryButtonClass"
      [size]="mediumButtonSize"
      (onClick)="inviteUserToOrganisation()"></claas-button>
  </section>

  <div *ngIf="!organisationUsers; else userListing">
    <div class="organisation-data-users__loader">
      <claas-circular-progress
        [color]="secondaryColor"
        [diameter]="largeSize"></claas-circular-progress>
    </div>
  </div>

  <ng-template #userListing>
    <section
      *ngIf="isSmallScreen; else largeListing"
      class="organisation-data-users__listing">
      <section class="organisation-data-users__listing tablet-listing">
        <div *ngFor="let entity of organisationUsers" class="line-container">
          <div class="content-line">
            <div class="content-left">
              <div class="avatar-picture">
                <img *ngIf="entity.profilePicture" [src]="entity.profilePicture" />
              </div>
            </div>
            <div class="content-right">
              <div class="content-right-first">
                <!-- Username Column -->
                <span
                  *ngIf="entity?.givenName || entity?.familyName"
                  class="entityname"
                  >{{ entity?.givenName }} {{ entity?.familyName }}</span
                >

                <span *ngIf="entity.invitee" class="invitee">{{
                  entity.invitee
                }}</span>

                <!-- Role Column -->
                <span
                  *ngIf="
                    entity.invitee ||
                    !isAdmin ||
                    (isAdmin && isUniqueAdmin && entity.idpUser === myUserId)
                  "
                  class="role"
                  >{{ entity.role ? ('organization.roles.'+(entity.role | lowercase) | translate) : '-' }}</span
                >

                <claas-dropdown-key-value
                  *ngIf="
                    !entity.invitee &&
                    isAdmin &&
                    !isUniqueAdmin &&
                    entity.idpUser === myUserId
                  "
                  [defaultOption]="getDefaultRoleOption(entity.role)"
                  [label]="'organization.user_role' | translate"
                  [options]="roleOptions"
                  [disabled]="requestInProgress"
                  (onChange)="
                    selectRole($event, entity?.idpUser)
                  "></claas-dropdown-key-value>

                <claas-dropdown-key-value
                  *ngIf="!entity.invitee && isAdmin && entity.idpUser !== myUserId"
                  [defaultOption]="getDefaultRoleOption(entity.role)"
                  [label]="'organization.user_role' | translate"
                  [options]="roleOptions"
                  [disabled]="requestInProgress"
                  (onChange)="
                    selectRole($event, entity?.idpUser)
                  "></claas-dropdown-key-value>
              </div>

              <div class="content-right-second">
                <!-- Status Column -->
                <app-chip
                  *ngIf="entity?.givenName || entity?.familyName"
                  [label]="'organization.user_status.accepted' | translate"
                  [type]="'accepted'"></app-chip>

                <app-chip
                  *ngIf="entity?.invitee && !entity.isExpired"
                  [label]="'organization.user_status.pending' | translate"
                  [type]="'pending'"></app-chip>

                <app-chip
                  *ngIf="entity.isExpired"
                  [label]="'organization.user_status.expired' | translate"
                  [type]="'outdated'"></app-chip>

                <!-- Actions Column -->
                <div class="actions">
                  <claas-button
                    *ngIf="
                      (entity.invitee || entity.idpUser !== myUserId) && isAdmin
                    "
                    class="organisation-data-users__listing-action delete-btn"
                    [buttonClass]="secondaryTextButtonClass"
                    [icon]="deleteIcon"
                    [disabled]="requestInProgress"
                    (onClick)="removeUserFromOrganisation(entity.idpUser ?  entity.idpUser : entity.id, !!entity.idpUser)">
                  </claas-button>

                  <claas-button
                    *ngIf="entity.isExpired && isAdmin"
                    class="organisation-data-users__listing-action invitation-btn"
                    [buttonClass]="secondaryTextButtonClass"
                    [icon]="invitationIcon"
                    [disabled]="requestInProgress"
                    (onClick)="
                      inviteUserToOrganisation(entity.invitee, entity.role, entity)
                    ">
                  </claas-button>

                  <claas-button
                    *ngIf="entity.idpUser === myUserId"
                    class="organisation-data-users__listing-action leave-btn"
                    [buttonClass]="secondaryTextButtonClass"
                    [icon]="leaveIcon"
                    [disabled]="requestInProgress"
                    (onClick)="leaveOrganisation(entity.idpUser)">
                  </claas-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>

    <ng-template #largeListing>
      <section class="organisation-data-users__listing large-listing">
        <table mat-table [dataSource]="organisationUsers">
          <!-- Username Column -->
          <ng-container matColumnDef="username">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'organization.user_name' | translate }}
            </th>
            <td mat-cell *matCellDef="let entity">
              <div class="cell first-cell">
                <div class="content-left">
                  <div class="avatar-picture">
                    <img
                      *ngIf="entity.profilePicture"
                      [src]="entity.profilePicture" />
                  </div>
                </div>

                <div
                  *ngIf="entity?.givenName || entity?.familyName"
                  class="content-right">
                  {{ entity?.givenName }} {{ entity?.familyName }}
                </div>

                <div *ngIf="entity.invitee" class="content-right">
                  {{ entity?.invitee }}
                </div>
              </div>
            </td>
          </ng-container>

          <!-- Role Column -->
          <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'organization.user_role' | translate }}
            </th>
            <td mat-cell *matCellDef="let entity">
              <div class="cell role">
                <span
                  *ngIf="
                    entity.invitee ||
                    !isAdmin ||
                    (isAdmin && isUniqueAdmin && entity.idpUser === myUserId)
                  "
                  >{{ entity.role ? ('organization.roles.'+(entity.role | lowercase) | translate) : '-' }}</span
                >

                <claas-dropdown-key-value
                  *ngIf="
                    !entity.invitee &&
                    isAdmin &&
                    !isUniqueAdmin &&
                    entity.idpUser === myUserId
                  "
                  [defaultOption]="getDefaultRoleOption(entity.role)"
                  [label]="'organization.user_role' | translate"
                  [options]="roleOptions"
                  [disabled]="requestInProgress"
                  (onChange)="
                    selectRole($event, entity?.idpUser)
                  "></claas-dropdown-key-value>

                <claas-dropdown-key-value
                  *ngIf="!entity.invitee && isAdmin && entity.idpUser !== myUserId"
                  [defaultOption]="getDefaultRoleOption(entity.role)"
                  [label]="'organization.user_role' | translate"
                  [options]="roleOptions"
                  [disabled]="requestInProgress"
                  (onChange)="
                    selectRole($event, entity?.idpUser)
                  "></claas-dropdown-key-value>
              </div>
            </td>
          </ng-container>

          <!-- Status Column -->
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'organization.user_status.header' | translate }}
            </th>
            <td mat-cell *matCellDef="let entity">
              <div class="cell status">
                <app-chip
                  *ngIf="entity?.givenName || entity?.familyName"
                  [label]="'organization.user_status.accepted' | translate"
                  [type]="'accepted'"></app-chip>

                <app-chip
                  *ngIf="entity?.invitee && !entity.isExpired"
                  [label]="'organization.user_status.pending' | translate"
                  [type]="'pending'"></app-chip>

                <app-chip
                  *ngIf="entity.isExpired"
                  [label]="'organization.user_status.expired' | translate"
                  [type]="'outdated'"></app-chip>
              </div>
            </td>
          </ng-container>

          <!-- Actions Column -->
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let entity">
              <div class="cell actions last-cell">
                <claas-button
                  *ngIf="(entity.invitee || entity.idpUser !== myUserId) && isAdmin"
                  class="organisation-data-users__listing-action delete-btn"
                  [buttonClass]="secondaryTextButtonClass"
                  [icon]="deleteIcon"
                  [disabled]="requestInProgress"
                  (onClick)="removeUserFromOrganisation(entity.idpUser ?  entity.idpUser : entity.id, !!entity.idpUser)">
                </claas-button>

                <claas-button
                  *ngIf="entity.isExpired && isAdmin"
                  class="organisation-data-users__listing-action invitation-btn"
                  [buttonClass]="secondaryTextButtonClass"
                  [icon]="invitationIcon"
                  [disabled]="requestInProgress"
                  (onClick)="inviteUserToOrganisation(entity.invitee, entity.role)">
                </claas-button>

                <claas-button
                  *ngIf="entity.idpUser === myUserId"
                  class="organisation-data-users__listing-action leave-btn"
                  [buttonClass]="secondaryTextButtonClass"
                  [icon]="leaveIcon"
                  [disabled]="requestInProgress"
                  (onClick)="leaveOrganisation(entity.idpUser)">
                </claas-button>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </section>
    </ng-template>
  </ng-template>
</div>
