import { User } from 'cde-fe-organization-registration-dialog/lib/models/auth.model';
import {
  IAddressAutocompleteResponse,
  IAddressDetailsResponse,
  IDealerByNameResponse,
  INearestDealersResponse,
  IRegisterOrganisationResult,
  IRegistrationCurrentDefaultOrganisation,
  IRegistrationCurrentOrganisationAddress,
  IRegistrationDealerDataResponse,
  IRegistrationDialogStep,
  IRegistrationPersonalData,
  IRegistrationShopData,
  OrganisationListItem,
  RegistrationDialogError
} from 'cde-fe-organization-registration-dialog/lib/models/organisation.model';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  IDealerDict,
  IDealerFilter
} from 'cde-fe-organization-registration-dialog/lib/models/dealer.model';
import { StateError } from 'cde-fe-organization-registration-dialog/lib/models/service-call.model';
import {
  DialogMode,
  IOrganisationRegistrationDialogPrefill
} from 'cde-fe-organization-registration-dialog/lib/models/organisation-registration-dialog.model';
import { OrganisationRegistrationDialogUserModel } from './organisationRegistrationDialogUser.model';
import { IMarketingPermission } from 'cde-fe-organization-registration-dialog/lib/models/marketing-permission.model';

export class OrganisationRegistrationDialogInputModel {
  UC_UI: any;
  user: User;
  orgAddress?: IRegistrationCurrentOrganisationAddress;
  bearerToken: string | null;
  apiBaseUrl: string;
  clientId: string;
  dialogSteps: IRegistrationDialogStep[];
  dialogMode?: DialogMode;
  disableAutostart?: boolean;
  completeKeyText?: string;
  completeStepLabel?: string;
  prefillData?: IOrganisationRegistrationDialogPrefill | null;
  disableInitialDealerSelection?: boolean;
  overrideProposedDealerId?: string;
  registrationDataPending$: Observable<boolean>;
  searchNearestDealerPending$: Observable<boolean>;
  currentDefaultOrganisation$: Observable<IRegistrationCurrentDefaultOrganisation | null>;
  currentOrganisation$: Observable<Partial<OrganisationListItem> | null>;
  registrationDealerData$: Observable<IRegistrationDealerDataResponse | null>;
  searchNearestDealers$: Observable<INearestDealersResponse | null>;
  dealerFilters$: Observable<IDealerFilter[] | null>;
  addressAutocompleteSuggestions$: Observable<
    IAddressAutocompleteResponse[] | null
  >;
  searchDealersByName$: Observable<IDealerByNameResponse | null>;
  registrationPersonalData$: Observable<IRegistrationPersonalData | null>;
  registrationError$: Observable<RegistrationDialogError>;
  organisationsError$: Observable<StateError>;
  getRegisteredOrganisation$: Observable<IRegisterOrganisationResult | null>;
  registrationDefaultOrganisation$: Observable<IRegistrationCurrentDefaultOrganisation | null>;
  registrationPersonalDataPending$: Observable<boolean>;
  registrationShopData$: Observable<IRegistrationShopData | null>;
  dealersData$: Observable<IDealerDict | null>;
  addressDetails$: Observable<IAddressDetailsResponse | null>;
  userMarketingPermissions$: Observable<IMarketingPermission[] | null>;

  constructor() {
    this.UC_UI = undefined;
    this.user = new OrganisationRegistrationDialogUserModel();
    this.bearerToken = '';
    this.apiBaseUrl = '';
    this.clientId = '';
    this.dialogSteps = [];

    this.registrationDefaultOrganisation$ = new BehaviorSubject(null);
    this.organisationsError$ = new BehaviorSubject(null);
    this.getRegisteredOrganisation$ = new BehaviorSubject(null);
    this.currentDefaultOrganisation$ = new BehaviorSubject(null);
    this.currentOrganisation$ = new BehaviorSubject(null);

    this.registrationDataPending$ = new BehaviorSubject(false);

    this.searchNearestDealerPending$ = new BehaviorSubject(false);
    this.registrationDealerData$ = new BehaviorSubject(null);
    this.searchNearestDealers$ = new BehaviorSubject(null);
    this.dealerFilters$ = new BehaviorSubject(null);
    this.searchDealersByName$ = new BehaviorSubject(null);
    this.dealersData$ = new BehaviorSubject(null);

    this.addressDetails$ = new BehaviorSubject(null);
    this.addressAutocompleteSuggestions$ = new BehaviorSubject(null);
    this.registrationPersonalDataPending$ = new BehaviorSubject(false);
    this.registrationShopData$ = new BehaviorSubject(null);
    this.registrationPersonalData$ = new BehaviorSubject(null);
    this.userMarketingPermissions$ = new BehaviorSubject(null);
    this.registrationError$ = new BehaviorSubject(0);
  }
}
