import { Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { Icon, IconFontSize, IconSize } from '@claas/claas-form-components';
import {
  CircularProgressDiameter,
  ProgressColor
} from '@claas/claas-layout-components';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  finalize,
  forkJoin,
  switchMap,
  tap
} from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  selectDefaultOrganisation,
  selectOrganisations,
  selectRegistrationSteps
} from '../shared/state/organisations/organisations.selectors';
import { filter, map } from 'rxjs/operators';
import { UserOrganisations } from '../shared/state/organisations/organisations.state';
import { selectUserDetails } from '../shared/state/user';
import {
  loadRegistrationDefaultOrganisation,
  loadUserDefaultOrganisation,
  loadUserOrganisations,
  organisationMembersNotLoaded,
  setUserDefaultOrganisation
} from '../shared/state/organisations';
import { Organization } from '../shared/models/organization.model';
import { ApiService } from '../shared/services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { InvitationCreateModel } from '../shared/models/invitation-create.model';
import { AuthService } from '../shared/services/auth.service';
import { loadOrganisationDefaultDealer } from '../shared/state/dealers';
import { OrganisationShopModel } from '../shared/models/organisation-shop.model';
import { OrganisationDealerModel } from '../shared/models/organisation-dealer.model';
import { IRegistrationDialogStep } from 'cde-fe-organization-registration-dialog/lib/models/organisation.model';
import { OrganisationRegistrationDialogUserModel } from '../shared/models/organisationRegistrationDialogUser.model';
import { IDealerData } from 'cde-fe-organization-registration-dialog/lib/models/dealer.model';

export interface DefaultOrganisation {
  orgId: string;
}

@Component({
  selector: 'app-organisation-data',
  templateUrl: './organisation-data.component.html',
  styleUrls: ['./organisation-data.component.scss']
})
export class OrganisationDataComponent implements OnDestroy {
  private subscriptions = new Subscription();

  tabs: any[] = [];
  currentOrganisation: Organization | undefined;
  previousIcon: Icon = {
    iconId: '006230',
    iconStyle: 'bold',
    namespace: 'claas-id',
    size: 19
  };

  starIcon: Icon = {
    iconId: '000983',
    iconStyle: 'bold',
    namespace: 'claas-id',
    size: 19
  };

  orgaId: any;
  currentUser: any;
  currentIndex = 0;

  largeSize = CircularProgressDiameter.LARGE;
  mediumIconSize = IconSize.MEDIUM;
  mediumIconFontSize = IconFontSize.MEDIUM;
  secondaryColor = ProgressColor.SECONDARY;
  isLoadingOrganisationDetails$ = new BehaviorSubject(true);
  organisationData$ = new BehaviorSubject<UserOrganisations | undefined>(
    undefined
  );
  isDefaultOrganisation$ = new BehaviorSubject<boolean>(false);
  currentIndex$ = new BehaviorSubject<any>(0);
  organisationMembers$ = new BehaviorSubject<any[] | undefined>(undefined);
  organisationShops$ = new BehaviorSubject<
    OrganisationShopModel[] | null | undefined
  >(undefined);
  organisationConnectedDealers$ = new BehaviorSubject<
    OrganisationDealerModel[] | null | undefined
  >(undefined);
  isAdmin$ = new BehaviorSubject<boolean>(false);
  isUniqueAdmin$ = new BehaviorSubject<boolean>(false);
  myUserId$ = new BehaviorSubject<any>(undefined);
  roleOptions$ = new BehaviorSubject<string[]>([]);
  requestInProgress$ = new BehaviorSubject<boolean>(false);
  currentUser$ = new BehaviorSubject<
    OrganisationRegistrationDialogUserModel | undefined
  >(undefined);
  registrationSteps$ = new BehaviorSubject<
    undefined | IRegistrationDialogStep[] | null
  >(undefined);

  @ViewChild('overview') overview!: TemplateRef<any>;
  @ViewChild('usersManagement') usersManagement!: TemplateRef<any>;
  @ViewChild('dealersManagement') dealersManagement!: TemplateRef<any>;

  constructor(
    private store: Store,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    private snackBar: MatSnackBar,
    private authService: AuthService
  ) {
    this.subscriptions.add(
      this.activatedRoute.paramMap.subscribe(params => {
        this.orgaId = params.get('orgaId');

        if (this.orgaId) {
          this.subscriptions.add(
            this.store
              .select(selectUserDetails)
              .pipe(
                filter((user: any) => !!user),
                tap((user: OrganisationRegistrationDialogUserModel) => {
                  this.currentUser = user;
                  this.currentUser$.next(this.currentUser);

                  this.store.dispatch(
                    loadUserDefaultOrganisation({ userId: user.uuid })
                  );

                  this.checkDefaultOrganisationState();
                  this.loadOrganisations();
                  this.getRolesInOrganisation();

                  if (this.orgaId) {
                    this.getOrganisationMembers(this.orgaId);
                    this.getOrganisationShops(this.orgaId);
                    this.getOrganisationConnectedDealers(this.orgaId);

                    if (this.currentOrganisation?.dealer.id) {
                      this.getOrganisationDefaultDealer(
                        this.orgaId,
                        this.currentOrganisation?.dealer.id
                      );
                    }
                  }
                })
              )
              .subscribe()
          );
        }

        this.subscriptions.add(
          this.store
            .select(selectRegistrationSteps)
            .subscribe(steps => this.registrationSteps$.next(steps))
        );
      })
    );
  }

  checkDefaultOrganisationState(): void {
    this.subscriptions.add(
      this.store
        .select(selectDefaultOrganisation)
        .pipe(
          filter((result: any) => !!result),
          tap((orgaId: string) => {
            this.isDefaultOrganisation$.next(orgaId === this.orgaId);
          })
        )
        .subscribe()
    );
  }

  loadOrganisations(): void {
    this.subscriptions.add(
      this.store
        .select(selectOrganisations)
        .subscribe((organisations: UserOrganisations | null) => {
          if (!organisations) {
            return this.store.dispatch(
              loadUserOrganisations({
                userId: this.currentUser.uuid
              })
            );
          } else {
            if (
              this.orgaId &&
              organisations[this.orgaId] &&
              Object.keys(organisations[this.orgaId]).length
            ) {
              this.currentOrganisation = organisations[this.orgaId];
              this.isLoadingOrganisationDetails$.next(false);
              this.organisationData$.next(this.currentOrganisation);

              if (this.currentOrganisation?.country) {
                this.store.dispatch(
                  loadRegistrationDefaultOrganisation({
                    country: this.currentOrganisation.country
                  })
                );
              }

              setTimeout(() => {
                this.setTabs();
              }, 500);
            }
          }
        })
    );
  }

  getRolesInOrganisation(): void {
    this.subscriptions.add(
      this.apiService
        .getRolesInOrganisation()
        .pipe(
          map((result: any) => result.content),
          map((elements: any[]) => {
            const roles: any[] = [];

            elements.forEach(element => {
              if (element.role !== 'DEMO_ADMIN') {
                roles.push({
                  text: this.getRole(element.role),
                  key: element.role
                });
              }
            });

            return roles;
          })
        )
        .subscribe((roles: any[]) => {
          this.roleOptions$.next(roles);
        })
    );
  }

  getRole(r: string) {
    var label = '';
    this.translateService
      .get('organization.roles.' + r.toLowerCase())
      .subscribe((data: string) => (label = data));
    return label;
  }

  getOrganisationMembers(orgaId: string): void {
    this.subscriptions.add(
      this.apiService.getOrganisationMembers(orgaId).subscribe({
        next: (result: any) => {
          const isAdmin = this.checkIfAdmin(
            result.content,
            this.currentUser.uuid
          );

          const isUniqueAdmin = this.checkIfUniqueAdmin(result.content);

          this.isAdmin$.next(isAdmin);
          this.isUniqueAdmin$.next(isUniqueAdmin);
          this.myUserId$.next(this.currentUser.uuid);
          this.getOrganisationInvitations(this.orgaId, result.content);
        },
        error: error =>
          this.store.dispatch(
            organisationMembersNotLoaded({
              organisationError: error.payload
            })
          )
      })
    );
  }

  getOrganisationShops(orgaId: string): void {
    this.subscriptions.add(
      this.apiService
        .getOrganisationShops(orgaId)
        .subscribe((result: any) =>
          this.organisationShops$.next(result.content)
        )
    );
  }

  getOrganisationConnectedDealers(orgaId: string): void {
    this.subscriptions.add(
      this.apiService
        .getOrganisationConnectedDealers(orgaId)
        .subscribe((result: any) =>
          this.organisationConnectedDealers$.next(result)
        )
    );
  }

  getOrganisationDefaultDealer(orgaId: string, dealerId: string): void {
    this.store.dispatch(
      loadOrganisationDefaultDealer({
        orgaId,
        dealerId: dealerId
      })
    );
  }

  getOrganisationInvitations(orgaId: string, users: any[]): void {
    this.subscriptions.add(
      this.apiService
        .getOrganisationInvitations(orgaId)
        .pipe(finalize(() => this.requestInProgress$.next(false)))
        .subscribe((invitations: any) => {
          const processedInvitations =
            this.markOutdatedInvitations(invitations);

          this.organisationMembers$.next([...users, ...processedInvitations]);
        })
    );
  }

  markOutdatedInvitations(invitations: any): any {
    return invitations.map((element: any) => {
      if (element.invitee) {
        const expirationDate = new Date(element.expiresAt);
        const now = new Date();

        if (now > expirationDate) {
          element['isExpired'] = true;
        }
      }

      return element;
    });
  }

  checkIfUniqueAdmin(users: any[]): boolean {
    const adminUsers = users.filter(user => user?.role === 'ADMIN');

    return adminUsers.length < 2;
  }

  checkIfAdmin(users: any[], userId: string): boolean {
    const adminUsers = users.filter(user => user?.idpUser === userId);

    return adminUsers[0] && adminUsers[0].role === 'ADMIN';
  }

  setTabs(): void {
    const tabsKeyWords = [
      'organization.overview',
      'organization.user_management',
      'organization.dealer_management'
    ];
    const tabsArray = [
      {
        title: '-',
        disabled: false,
        content: this.overview
      },
      {
        title: '-',
        disabled: false,
        content: this.usersManagement
      },
      {
        title: '-',
        disabled: false,
        content: this.dealersManagement
      }
    ];

    this.getTranslationsObs$(tabsKeyWords).subscribe((translations: any[]) => {
      for (let i = 0; i < translations.length; i++) {
        tabsArray[i].title = translations[i];
      }
    });

    this.tabs = tabsArray;
    this.currentIndex$.next(this.currentIndex);
  }

  getTranslationsObs$(tabsKeyWords: string[]): Observable<any> {
    const obs: Observable<string>[] = [];
    tabsKeyWords.forEach(key => obs.push(this.translateService.get(key)));

    return forkJoin(obs);
  }

  goPreviousPage(): void {
    this.router.navigate(['/organisations', {}]);
  }

  reloadOrganisationDetails(currentIndex: number): void {
    this.currentIndex = currentIndex;
    this.isLoadingOrganisationDetails$.next(true);

    this.store.dispatch(
      loadUserOrganisations({ userId: this.currentUser.uuid })
    );
  }

  setAsDefaultOrganisation(): void {
    this.store.dispatch(
      setUserDefaultOrganisation({
        orgId: this.orgaId ? this.orgaId : '',
        userId: this.currentUser.uuid
      })
    );
  }

  updateMemberRole(input: any): void {
    this.requestInProgress$.next(true);

    this.subscriptions.add(
      this.apiService
        .updateOrganisationMember(this.orgaId, this.currentUser.uuid, {
          idpUser: input.idpUser,
          role: input.role
        })
        .pipe(finalize(() => this.getOrganisationMembers(this.orgaId)))
        .subscribe({
          next: () => {
            this.translateService
              .get('organization.update_user.success')
              .subscribe(text => this.displayPopup(text, ''));
          },
          error: err => {
            this.translateService
              .get('organization.update_user.fail')
              .subscribe(text => this.displayPopup(text, 'error'));
          }
        })
    );
  }

  leaveOrganisation(userId: string): void {
    this.requestInProgress$.next(true);

    this.subscriptions.add(
      this.apiService
        .deleteOrganisationMember(this.orgaId, userId)
        .pipe(finalize(() => this.getOrganisationMembers(this.orgaId)))
        .subscribe({
          next: () => {
            this.translateService
              .get('organization.leave_organization.success')
              .subscribe(text => this.displayPopup(text, ''));
            // redirect to org list
            this.router.navigate(['/organisations']);
          },
          error: err => {
            this.translateService
              .get('organization.leave_organization.fail')
              .subscribe(text => this.displayPopup(text, 'error'));
          }
        })
    );
  }

  deleteOrganisationInvitation(targetId: string, isMember?: boolean): void {
    this.subscriptions.add(
      this.authService
        .getTokenSilently$()
        .pipe(
          switchMap(accessToken =>
            isMember
              ? this.apiService.deleteOrganisationMember(
                  this.orgaId,
                  targetId,
                  accessToken
                )
              : this.apiService.deleteOrganisationInvitation(
                  this.orgaId,
                  targetId,
                  accessToken
                )
          ),
          finalize(() => this.getOrganisationMembers(this.orgaId))
        )
        .subscribe({
          next: () => {
            this.translateService
              .get(
                isMember
                  ? 'organization.delete_user.success'
                  : 'organization.delete_invitation.success'
              )
              .subscribe(text => this.displayPopup(text, ''));
          },
          error: err => {
            this.translateService
              .get(
                isMember
                  ? 'organization.delete_user.fail'
                  : 'organization.delete_invitation.fail'
              )
              .subscribe(text => this.displayPopup(text, 'error'));
          }
        })
    );
  }

  inviteUserToOrganisation(payload: InvitationCreateModel): void {
    this.requestInProgress$.next(true);

    this.subscriptions.add(
      this.apiService
        .createInvitation(this.orgaId, payload)
        .pipe(finalize(() => this.getOrganisationMembers(this.orgaId)))
        .subscribe({
          next: () => {
            this.translateService
              .get('organization.user_invitation.success')
              .subscribe(text => this.displayPopup(text, ''));
          },
          error: err => {
            this.translateService
              .get('organization.user_invitation.fail')
              .subscribe(text => this.displayPopup(text, 'error'));
          }
        })
    );
  }

  displayPopup(text: string, className?: string): void {
    this.snackBar.open(text, '', {
      duration: 5000,
      panelClass: className ? className : ''
    });
  }

  disconnectDealers(
    targets: OrganisationDealerModel[] | undefined | null
  ): void {
    const subscriptionsArray: any[] = [];
    this.organisationConnectedDealers$.next(undefined);

    targets?.forEach(element =>
      subscriptionsArray.push(
        this.authService
          .getTokenSilently$()
          .pipe(
            switchMap(accessToken =>
              this.apiService.disconnectDealer(
                this.orgaId,
                element.dealerId,
                accessToken
              )
            )
          )
      )
    );

    if (subscriptionsArray.length) {
      this.subscriptions.add(
        forkJoin(subscriptionsArray)
          .pipe(
            finalize(() => this.getOrganisationConnectedDealers(this.orgaId))
          )
          .subscribe({
            next: value => {
              // Disconnect Dealer from thr organisation
              this.translateService
                .get('organization.remove_dealer.success')
                .subscribe((text: string) => {
                  this.displayPopup(text, '');
                });
            },
            error: err => {
              this.translateService
                .get('organization.remove_dealer.fail')
                .subscribe((text: string) => {
                  this.displayPopup(text, 'error');
                });
            }
          })
      );
    }
  }

  setNewOrganisationDealer(props: any): void {
    this.subscriptions.add(
      this.apiService
        .getDealer(props.dealerId, this.translateService.currentLang)
        .pipe(
          switchMap((info: IDealerData) => {
            const { deliveryPrograms, dealerLocators, ...dealerPayload } = info;

            return this.apiService.updateOrganisationDealer(
              props.org?.auth0Id,
              dealerPayload
            );
          })
        )
        .subscribe({
          next: (result: any) =>
            this.store.dispatch(
              loadOrganisationDefaultDealer({
                orgaId: props.org?.auth0Id,
                dealerId: result?.dealerId
              })
            ),

          error: (): any =>
            this.translateService
              .get('overview.connection_error')
              .subscribe(text => this.displayPopup(text, 'error'))
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
