import { Action, createReducer, on } from '@ngrx/store';
import { OrganisationDealersState, initialState } from './dealers.state';
import {
  dealerByNameSearchDataLoaded,
  dealerByNameSearchDataNotLoaded,
  dealerFiltersDataLoaded,
  loadDealerByNameSearchData,
  loadDealerFiltersData,
  loadNearestDealersSearchData,
  loadOrganisationDefaultDealer,
  nearestDealersSearchDataLoaded,
  nearestDealersSearchDataNotLoaded,
  organisationDefaultDealerLoaded
} from '../dealers';

export const dealersReducer = createReducer(
  initialState,
  on(loadOrganisationDefaultDealer, state => ({
    ...state
  })),
  on(organisationDefaultDealerLoaded, (state, { defaultDealer }) => ({
    ...state,
    defaultDealer
  })),
  on(loadNearestDealersSearchData, state => ({
    ...state,
    nearestDealersSearchDataPending: true
  })),
  on(nearestDealersSearchDataLoaded, (state, { nearestDealersSearchData }) => ({
    ...state,
    nearestDealersSearchData,
    nearestDealersSearchDataPending: false
  })),
  on(
    nearestDealersSearchDataNotLoaded,
    (state, { nearestDealersSearchData, organisationError }) => ({
      ...state,
      nearestDealersSearchData,
      nearestDealersSearchDataPending: false
    })
  ),
  on(loadDealerByNameSearchData, state => ({
    ...state
  })),
  on(dealerByNameSearchDataLoaded, (state, { dealerByNameSearchData }) => ({
    ...state,
    dealerByNameSearchData
  })),
  on(dealerByNameSearchDataNotLoaded, state => ({
    ...state
  })),
  on(loadDealerFiltersData, state => ({
    ...state
  })),
  on(dealerFiltersDataLoaded, (state, { dealerFilters }) => ({
    ...state,
    dealerFilters
  }))
);

export function reducer(
  state: OrganisationDealersState | undefined,
  action: Action
) {
  return dealersReducer(state, action);
}
